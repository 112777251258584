const redirects = [
    {
        path: '/join',
        to: '/public/career-seekers/#utm_source=vanity&utm_medium=redirect&utm_campaign=vanity-redirect&utm_id=vanity'
    },
    {
        path: '/hire',
        to: '/public/companies/#utm_source=vanity&utm_medium=redirect&utm_campaign=vanity-redirect&utm_id=vanity'
    }
];

export default redirects;
