import MaterialModal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import Button from '../Button/Button';

const content = { modalClose: 'Cancel' };

const StyledModal = styled(MaterialModal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    max-width: 30rem;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
`;

const StyledModalAction = styled('div')`
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
`;

const ModalButton = styled(Button)`
    flex: 1;
`;

export function ModalContent(props: {
    heading: string;
    content: string;
    onClose: () => void;
    primaryCTAContent: string;
    primaryCTAAction: () => void;
}) {
    return (
        <StyledModalContent>
            <Typography id='modal-title' sx={{ fontWeight: 'bold' }}>
                {props.heading}
            </Typography>
            <Typography id='modal-description' sx={{ mt: 2 }}>
                {props.content}
            </Typography>
            <StyledModalAction>
                <ModalButton variant='outlined' onClick={props.onClose}>
                    {content.modalClose}
                </ModalButton>
                <ModalButton
                    id='modalPrimaryCTAAction'
                    variant='contained'
                    onClick={props.primaryCTAAction}
                >
                    {props.primaryCTAContent}
                </ModalButton>
            </StyledModalAction>
        </StyledModalContent>
    );
}

export default function Modal(props: {
    heading: string;
    content: string;
    open: boolean;
    onClose: () => void;
    primaryCTAContent: string;
    primaryCTAAction: () => void;
}) {
    return (
        <StyledModal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
            disableScrollLock
        >
            <ModalContent
                heading={props.heading}
                content={props.content}
                onClose={props.onClose}
                primaryCTAContent={props.primaryCTAContent}
                primaryCTAAction={props.primaryCTAAction}
            />
        </StyledModal>
    );
}
