import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import RichText from '../../../global/components/RichText';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    experience: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            company: yup.string(),
            startDate: yup.string(),
            endDate: yup.string(),
            description: yup.string()
        })
    )
});

export default function ExperienceSlide(props: {
    profile: UserProfile['experience'];
    updateProfile: (value: UserProfile['experience']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            experience: props.profile
        }
    });
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'experience'
    });

    const onSubmit = (data: { experience: UserProfile['experience'] }) => {
        props.updateProfile(data.experience);
    };

    useEffect(() => {
        replace(props.profile);
    }, [replace, props.profile]);

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'experienceSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {commonContent.common.stepThreeSubtitle}
                </Typography>
                {fields.map((field, index) => (
                    <div key={field.id} style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #C2C2C2',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                marginTop: '1rem'
                            }}
                        >
                            <TextInput
                                id={`experience.${index}.title`}
                                label={content.experience.title.label}
                                color='primary'
                                error={!!errors.experience?.[index]?.title}
                                errorText={
                                    errors.experience?.[index]?.title?.message
                                }
                                placeholder={
                                    content.experience.title.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                            <TextInput
                                id={`experience.${index}.company`}
                                label={content.experience.company.label}
                                color='primary'
                                error={!!errors.experience?.[index]?.company}
                                errorText={
                                    errors.experience?.[index]?.company?.message
                                }
                                placeholder={
                                    content.experience.company.placeholder
                                }
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />

                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <TextInput
                                    id={`experience.${index}.startDate`}
                                    label={content.experience.startDate.label}
                                    color='primary'
                                    error={
                                        !!errors.experience?.[index]?.startDate
                                    }
                                    errorText={
                                        errors.experience?.[index]?.startDate
                                            ?.message
                                    }
                                    placeholder={
                                        content.experience.startDate.placeholder
                                    }
                                    control={
                                        control as unknown as Control<FieldValues>
                                    }
                                />
                                <TextInput
                                    id={`experience.${index}.endDate`}
                                    label={content.experience.endDate.label}
                                    color='primary'
                                    error={
                                        !!errors.experience?.[index]?.endDate
                                    }
                                    errorText={
                                        errors.experience?.[index]?.endDate
                                            ?.message
                                    }
                                    placeholder={
                                        content.experience.endDate.placeholder
                                    }
                                    control={
                                        control as unknown as Control<FieldValues>
                                    }
                                />
                            </div>
                            <RichText
                                id={`experience.${index}.description`}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                            />
                        </div>
                        {fields.length > 1 && (
                            <IconButton
                                id={`experience.${index}.remove`}
                                color='primary'
                                aria-label={content.experience.remove}
                                component='label'
                                sx={{ ml: 1 }}
                                onClick={() => remove(index)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        )}
                    </div>
                ))}
                <div>
                    <TextButton
                        id={'addExperience'}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ fontWeight: 400, mt: 2 }}
                        onClick={() =>
                            append({
                                startDate: '',
                                endDate: '',
                                title: '',
                                company: '',
                                description: ''
                            })
                        }
                    >
                        {content.experience.add}
                    </TextButton>
                </div>
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {commonContent.common.back}
                    </TextButton>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id={'submit'}
                    >
                        {commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
