import { styled } from '@mui/material/styles';

export const StyledChannelListWrapper = styled('div')`
    display: flex;
    height: 100%;
    flex-direction: column;
    border: 1px solid #e0e0e0;
`;

export const StyledChannelListContainer = styled('div')`
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const StyledChannelList = styled('div')`
    display: flex;
    flex-direction: column;
    height: 0;
`;
