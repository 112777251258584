import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import SwooshSVG from '../../static/icons/swoosh.svg';

const StyledBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem 8rem',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const StyledContainer = styled(Container)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0'
}));

const StyledContent = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: `${(6 / 12) * 100}%`,
    margin: '0',

    [theme.breakpoints.down('lg')]: {
        flexBasis: `${(6 / 12) * 100}%`
    },
    [theme.breakpoints.down('md')]: {
        minWidth: theme.spacing(32),
        flexBasis: '100%'
    }
}));

const PointsContainer = styled(Grid)`
    background-color: #fafbfc;
    border-left: solid #d5d8df 2px;
`;

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: `${(6 / 12) * 100}%`,
    paddingRight: '6rem',
    [theme.breakpoints.down('lg')]: {
        maxWidth: `${(6 / 12) * 100}%`,
        paddingRight: '3rem'
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: theme.spacing(64),
        paddingRight: '0',
        marginBottom: theme.spacing(6)
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: `${(10 / 12) * 100}%`,
        minWidth: theme.spacing(32)
    }
}));

const SwooshIcon = styled('img')(({ theme }) => ({
    width: theme.spacing(6),
    position: 'relative',
    bottom: '1rem',
    right: '2.5rem',
    [theme.breakpoints.down('md')]: {
        bottom: '0'
    },
    [theme.breakpoints.down('sm')]: {
        right: '0.5rem',
        width: theme.spacing(4)
    }
}));

const HeadingIconWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export interface WhyProps {
    image: { file: { url: string } };
    heading: string;
    steps: { title: string; content: string }[];
}

export default function WhySection(props: WhyProps) {
    return (
        <StyledBackground>
            <StyledContainer disableGutters>
                <StyledImage src={props.image.file.url} />
                <StyledContent disableGutters>
                    <HeadingIconWrapper>
                        <SwooshIcon src={SwooshSVG} />
                        <Typography
                            mb={2}
                            variant={'h2'}
                            color='text.secondary'
                            component={'h2'}
                            sx={{ fontSize: '2.5rem' }}
                        >
                            {props.heading}
                        </Typography>
                    </HeadingIconWrapper>
                    <PointsContainer p={4}>
                        {props.steps.map(
                            (
                                point: { title: string; content: string },
                                i,
                                arr
                            ) => (
                                <Box
                                    sx={{
                                        pb: i === arr.length - 1 ? '0' : '2rem'
                                    }}
                                    key={point.title}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'medium',
                                            mb: '0.5rem',
                                            lineHeight: '1.5'
                                        }}
                                        variant={'body2'}
                                        color='text.secondary'
                                        component='h6'
                                    >
                                        {point.title}
                                    </Typography>
                                    <Typography
                                        color='text.primary'
                                        variant='body2'
                                    >
                                        {point.content}
                                    </Typography>
                                </Box>
                            )
                        )}
                    </PointsContainer>
                </StyledContent>
            </StyledContainer>
        </StyledBackground>
    );
}
