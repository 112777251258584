import React from 'react';

import fourthImage from '../../../static/illustrations/public/candidates/fourth.png';
import firstImage from '../../../static/illustrations/public/candidates/intro.svg';
import secondImage from '../../../static/illustrations/public/candidates/second.png';
import waitlistImage from '../../../static/illustrations/public/employers/fourth.svg';
import logo from '../../../static/logos/name.svg';
import FooterSection from '../FooterSection';
import HowSection from '../HowSection';
import IntroSection from '../IntroSection';
import NavBarSection from '../NavBarSection';
import WaitlistSection from '../WaitlistSection';
import WhatSection from '../WhatSection';
import WhySection from '../WhySection';

const Candidates = () => (
    <>
        <NavBarSection
            logo={{ file: { url: logo } }}
            links={[
                {
                    content: 'Career Seekers',
                    link: '/public/career-seekers'
                },
                { content: 'Employers', link: '/public/companies' }
            ]}
            logoLink=''
        />
        <IntroSection
            heading={'Find your next opportunity'}
            content={
                'Connect with employers that are committed to inclusive hiring and creating opportunities for all candidates.'
            }
            link={{
                id: 'join-waitlist-cta-candidates',
                link: '#waitlist',
                content: 'Join the waitlist'
            }}
            image={{ file: { url: firstImage } }}
        />
        <WhatSection
            heading={'Say goodbye to applications'}
            content={
                'No need to submit hundreds of applications and wait to hear back. If you’re qualified for a role, you book an interview. It’s that simple.'
            }
            image={{ file: { url: secondImage } }}
        />
        <HowSection
            heading={'Here’s how it works'}
            steps={[
                {
                    title: 'Create your profile in minutes',
                    image: { file: { url: '' } },
                    stepImage: { file: { url: '' } },
                    content:
                        'Tell us about what you’re looking for in your next role, and we’ll help you find it.'
                },
                {
                    title: 'Get notified when we find a good fit',
                    image: { file: { url: '' } },
                    stepImage: { file: { url: '' } },
                    content:
                        'We’ll send you the roles you qualify for based on your profile, no applications needed.'
                },
                {
                    title: 'Book a 7-minute interview with the hiring manager',
                    image: { file: { url: '' } },
                    stepImage: { file: { url: '' } },
                    content:
                        'Meet with the hiring team and be ready to pitch yourself in 7 minutes.'
                }
            ]}
        />
        <WhySection
            heading={'Let’s get you in the room with managers'}
            image={{ file: { url: fourthImage } }}
            steps={[
                {
                    title: 'Enjoy merit-based hiring',
                    content:
                        'If you’re a seasoned professional, recent grad or newcomer to Canada, we help you overcome the challenges you might face while searching for your next opportunity, even if you don’t have an established network.'
                },
                {
                    title: 'Save time while job-hunting',
                    content:
                        'No need to spend long hours looking for a job. We’ll do the job searching for you and reduce hiring roadblocks along the way.'
                },
                {
                    title: 'Your first interview is anonymous',
                    content:
                        'We match roles to you based on your qualifications. Any reference to your ethnicity, gender, age or work history won’t be shared with the hiring team before your first interview.'
                }
            ]}
        />
        <WaitlistSection
            heading={'Ready to fast-track your career journey?'}
            content={''}
            fieldHelperText={'Enter your email address'}
            ctaText={'Join the waitlist'}
            image={{ file: { url: waitlistImage } }}
        />
        <FooterSection content={''} />
    </>
);

export default Candidates;
