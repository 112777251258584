import { Company, CompanyResponse } from '../../types/company';

export const createEmptyCompany = (): Company => {
    const company: Company = {
        id: 0,
        name: '',
        logoURL: '',
        websiteURL: '',
        description: '',
        createdByID: 0,
        industry: '',
        location: '',
        size: '',
        plan: 'basic',
        stripeCustomerID: '',
        createdAt: '',
        active: false,
        subscription: null
    };
    return company;
};

export const convertCompanyResponseToCompany = (
    response: CompanyResponse
): Company => {
    const company: Company = {
        id: response.id,
        name: response.name,
        logoURL: response.logo_url,
        websiteURL: response.website_url,
        description: response.description,
        createdByID: response.created_by_id,
        industry: response.industry,
        location: response.location,
        size: response.size,
        plan: response.plan,
        stripeCustomerID: response.stripe_customer_id,
        createdAt: response.created_at,
        active: response.active,
        subscription: response.subscription
            ? {
                  id: response.subscription.id,
                  status: response.subscription.status,
                  currentPeriodEnd: response.subscription.current_period_end,
                  currentPeriodStart: response.subscription.current_period_start
              }
            : null
    };
    return company;
};

export const convertCompanyToCompanyResponse = (
    company: Company
): CompanyResponse => {
    const response: CompanyResponse = {
        id: company.id,
        name: company.name,
        logo_url: company.logoURL,
        website_url: company.websiteURL,
        description: company.description,
        created_by_id: company.createdByID,
        industry: company.industry,
        location: company.location,
        size: company.size,
        plan: company.plan,
        stripe_customer_id: company.stripeCustomerID,
        created_at: company.createdAt,
        active: company.active,
        subscription: company.subscription
            ? {
                  id: company.subscription.id,
                  status: company.subscription.status,
                  current_period_end: company.subscription.currentPeriodEnd,
                  current_period_start: company.subscription.currentPeriodStart
              }
            : null
    };
    return response;
};
