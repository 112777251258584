const commonContent = {
    common: {
        title: 'Create your profile',
        stepOneSubtitle:
            'We only share your personal details with hiring managers immediately before an interview.',
        stepTwoSubtitle:
            'Let us know what you’re looking for in your next role, so we can find the right opportunities for you.',
        stepThreeSubtitle:
            'We only share your personal details with hiring managers immediately before an interview.',
        submit: 'Next',
        back: 'Back',
        cancel: 'Cancel',
        confirm: 'Confirm plan',
        invite: 'Send invitation',
        add_email: 'Add email',
        errors: {
            required: 'This field is required',
            email: 'Please provide a valid email address',
            duplicate_email: 'This email is already in the list'
        }
    },
    name: {
        firstName: { label: 'First name' },
        lastName: { label: 'Last name' },
        currentJobTitle: {
            label: 'Current job title'
        },
        pronouns: {
            label: 'Add your pronouns',
            helperText:
                "Share your pronouns if you're comfortable doing so, e.g. they/them, she/her, he/him, etc."
        },
        imageURL: { label: 'profile picture' }
    },
    location: {
        location: { label: 'Where do you live?', placeholder: 'Toronto' },
        workInCanada: {
            label: 'Are you legally eligible to work in Canada?',
            options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
            ]
        },
        termsAndConditions: {
            label: 'I agree to Levyl’s Terms of Service and Privacy Policy'
        },
        errors: {
            workInCanada: 'You must be able to work in Canada',
            termsAndConditions: 'You must agree to the terms and conditions'
        }
    }
};

export default commonContent;
