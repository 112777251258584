import ChannelUI from '@sendbird/uikit-react/Channel/components/ChannelUI';
import {
    ChannelProvider,
    useChannelContext
} from '@sendbird/uikit-react/Channel/context';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import '@sendbird/uikit-react/dist/index.css';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../context/UserContext';

import StyledChannelContainer from './Channel.styles';
import ChannelHeaderDisplay from './ChannelHeader';

const ChannelHeader = () => {
    const channelData = useChannelContext();
    const sendbirdInstance = sendbirdSelectors.getSdk(
        useSendbirdStateContext()
    );
    const targetUser = channelData?.currentGroupChannel?.members.filter(
        (member) => member.userId !== sendbirdInstance?.currentUser.userId
    )[0];

    return (
        <ChannelHeaderDisplay
            name={targetUser?.nickname}
            profileImageURL={targetUser?.profileUrl ?? ''}
        />
    );
};

const Channel = (props: { channelURL: string }) => {
    const { messageID } = useParams<{ messageID: string }>();
    const { user } = useContext(UserContext);
    return (
        <SendbirdProvider
            appId={'E464F6AC-4712-468C-8CCF-4B1D4B130ECA'}
            userId={user.userID}
            nickname={user.name.firstName}
        >
            <StyledChannelContainer>
                <ChannelProvider
                    isMessageGroupingEnabled={true}
                    channelUrl={messageID || props.channelURL}
                    showSearchIcon={false}
                >
                    <ChannelUI renderChannelHeader={() => <ChannelHeader />} />
                </ChannelProvider>
            </StyledChannelContainer>
        </SendbirdProvider>
    );
};

export default Channel;
