import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../global/components/Button/Button';

import useTimer, { isLessThanAnHourFromNow } from './hooks/useTimer';
import { padTime } from './videochat/ChatTimer';

const WaitingRoom = styled('div')`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
`;

const TextGroup = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const CandidateSection = styled('div')`
    display: flex;
    align-items: center;
    gap: 2rem;
`;

const CandidateStatus = styled('div')`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export default function Lobby(props: {
    role: string;
    company: string;
    interviewerName?: string;
    unixStartTime: number;
    available: number;
    unavailable: number;
    isCandidate: boolean;
    children?: React.ReactNode;
}) {
    const { uid, interviewID } =
        useParams<{ uid: string; interviewID: string }>();
    const { seconds, minutes } = useTimer(props.unixStartTime);
    const isSoon = useMemo(
        () => isLessThanAnHourFromNow(props.unixStartTime),
        [props.unixStartTime]
    );
    const startTime = useMemo(
        () =>
            moment
                .unix(props.unixStartTime || 0)
                .local()
                .format('MMMM Do YYYY, h:mm a'),
        [props.unixStartTime]
    );

    return (
        <WaitingRoom>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}
            >
                <Typography>
                    <b>
                        {props.role}
                        {' at '}
                        {props.company}
                    </b>
                </Typography>
                {props.isCandidate && (
                    <Typography>{`with ${props.interviewerName}`}</Typography>
                )}
            </div>
            <TextGroup>
                <Typography variant='h6' sx={{ fontWeight: 'light' }}>
                    {'Stay tuned!'}
                </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontWeight: 'light', textAlign: 'center' }}
                >
                    {props.isCandidate
                        ? 'Your interview starts automatically'
                        : 'Your interviews start automatically'}
                    {isSoon ? ' in ' : ' on '}
                    <Typography
                        component={'span'}
                        variant={'h6'}
                        color='primary'
                        sx={{ mx: 0.25 }}
                    >
                        <b>
                            {isSoon
                                ? `${padTime(minutes)}:${padTime(seconds)}"`
                                : startTime}
                        </b>
                    </Typography>
                </Typography>
            </TextGroup>
            {isSoon && (
                <>
                    {!props.isCandidate && (
                        <>
                            <Typography>{'Online candidates:'}</Typography>
                            <CandidateSection>
                                <CandidateStatus>
                                    <CheckIcon />
                                    <Typography sx={{ fontWeight: 'light' }}>
                                        {'Present'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            px: 1,
                                            py: 0.5,
                                            backgroundColor: 'success.light',
                                            color: 'success.main',
                                            borderRadius: '0.25rem'
                                        }}
                                    >
                                        {props.available}
                                    </Typography>
                                </CandidateStatus>
                                <CandidateStatus>
                                    <CloseIcon />
                                    <Typography sx={{ fontWeight: 'light' }}>
                                        {'Absent'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            px: 1,
                                            py: 0.5,
                                            backgroundColor: 'error.light',
                                            color: 'error.main',
                                            borderRadius: '0.25rem'
                                        }}
                                    >
                                        {props.unavailable}
                                    </Typography>
                                </CandidateStatus>
                            </CandidateSection>
                        </>
                    )}
                    <Typography variant={'h6'}>
                        {'Test audio and video'}
                    </Typography>
                    <div
                        style={{
                            background: 'black',
                            width: '20rem',
                            height: '11.25rem',
                            borderRadius: '0.5rem',
                            display: 'flex'
                        }}
                    >
                        {props.children}
                    </div>
                </>
            )}
            <TextGroup>
                <Typography>{'Want to prepare for the interview?'}</Typography>
                <Typography>
                    {'Access our '}
                    <Typography color={'primary'} component={'a'}>
                        <b>{'interview preparation checklist.'}</b>
                    </Typography>
                </Typography>
            </TextGroup>
            {isSoon && (
                <Button
                    variant={'contained'}
                    to={`/secure/${uid}/interview/${interviewID}/interview/`}
                >
                    {'Join Interview'}
                </Button>
            )}
        </WaitingRoom>
    );
}
