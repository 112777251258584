import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const Container = styled('div')`
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 0.5rem;
`;

const TimerText = styled(Typography)`
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.53);
`;

export const padTime = (time: number) => time.toString().padStart(2, '0');

export default function ChatTimer(props: { minutes: number; seconds: number }) {
    return (
        <Container>
            <TimerText fontWeight='700' color='white'>
                {`${padTime(props.minutes)}:${padTime(props.seconds)}`}
            </TimerText>
        </Container>
    );
}
