import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const StyledMessagingContainer = styled(Container)`
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    align-self: center;
    border-radius: 16px;
    padding: 0;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 2rem;
`;

export default StyledMessagingContainer;
