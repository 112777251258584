// todo add storybook stories

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import TitleIcon from '@mui/icons-material/Title';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React from 'react';
import { Controller } from 'react-hook-form';

import type { Editor } from '@tiptap/react';
import type { Control } from 'react-hook-form';

const StyledMenuBar = styled('div')`
    display: flex;
    column-gap: 0.125rem;
    flex-wrap: wrap;
`;

const StyledEditor = styled('div')`
    border: 1px solid #c2c2c2;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-family: 'Lato', sans-serif;
    font-size: 0.875rem;
`;

const MenuBar = (props: { editor: Editor | null; simple: boolean }) => {
    const { editor } = props;

    if (!editor) {
        return null;
    }

    return (
        <StyledMenuBar>
            <IconButton
                onClick={() => editor.chain().focus().toggleBold().run()}
            >
                <FormatBoldIcon
                    fontSize='small'
                    color={editor.isActive('bold') ? 'primary' : 'inherit'}
                />
            </IconButton>
            <IconButton
                onClick={() => editor.chain().focus().toggleItalic().run()}
            >
                <FormatItalicIcon
                    fontSize='small'
                    color={editor.isActive('italic') ? 'primary' : 'inherit'}
                />
            </IconButton>
            <IconButton
                onClick={() => editor.chain().focus().toggleStrike().run()}
            >
                <FormatStrikethroughIcon
                    fontSize='small'
                    color={editor.isActive('strike') ? 'primary' : 'inherit'}
                />
            </IconButton>
            {!props.simple && (
                <>
                    <IconButton
                        onClick={() =>
                            editor.chain().focus().setParagraph().run()
                        }
                    >
                        <FormatSizeIcon
                            fontSize='small'
                            color={
                                editor.isActive('paragraph')
                                    ? 'primary'
                                    : 'inherit'
                            }
                        />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 2 })
                                .run()
                        }
                    >
                        <TitleIcon
                            fontSize='small'
                            color={
                                editor.isActive('heading', { level: 2 })
                                    ? 'primary'
                                    : 'inherit'
                            }
                        />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            editor.chain().focus().toggleBulletList().run()
                        }
                    >
                        <FormatListBulletedIcon
                            fontSize='small'
                            color={
                                editor.isActive('bulletList')
                                    ? 'primary'
                                    : 'inherit'
                            }
                        />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            editor.chain().focus().toggleOrderedList().run()
                        }
                    >
                        <FormatListNumberedIcon
                            fontSize='small'
                            color={
                                editor.isActive('orderedList')
                                    ? 'primary'
                                    : 'inherit'
                            }
                        />
                    </IconButton>
                </>
            )}
        </StyledMenuBar>
    );
};

const RichText = (props: {
    id: string;
    content: string;
    setContent: (content: string) => void;
    simple: boolean;
    error?: string;
}) => {
    const richEditor = useEditor({
        extensions: [StarterKit],
        content: props.content,
        onUpdate({ editor }) {
            props.setContent(editor.getHTML());
        }
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledEditor id={props.id}>
                <MenuBar editor={richEditor} simple={props.simple} />
                <EditorContent editor={richEditor} />
            </StyledEditor>
            {props.error && (
                <Typography sx={{ mt: 1 }} color='error'>
                    {props.error}
                </Typography>
            )}
        </div>
    );
};

export default function ControlledRichText(props: {
    id: string;
    control: Control<any>;
    simple?: boolean;
    error?: string;
}) {
    return (
        <Controller
            name={props.id}
            control={props.control}
            render={({ field: { onChange, value } }) => (
                <RichText
                    id={props.id}
                    setContent={onChange}
                    content={value}
                    simple={props.simple ?? false}
                    error={props.error}
                />
            )}
        />
    );
}
