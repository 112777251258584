import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { useContext } from 'react';

import thumbsUp from '../../../../static/icons/thumbs-up.svg';
import { UserContext } from '../../../context/UserContext';
import { BaseContainer } from '../../../global/Core';

import EmployerJobList from './EmployerJobList';

export default function EmployerDashboard() {
    const { user, companies } = useContext(UserContext);
    return (
        <BaseContainer>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '4rem'
                }}
            >
                <Typography
                    variant={'h1'}
                    color={'text.secondary'}
                >{`Hi, ${user.name.firstName}!`}</Typography>
                <img src={thumbsUp} style={{ marginLeft: '1.5rem' }} />
            </div>
            {companies.map((company, index) => (
                <>
                    <EmployerJobList
                        key={company.id}
                        user={user}
                        company={company}
                    />
                    {index < companies.length - 1 && (
                        <Divider variant='middle' flexItem sx={{ my: 4 }} />
                    )}
                </>
            ))}
        </BaseContainer>
    );
}
