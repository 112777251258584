import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as AbsentSVG } from '../../../../static/icons/userabsent.svg';
import { ReactComponent as PresentSVG } from '../../../../static/icons/userpresent.svg';

import type { InterviewStatus } from '../../../../types/enum';

function UserPresentIcon(props: any) {
    return <SvgIcon {...props} component={PresentSVG} inheritViewBox />;
}

function UserAbsentIcon(props: any) {
    return <SvgIcon {...props} component={AbsentSVG} inheritViewBox />;
}

const Navigation = styled('div')<{ isLobby: boolean }>(
    ({ theme, isLobby }) => `
    display: flex;
    border-bottom: 1px solid ${theme.palette.grey[200]};
    width: 100%;
    justify-content: space-between;
    @media (max-width: 900px) {
        justify-content: ${isLobby ? 'flex-end' : 'space-between'};
    }
`
);

const TabIconBackground = styled('div')<{ selected: boolean }>(
    ({ theme, selected }) => `
    width: 100%;
    padding: 0.5rem;
    ${selected && `background :${theme.palette.primary.light};`}
    border-radius: 0.5rem;
`
);

const IconBorder = styled('div')<{ selected: boolean; nextThree: boolean }>(
    ({ theme, selected, nextThree }) => `
    @media (max-width: 900px) {
        display: ${nextThree ? 'inherit' : 'none'};
    }
    ${
        selected &&
        `
        background :${theme.palette.primary.light};
        border-radius: 0.5rem;
        padding: 0.25rem;
    `
    }
`
);

const CandidateLineUp = styled('div')`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const StyledChatStatus = styled('div')<{ isCandidate: boolean }>(
    ({ isCandidate }) => `
    width: 100%;
    @media (max-width: 900px) {
        display: ${isCandidate ? 'none' : 'inherit'};
    }
`
);

const StyledTabs = styled(Tabs)`
    @media (max-width: 900px) {
        display: none;
    }
`;

const StyledPeopleIcon = styled(PeopleIcon)`
    @media (max-width: 900px) {
        display: none;
    }
`;

const StyledLineUpText = styled(Typography)`
    @media (max-width: 900px) {
        display: none;
    }
`;

const getIcon = (status: InterviewStatus, candidatePresent: boolean) => {
    if (status === 'scheduled') {
        if (candidatePresent) {
            return <UserPresentIcon />;
        }
        return <UserAbsentIcon />;
    }
    return <PersonIcon sx={{ color: 'text.primary' }} />;
};

export default function ChatStatus(props: {
    lineUp?: {
        id: number;
        candidatePresent: boolean;
        status: InterviewStatus;
    }[];
    endAllInterview: () => void;
    isCandidate: boolean;
    interviewIndex: number;
}) {
    const pathName = useLocation().pathname;
    return (
        <StyledChatStatus isCandidate={props.isCandidate}>
            <Navigation isLobby={pathName.includes('lobby')}>
                <StyledTabs
                    value={props.lineUp ? 'interview' : 'home'}
                    aria-label='interview status'
                    color='primary'
                >
                    <Tab
                        icon={
                            <TabIconBackground selected={!props.lineUp}>
                                <HomeIcon />
                            </TabIconBackground>
                        }
                        aria-label='home'
                        value='home'
                        disabled={!!props.lineUp}
                    />
                    <Tab
                        icon={
                            <TabIconBackground selected={!!props.lineUp}>
                                <SubscriptionsIcon />
                            </TabIconBackground>
                        }
                        aria-label='interview'
                        value='interview'
                        disabled={!props.lineUp}
                    />
                </StyledTabs>
                {props.lineUp && !props.isCandidate && (
                    <CandidateLineUp>
                        <StyledPeopleIcon sx={{ color: 'text.primary' }} />
                        <StyledLineUpText color={'text.primary'}>
                            {'Candidate Line-Up:'}
                        </StyledLineUpText>
                        {props.lineUp.map((value, index) => (
                            <IconBorder
                                key={index}
                                selected={props.interviewIndex === index}
                                nextThree={
                                    index <= props.interviewIndex + 2 &&
                                    index >= props.interviewIndex
                                }
                            >
                                {getIcon(value.status, value.candidatePresent)}
                            </IconBorder>
                        ))}
                    </CandidateLineUp>
                )}
                {!props.isCandidate && (
                    <Button
                        sx={{
                            color: 'secondary.main',
                            textTransform: 'none',
                            fontWeight: '700'
                        }}
                        onClick={props.endAllInterview}
                    >
                        {'End Interview For All'}
                    </Button>
                )}
            </Navigation>
        </StyledChatStatus>
    );
}
