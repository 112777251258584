import React, { useContext, useEffect, useMemo } from 'react';
// @ts-expect-error - no types for library
import TagManager from 'react-gtm-module';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch
} from 'react-router-dom';

import { FirebaseContext } from './context/FirebaseContext';
import { UserContext } from './context/UserContext';
import MainContainer from './global/layouts/MainContainer/MainContainer';
import { useDefaultSidebarButtons } from './global/layouts/MainContainer/SidebarButton/SidebarList';
import ScheduleInterview from './pages/BookInterview/ScheduleInterview';
import CandidateDashboard from './pages/Dashboard/CandidateDashboard/CandidateDashboard';
import EmployerDashboard from './pages/Dashboard/EmployerDashboard/EmployerDashboard';
import CompanyDetails from './pages/Details/BusinessDetails';
import FullJobCard from './pages/Details/JobDetails';
import Interview from './pages/Interview/Interview';
import Messaging from './pages/Messaging/Messaging';
import Network from './pages/Network/Network';
import Statistics from './pages/Statistics/CompanyStatistics';
import UpcomingInterviews from './pages/UpcomingInterviews/UpcomingInterviews';

export default function SecureSite() {
    const { path } = useRouteMatch();
    const location = useLocation();

    const pathName = useLocation().pathname;

    const { auth } = useContext(FirebaseContext);
    const { user } = useContext(UserContext);
    const userID = useMemo(() => user.userID, [user.userID]);

    const sideBarButtons = useDefaultSidebarButtons(userID);

    useEffect(() => {
        const tagManagerArgs = {
            dataLayer: {
                role: user.levylRole,
                user
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    }, [location.pathname, user]);

    return userID ? (
        <MainContainer
            fullWidth={pathName.includes('/interview/')}
            sideBarButtons={sideBarButtons}
            firstName={user.name.firstName}
            photoURL={user.name.imageURL}
            signOut={() => {
                auth?.signOut();
            }}
        >
            <Switch>
                <Route path={`${path}/candidate/dashboard`}>
                    <CandidateDashboard />
                </Route>
                <Route path={`${path}/business/dashboard`}>
                    <EmployerDashboard />
                </Route>
                <Route path={`${path}/company/:companyID`}>
                    <CompanyDetails />
                </Route>
                <Route path={`${path}/job/:jobID`}>
                    <FullJobCard />
                </Route>
                <Route path={`${path}/network`}>
                    <Network />
                </Route>
                <Route path={`${path}/upcominginterviews`}>
                    <UpcomingInterviews />
                </Route>
                <Route
                    path={[
                        `${path}/schedule/:jobID`,
                        `${path}/schedulefollowup/:jobID`
                    ]}
                >
                    <ScheduleInterview />
                </Route>
                <Route path={`${path}/interview/:interviewID`}>
                    <Interview />
                </Route>
                <Route path={`${path}/statistics/:companyID`}>
                    <Statistics />
                </Route>
                <Route path={`${path}/messaging/:messageID?`}>
                    <Messaging />
                </Route>
                <Route path={`${path}/dashboard`}>
                    {user.levylRole === 'candidate' ? (
                        <Redirect
                            to={`/secure/${userID}/candidate/dashboard`}
                        />
                    ) : (
                        <Redirect to={`/secure/${userID}/business/dashboard`} />
                    )}
                </Route>
            </Switch>
        </MainContainer>
    ) : null;
}
