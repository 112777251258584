import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Chip, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getEligibleUserCount } from '../../../services/user';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import Checkbox from '../../global/components/Checkbox';
import TextInput from '../../global/components/TextField/TextField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        minSalary: yup.number().positive().required(),
        maxSalary: yup.number().positive().required(),
        showSalary: yup.bool(),
        benefit: yup.string()
    })
    .required();

export default function SalarySlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    prevURL: string;
}) {
    const [showHideSalary, setHideSalary] = useState<boolean | undefined>(
        false
    );
    const [benefits, setBenefits] = useState<Array<string>>(props.job.benefits);
    const {
        handleSubmit,
        getValues,
        resetField,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            minSalary: props.job.minSalary,
            maxSalary: props.job.maxSalary,
            hideSalary: false,
            benefit: ''
        }
    });

    const onSubmit = (data: {
        minSalary: number;
        maxSalary: number;
        hideSalary: boolean;
    }) => {
        props.setJob({
            ...props.job,
            minSalary: data.minSalary,
            maxSalary: data.maxSalary,
            hideSalary: data.hideSalary,
            benefits
        });
    };

    const addBenefit = () => {
        const benefit = getValues('benefit');
        const benefitsList = benefits;
        benefitsList.unshift(benefit);
        setBenefits(benefitsList);
        resetField('benefit');
    };

    const deleteBenefit = (e: Event, benefit: string) => {
        const benefitsList = benefits;
        const index = benefitsList.indexOf(benefit);
        benefitsList.splice(index, 1);
        setBenefits([...benefitsList]);
    };

    const { query, setQuery } = props;

    useEffect(() => {
        const subscription = watch((value) => {
            const { minSalary, hideSalary } = value;
            setHideSalary(hideSalary);
            setQuery({
                ...query,
                min_salary: minSalary
            });
        });
        return () => subscription.unsubscribe();
    }, [watch, query, setQuery]);

    return (
        <ColumnCard
            closeScreen
            step={2}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
        >
            <Typography variant='body2' fontWeight='400' sx={{ mb: 2 }}>
                {content.salary.title}
            </Typography>
            <Typography variant='body2' fontWeight='400' sx={{ mb: 2 }}>
                {content.salary.body}
            </Typography>
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    id='minSalary'
                    label={content.salary.minimumSalary.label}
                    color='primary'
                    error={!!errors.minSalary}
                    errorText={content.common.errors.required}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                    onBlur={() => {
                        getEligibleUserCount(props.query).then(
                            (count: number) => {
                                props.setCandidateCount(count);
                            }
                        );
                    }}
                    type='number'
                />
                <TextInput
                    id='maxSalary'
                    label={content.salary.maximumSalary.label}
                    color='primary'
                    error={!!errors.maxSalary}
                    errorText={content.common.errors.required}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                    type='number'
                />
                <Checkbox
                    id='hideSalary'
                    label={content.salary.checkbox.label}
                    color='primary'
                    control={control as unknown as Control<FieldValues>}
                />
                {showHideSalary && (
                    <Typography
                        color='primary'
                        variant='body1'
                        fontWeight='400'
                        sx={{ ml: 4 }}
                    >
                        {content.salary.checkbox.description}
                    </Typography>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline'
                    }}
                >
                    <TextInput
                        id='benefit'
                        label={content.salary.benefits.label}
                        color='primary'
                        placeholder={''}
                        control={control as unknown as Control<FieldValues>}
                    />
                    <IconButton size='large' onClick={addBenefit}>
                        <AddCircleOutlineIcon
                            color='primary'
                            fontSize='inherit'
                        />
                    </IconButton>
                </div>
                <div>
                    {benefits.map((benefit) => (
                        <Chip
                            key={benefit}
                            label={benefit}
                            style={{
                                marginLeft: '0.5rem',
                                marginRight: '0.5rem'
                            }}
                            onDelete={(e) => deleteBenefit(e, benefit)}
                        />
                    ))}
                </div>
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {content.common.back}
                    </TextButton>
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
