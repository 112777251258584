import {
    ChannelListProvider,
    useChannelListContext
} from '@sendbird/uikit-react/ChannelList/context';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import '@sendbird/uikit-react/dist/index.css';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getNetworkConnections } from '../../../../services/user';
import { UserContext } from '../../../context/UserContext';

import {
    StyledChannelList,
    StyledChannelListContainer,
    StyledChannelListWrapper
} from './ChannelList.styles';
import ChannelPreview from './ChannelPreview';
import ChatHeader from './ChatHeader';

import type { NetworkConnection } from '../../../../types/user';

const ChannelList = ({
    channelURL,
    setChannelURL
}: {
    channelURL: string;
    setChannelURL: (url: string) => void;
}) => {
    const [unreadChats, setUnreadChats] = useState(0);
    const [networkChannelURLs, setNetworkChannelURLs] = useState<string[]>([]);
    const { allChannels } = useChannelListContext();
    const { user } = useContext(UserContext);

    useEffect(() => {
        let newUnreadChats = 0;
        allChannels.forEach((channel) => {
            if (channel.unreadMessageCount > 0) {
                newUnreadChats += channel.unreadMessageCount;
            }
        });
        setUnreadChats(newUnreadChats);
    }, [allChannels]);

    useEffect(() => {
        getNetworkConnections(user.id).then(
            (connections: NetworkConnection[]) => {
                const newChannelURLs = connections.map(
                    (connection: NetworkConnection) => connection.messageChatURL
                );
                setNetworkChannelURLs(newChannelURLs);
            }
        );
    }, [user.id]);

    return (
        <StyledChannelListWrapper>
            <ChatHeader unreadChats={unreadChats} />
            <StyledChannelListContainer>
                <StyledChannelList>
                    {allChannels.map(
                        (channel) =>
                            networkChannelURLs.includes(channel.url) && (
                                <ChannelPreview
                                    key={channel.createdAt}
                                    channel={channel}
                                    setChannelUrl={setChannelURL}
                                    channelUrl={channelURL}
                                    allChannels={allChannels}
                                />
                            )
                    )}
                </StyledChannelList>
            </StyledChannelListContainer>
        </StyledChannelListWrapper>
    );
};

const ChannelListWithProvider = (props: {
    channelURL: string;
    setChannelURL: (channelURL: string) => void;
}) => {
    const { uid, messageID } = useParams<{ uid: string; messageID: string }>();
    const { channelURL, setChannelURL } = props;
    const history = useHistory();

    useEffect(() => {
        if (messageID) {
            setChannelURL(messageID);
        }
    }, [messageID, setChannelURL]);

    const setChannel = (channel: string) => {
        setChannelURL(channel);
        history.push(`/secure/${uid}/messaging/${channel}`);
    };

    return (
        <SendbirdProvider
            appId={'E464F6AC-4712-468C-8CCF-4B1D4B130ECA'}
            userId={uid}
            nickname={'Athavan'}
        >
            <ChannelListProvider
                queries={{
                    channelListQuery: {
                        includeEmpty: true
                    }
                }}
            >
                <ChannelList
                    channelURL={channelURL}
                    setChannelURL={setChannel}
                />
            </ChannelListProvider>
        </SendbirdProvider>
    );
};

export default ChannelListWithProvider;
