import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

// todo: remove react equal height package
const StyledBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5rem 8rem',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const PointsContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '0',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}));

const PointContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    flexBasis: `${(4 / 12) * 100}%`,
    flexDirection: 'column',
    alignSelf: 'stretch',

    [theme.breakpoints.up('md')]: {
        paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
        paddingRight: 0
    }
}));

const StyledNumber = styled(Typography)(({ theme }) => ({
    backgroundColor: `${theme.palette.primary.light}`,
    borderRadius: '50%',
    color: `${theme.palette.primary.main}`,
    height: theme.spacing(8.5),
    width: theme.spacing(8.5),
    textAlign: 'center',
    lineHeight: theme.spacing(8.5),
    fontSize: theme.spacing(4),
    marginBottom: theme.spacing(4),
    userSelect: 'none',
    [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2)
    }
}));

export interface HowProps {
    heading: string;
    steps: {
        title: string;
        image: { file: { url: string } };
        stepImage: { file: { url: string } };
        content: string;
    }[];
}

export default function HowSection(props: HowProps) {
    return (
        <StyledBackground>
            <Typography
                mb={8}
                variant={'h2'}
                color='text.secondary'
                component={'h2'}
                sx={{ fontSize: '2.5rem', fontWeight: 'medium' }}
            >
                {props.heading}
            </Typography>
            <PointsContainer disableGutters>
                {props.steps.map((step, i, arr) => {
                    const isLast = i === arr.length - 1;
                    return (
                        <PointContainer
                            sx={{
                                pr: isLast ? 0 : 3,
                                pb: isLast ? 0 : 6
                            }}
                            disableGutters
                            key={step.title}
                        >
                            <StyledNumber>{i + 1}</StyledNumber>
                            <Typography
                                sx={{
                                    fontWeight: 'medium',
                                    lineHeight: '1.5'
                                }}
                                mb={1}
                                variant={'body2'}
                                color='text.secondary'
                                component='h6'
                                align='center'
                            >
                                {step.title}
                            </Typography>
                            <Typography
                                sx={{
                                    lineHeight: '1.5',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    flexGrow: '1'
                                }}
                                color='text.primary'
                                variant='body2'
                                align='center'
                            >
                                {step.content}
                            </Typography>
                        </PointContainer>
                    );
                })}
            </PointsContainer>
        </StyledBackground>
    );
}
