import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import MaterialButton from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import logo from '../../static/logos/name.svg';
import Button, { TextButton } from '../global/components/Button/Button';
import SidebarButton from '../global/layouts/MainContainer/SidebarButton/SidebarButton';

const StyledLogo = styled('img')(({ theme }) => ({
    height: 24,
    [`${theme.breakpoints.up('xs')}`]: {
        height: 22
    },
    [theme.breakpoints.up('sm')]: {
        height: 24
    }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
        display: 'none'
    }
}));

const StyledHeaderLink = styled(MaterialButton)<{ isCurrentPage: boolean }>(
    ({ isCurrentPage }) => `
    padding: 1rem;
    white-space: nowrap;
    border-radius: 0;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    color: ${isCurrentPage ? '#4B4B4B' : '#848485'};
    ${
        isCurrentPage &&
        `
        border-bottom: #7628C9 2px solid; 
        margin-bottom: -2px;`
    }
`
);

const StyledToolbar = styled(Toolbar)`
    min-height: 4.25rem;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 8rem;
    position: relative;
    @media (max-width: 55rem) {
        padding: 0 2rem;
    }
`;

const ToolbarButtonsFlex = styled('div')`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: 1;
    position: absolute;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
    @media (max-width: 55rem) {
        display: none;
    }
`;

const PrimaryButtons = styled('div')`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const LogoContainer = styled('div')`
    display: inline-flex;
    align-items: center;
`;

const PrimaryHeaderButton = styled(Button)`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`;

export interface NavBarProps {
    logo: { file: { url: string } };
    links: { content: string; link: string }[];
    logoLink: string;
}

const links = [
    { text: 'Career Seekers', to: '/public/career-seekers', active: false },
    { text: 'Employers', to: '/public/companies', active: false }
];

export default function NavBarSection(props: NavBarProps) {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <AppBar elevation={0} position='static' sx={{ bgcolor: '#FFFFFF' }}>
            <StyledToolbar>
                <LogoContainer disableGutters>
                    <StyledIconButton
                        edge='start'
                        color='primary'
                        aria-label='menu'
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        <MenuIcon />
                    </StyledIconButton>
                    <Link
                        component={RouterLink}
                        to={props.logoLink}
                        sx={{ display: 'inline-flex' }}
                    >
                        <img src={props.logo.file.url} />
                    </Link>
                </LogoContainer>
                <ToolbarButtonsFlex disableGutters>
                    {props.links.map(
                        (link: { content: string; link: string }) => (
                            <StyledHeaderLink
                                id={link.content.toLowerCase()}
                                key={link.content}
                                to={link.link}
                                isCurrentPage={location.pathname === link.link}
                                component={RouterLink}
                            >
                                {link.content}
                            </StyledHeaderLink>
                        )
                    )}
                </ToolbarButtonsFlex>
                <PrimaryButtons>
                    <TextButton to='/login'>{'Login'}</TextButton>
                    <div>
                        <PrimaryHeaderButton
                            variant='contained'
                            link='#waitlist'
                            disableRipple
                        >
                            {'Join Waitlist'}
                        </PrimaryHeaderButton>
                    </div>
                </PrimaryButtons>
            </StyledToolbar>
            <Drawer
                anchor={'left'}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                style={{ zIndex: 1202 }}
            >
                <List sx={{ p: 3 }}>
                    <StyledLogo src={logo} sx={{ mb: 2 }} />
                    {links.map((sidebar, index) => (
                        <SidebarButton
                            text={
                                sidebar.text as 'Career Seekers' | 'Employers'
                            }
                            to={sidebar.to}
                            active={sidebar.active}
                            key={index}
                        />
                    ))}
                </List>
            </Drawer>
        </AppBar>
    );
}
