import firebase from 'firebase/compat/app';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CreateIntroSlide from './CreateIntroSlide';
import Login from './Login';

export default function LoginRoutes(props: {
    user: firebase.User | null | undefined;
}) {
    return (
        <Switch>
            <Route exact path={['/signup/select']}>
                <CreateIntroSlide />
            </Route>
            <Route exact path={['/signup/user']}>
                {props.user && (
                    <Redirect to={`/create/${props.user.uid}/candidate/name`} />
                )}
                {props.user === null && <Login />}
            </Route>
            <Route exact path={['/signup/business']}>
                {props.user && (
                    <Redirect to={`/create/${props.user.uid}/business/name`} />
                )}
                {props.user === null && <Login />}
            </Route>
            <Route exact path={['/', '/login', '/resetpassword']}>
                {props.user && (
                    <Redirect to={`/secure/${props.user.uid}/dashboard`} />
                )}
                {props.user === null && <Login />}
            </Route>
        </Switch>
    );
}
