import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import MaterialTextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';

const content = { passwordToggleAriaLabel: 'toggle password visibility' };

interface TextFieldProps {
    id: string;
    label: string;
    color: 'primary' | 'secondary';
    error?: boolean;
    helperText?: string;
    errorText?: string;
    placeholder: string;
    password?: boolean;
    control: Control<any>;
    type?: string;
    rows?: number;
    outlined?: boolean;
    [x: string]: any;
}

const StyledTextField = styled(MaterialTextField)(({ theme }) => ({
    '& .MuiFormHelperText-root': {
        color: theme.palette.secondary.main
    },
    '& .Mui-error': {
        color: theme.palette.error.main
    }
}));

export default function TextField(props: TextFieldProps) {
    const [passwordVisible, setPasswordVisible] = useState(true);
    const {
        id,
        label,
        color,
        error,
        helperText,
        placeholder,
        password,
        control,
        type,
        rows,
        outlined,
        ...other
    } = props;
    let { errorText } = props;
    if (!errorText) {
        errorText = '';
    }
    return (
        <Controller
            name={id}
            control={control}
            render={({ field: { onChange, value } }) => (
                <StyledTextField
                    onChange={onChange}
                    value={value}
                    sx={{ my: 1 }}
                    id={id}
                    label={label}
                    color={color}
                    error={error}
                    helperText={error ? errorText : helperText}
                    placeholder={placeholder}
                    variant={outlined ? 'outlined' : 'standard'}
                    type={
                        password && passwordVisible
                            ? 'password'
                            : type || 'text'
                    }
                    multiline={!!rows}
                    rows={rows}
                    InputProps={{
                        endAdornment: password && (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label={content.passwordToggleAriaLabel}
                                    onClick={() =>
                                        setPasswordVisible(!passwordVisible)
                                    }
                                    edge='end'
                                    color={color}
                                >
                                    {passwordVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                        startAdornment: other.startAdornment
                    }}
                    {...other}
                />
            )}
        />
    );
}
