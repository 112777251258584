import GoogleIcon from '@mui/icons-material/Google';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/compat/app';
import React, { Fragment } from 'react';
import { Control, FieldError, FieldValues } from 'react-hook-form';

import Modal from '../../global/components/Modal/Modal';
import TextField from '../../global/components/TextField/TextField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';

import contentType from './content.json';
import {
    ButtonWithTextContainer,
    FormSection,
    StyledButton,
    StyledCollapse,
    StyledFormSubmitButton,
    StyledTextButton,
    StyledTextInput
} from './styles';
import { logInUserWithGoogle } from './userManagement';

export default function LoginDisplay(props: {
    auth: firebase.auth.Auth | null;
    content: typeof contentType;
    primaryAction: 'login' | 'signup' | 'resetpassword';
    errors: {
        email?: FieldError;
        password?: FieldError;
        confirmPassword?: FieldError;
    };
    control: Control<{
        primaryAction: string;
        email: string;
        password: string;
        confirmPassword: string;
    }>;
    onSubmit: (data: any) => void;
    setValue: (
        name: 'primaryAction' | 'email' | 'password' | 'confirmPassword',
        value: string
    ) => void;
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    passwordValue: string;
}) {
    const {
        content,
        primaryAction,
        errors,
        control,
        onSubmit,
        setValue,
        modalOpen,
        setModalOpen,
        passwordValue
    } = props;

    return (
        <Fragment>
            <MainContainer public fullWidth>
                <ColumnCard>
                    <FormSection onSubmit={onSubmit}>
                        <Typography
                            variant='h2'
                            component='h1'
                            fontWeight='400'
                            sx={{ mb: 1 }}
                            color='text.secondary'
                        >
                            {content.primaryAction[primaryAction]}
                        </Typography>
                        <TextField
                            id='email'
                            label={content.email.label}
                            color='primary'
                            error={!!errors.email}
                            errorText={errors.email?.message}
                            placeholder={content.email.placeholder}
                            control={control as unknown as Control<FieldValues>}
                        />
                        <StyledCollapse
                            direction='up'
                            in={primaryAction !== 'resetpassword'}
                            mountOnEnter
                            unmountOnExit
                        >
                            <StyledTextInput
                                id='password'
                                label={content.password.label}
                                color='primary'
                                error={!!errors.password}
                                helperText={content.password.helperText}
                                errorText={errors.password?.message}
                                placeholder={content.password.placeholder}
                                password
                                control={control}
                            />
                            <StyledCollapse
                                direction='up'
                                in={
                                    !!passwordValue &&
                                    primaryAction === 'signup'
                                }
                                mountOnEnter
                                unmountOnExit
                            >
                                <StyledTextInput
                                    id='confirmPassword'
                                    label={content.confirmPassword.label}
                                    color='primary'
                                    error={!!errors.confirmPassword}
                                    helperText={
                                        content.confirmPassword.helperText
                                    }
                                    errorText={errors.confirmPassword?.message}
                                    placeholder={
                                        content.confirmPassword.placeholder
                                    }
                                    password
                                    control={control}
                                />
                            </StyledCollapse>
                        </StyledCollapse>
                        {primaryAction === 'login' && (
                            <div>
                                <StyledTextButton
                                    id='goToResetPassword'
                                    onClick={() =>
                                        setValue(
                                            'primaryAction',
                                            'resetpassword'
                                        )
                                    }
                                >
                                    {content.changetoResetPassword}
                                </StyledTextButton>
                            </div>
                        )}
                        <StyledFormSubmitButton
                            id='submit'
                            variant='contained'
                            submit
                        >
                            {content.primaryAction[primaryAction]}
                        </StyledFormSubmitButton>
                        <StyledCollapse
                            direction='up'
                            in={primaryAction !== 'resetpassword'}
                            mountOnEnter
                            unmountOnExit
                        >
                            <StyledButton
                                id='googleSignIn'
                                variant='outlined'
                                startIcon={<GoogleIcon />}
                                sx={{ width: '100%', mt: 0 }}
                                onClick={() => logInUserWithGoogle(props.auth)}
                            >
                                {primaryAction === 'signup'
                                    ? content.googleSignUp
                                    : content.googleSignIn}
                            </StyledButton>
                        </StyledCollapse>
                        {primaryAction !== 'login' && (
                            <ButtonWithTextContainer color='text.secondary'>
                                {content.changetoLoginText}
                                <StyledTextButton
                                    id='goToLogin'
                                    onClick={() =>
                                        setValue('primaryAction', 'login')
                                    }
                                    sx={{ ml: 0.5 }}
                                >
                                    {content.changetoLoginCTA}
                                </StyledTextButton>
                            </ButtonWithTextContainer>
                        )}
                        {primaryAction === 'login' && (
                            <ButtonWithTextContainer color='text.secondary'>
                                {content.changetoSignupText}
                                <StyledTextButton
                                    id='goToSignup'
                                    to='/signup/select'
                                    sx={{ ml: 0.5 }}
                                >
                                    {content.changetoSignupCTA}
                                </StyledTextButton>
                            </ButtonWithTextContainer>
                        )}
                    </FormSection>
                </ColumnCard>
            </MainContainer>
            <Modal
                heading={content.passwordResetModal.heading}
                content={content.passwordResetModal.content}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                primaryCTAContent={content.passwordResetModal.CTAContent}
                primaryCTAAction={() => setModalOpen(false)}
            />
        </Fragment>
    );
}
