import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import jobPosting from '../../../static/illustrations/job-posting.svg';
import { TextButton } from '../../global/components/Button/Button';
import StyledCard from '../../global/Core';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';

const StyledImageContainer = styled('div')`
    max-width: 25rem;
    max-height: 25rem;
    margin: auto;
`;

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: theme.spacing(2)
}));

export default function CompleteSlide(props: { nextURL: string }) {
    return (
        <MainContainer public fullWidth>
            <StyledCard>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        marginTop: '4rem',
                        marginBottom: '4rem'
                    }}
                >
                    <Typography color='primary'>
                        {'Your job posting is live!'}
                    </Typography>
                    <Typography color='primary'>
                        {'Sit back, and let us bring great candidates to you.'}
                    </Typography>
                    <Typography color='primary'>
                        {
                            "You'll be notified each time an interview is scheduled"
                        }
                    </Typography>
                    <TextButton
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        to={props.nextURL}
                    >
                        {'Go to dashboard'}
                    </TextButton>
                    <StyledImageContainer>
                        <StyledImage src={jobPosting} />
                    </StyledImageContainer>
                </div>
            </StyledCard>
        </MainContainer>
    );
}
