import { styled, Typography } from '@mui/material';
import React from 'react';

import dashboardImage from '../../../../static/illustrations/dashboard.svg';

import JobCard from './SummaryJobCard';

import type { Job } from '../../../../types/job';

const StyledImageContainer = styled('div')`
    max-width: 25rem;
    max-height: 25rem;
    margin: auto;
`;

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: theme.spacing(4.5)
}));

export default function JobList({ jobs }: { jobs: Job[] }) {
    return (
        <div style={{ minHeight: '100vh' }}>
            <Typography
                variant='body2'
                color={'secondary.main'}
                style={{
                    display: 'flex',
                    marginTop: '0.875rem',
                    marginBottom: '2.5rem'
                }}
            >
                {'We found '}
                <Typography
                    style={{
                        marginLeft: '0.25rem',
                        marginRight: '0.25rem'
                    }}
                    color='primary'
                    variant='body2'
                    component='span'
                >
                    <b>{jobs.length}</b>
                </Typography>
                {'new opportunities for you.'}
            </Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem'
                }}
            >
                {jobs.map((job, index) => (
                    <JobCard key={index} job={job} />
                ))}
            </div>
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '4.5rem'
                }}
            >
                <Typography variant='body2' color={'text.secondary'}>
                    {"You're all caught up on available roles."}
                </Typography>
                <Typography variant='body2' color={'text.secondary'}>
                    {'Check back soon for more opportunites!'}
                </Typography>
                <StyledImageContainer>
                    <StyledImage src={dashboardImage} />
                </StyledImageContainer>
            </div>
        </div>
    );
}
