// TODO:
/* eslint max-lines: off */
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import Select from '../../../global/components/SelectField';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import {
    FormSection,
    NextSteps,
    StyledButton,
    StyledDynamicInputContainer,
    StyledSelect
} from '../styles';

import content from './content';

import type { WorkingPreference } from '../../../../types/enum';
import type { User } from '../../../../types/user';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        desiredRoles: yup.array().of(
            yup.object().shape({
                role: yup
                    .string()
                    .oneOf([
                        'product manager',
                        'software engineer',
                        'designer',
                        'other'
                    ])
                    .required(commonContent.common.errors.required)
            })
        ),
        desiredLocations: yup
            .array()
            .of(
                yup.object().shape({
                    location: yup
                        .string()
                        .oneOf(['toronto', 'montreal', 'vancouver'])
                        .required(commonContent.common.errors.required)
                })
            )
            .required(),
        workingEnvironment: yup
            .string()
            .oneOf(['no_preference', 'office', 'remote', 'hybrid'])
            .required(commonContent.common.errors.required),
        desiredSalary: yup
            .number()
            .typeError(content.idealRole.errors.salaryNumber)
            .min(30000, content.idealRole.errors.salaryMin)
            .required(commonContent.common.errors.required)
    })
    .required();

export default function IdealRoleSlide(props: {
    profile: User['idealRole'];
    updateProfile: (value: User['idealRole']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            desiredRoles: props.profile.desiredRoles.map((role) => ({ role })),
            desiredLocations: props.profile.desiredLocations.map(
                (location) => ({ location })
            ),
            workingEnvironment: props.profile.workingEnvironment,
            desiredSalary: props.profile.desiredSalary.toString()
        }
    });
    const { fields: roleFields } = useFieldArray({
        control,
        name: 'desiredRoles'
    });
    const {
        fields: locationFields,
        append: locationAppend,
        remove: locationRemove
    } = useFieldArray({
        control,
        name: 'desiredLocations'
    });

    const onSubmit = (data: {
        desiredRoles: { role: string }[];
        desiredLocations: { location: string }[];
        workingEnvironment: WorkingPreference;
        desiredSalary: string;
    }) => {
        props.updateProfile({
            desiredRoles: data.desiredRoles.map((role) => role.role),
            desiredLocations: data.desiredLocations.map(
                (location) => location.location
            ),
            workingEnvironment: data.workingEnvironment,
            desiredSalary: parseInt(data.desiredSalary, 10)
        });
    };

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'idealRoleSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>{commonContent.common.stepTwoSubtitle}</Typography>
                {roleFields.map((field, index) => (
                    <StyledDynamicInputContainer key={field.id}>
                        <StyledSelect
                            id={`desiredRoles.${index}.role`}
                            label={content.idealRole.desiredRoles.label}
                            color='primary'
                            error={!!errors.desiredRoles?.[index]?.role}
                            errorText={
                                errors.desiredRoles?.[index]?.role?.message
                            }
                            control={control as unknown as Control<FieldValues>}
                            options={content.idealRole.desiredRoles.options}
                        />
                    </StyledDynamicInputContainer>
                ))}
                <Typography fontWeight='400' sx={{ mb: 1 }}>
                    {'We found 273 roles in that field'}
                </Typography>
                <Typography fontWeight='400' sx={{ mb: 1 }}>
                    {'Average salary: $80,000'}
                </Typography>
                <TextInput
                    id='desiredSalary'
                    label={content.idealRole.desiredSalary.label}
                    color='primary'
                    error={!!errors.desiredSalary}
                    errorText={errors.desiredSalary?.message}
                    placeholder={content.idealRole.desiredSalary.placeholder}
                    control={control as unknown as Control<FieldValues>}
                    type='number'
                    startAdornment={
                        <InputAdornment position='start'>
                            {content.idealRole.desiredSalary.currencySymbol}
                        </InputAdornment>
                    }
                />
                {locationFields.map((field, index) => (
                    <StyledDynamicInputContainer key={field.id}>
                        <StyledSelect
                            id={`desiredLocations.${index}.location`}
                            label={content.idealRole.desiredLocations.label}
                            color='primary'
                            error={!!errors.desiredLocations?.[index]?.location}
                            errorText={
                                errors.desiredLocations?.[index]?.location
                                    ?.message
                            }
                            control={control as unknown as Control<FieldValues>}
                            options={content.idealRole.desiredLocations.options}
                        />
                        {locationFields.length > 1 && (
                            <IconButton
                                id={`desiredLocations.${index}.remove`}
                                color='primary'
                                aria-label={
                                    content.idealRole.desiredLocations.remove
                                }
                                component='label'
                                sx={{ ml: 1 }}
                                onClick={() => locationRemove(index)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        )}
                    </StyledDynamicInputContainer>
                ))}
                <Typography fontWeight='400' sx={{ mb: 1 }}>
                    {'We found 275 roles in that location'}
                </Typography>
                <div>
                    <TextButton
                        id={'addLocation'}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{ fontWeight: 400, mb: 1 }}
                        onClick={() => locationAppend({ location: 'toronto' })}
                    >
                        {content.idealRole.desiredLocations.add}
                    </TextButton>
                </div>
                <Select
                    id='workingEnvironment'
                    label={content.idealRole.workingEnvironment.label}
                    color='primary'
                    error={!!errors.workingEnvironment}
                    errorText={errors.workingEnvironment?.message}
                    control={control as unknown as Control<FieldValues>}
                    options={content.idealRole.workingEnvironment.options}
                />
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {commonContent.common.back}
                    </TextButton>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
