// todo add storybook stories

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

interface RadioButtonProps {
    id: string;
    labels: { value: string; label: string }[];
    color: 'primary' | 'secondary';
    error?: boolean;
    helperText?: string;
    errorText?: string;
    title: string;
    control: Control;
    row?: boolean;
    labelTop?: boolean;
}

export const StyledRadioGroup = styled(RadioGroup)<{ labelTop: boolean }>(
    ({ labelTop }) => `
    justify-content: ${labelTop ? 'space-between' : 'inherit'};
    @media (max-width: 600px) {
        flex-direction: ${labelTop ? 'column' : 'inherit'};
        justify-content: ${labelTop ? 'center' : 'inherit'};   
    }
`
);

export default function RadioButton(props: RadioButtonProps) {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const {
        id,
        labels,
        color,
        error,
        helperText,
        control,
        title,
        row,
        labelTop,
        ...other
    } = props;
    let { errorText } = props;
    if (!errorText) {
        errorText = '';
    }
    return (
        <Controller
            name={id}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl
                    error={error}
                    component='fieldset'
                    variant='standard'
                    id={id}
                    color={color}
                    sx={{ width: '100%' }}
                >
                    <FormLabel id='demo-radio-buttons-group-label'>
                        <Typography
                            sx={{
                                mt: 1,
                                mb: labelTop ? 2 : 0,
                                fontSize: labelTop ? '1rem' : 'inherit',
                                textAlign:
                                    labelTop && width > 600
                                        ? 'center'
                                        : 'inherit'
                            }}
                            color='secondary'
                        >
                            {title}
                        </Typography>
                    </FormLabel>
                    <StyledRadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        name='radio-buttons-group'
                        onChange={onChange}
                        row={row || false}
                        value={value}
                        {...other}
                        labelTop={labelTop}
                    >
                        {labels.map((label) => (
                            <FormControlLabel
                                key={label.value}
                                value={label.value}
                                control={
                                    <Radio sx={{ color: 'secondary.main' }} />
                                }
                                labelPlacement={
                                    labelTop && width > 600 ? 'top' : 'end'
                                }
                                label={label.label}
                                sx={{ color: 'text.primary' }}
                            />
                        ))}
                    </StyledRadioGroup>
                    <FormHelperText>
                        {error ? errorText : helperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
}
