const getBackend = () => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:8000';
    }
    return 'https://levyl.app';
};

const getFrontend = () => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:3000';
    }
    return 'https://levyl.app';
};

const getProjectID = () => {
    if (window.location.hostname === 'localhost') {
        return 'demo-levyl';
    }
    return 'levyl-11cd1';
};

export const frontEndRoot = getFrontend();
export const backEndRoot = getBackend();
export const projectID = getProjectID();
