import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import React from 'react';

export const BaseCard = styled(Card)`
    border-radius: 1rem;
    padding: 2rem;
`;

const ProfilePictureBase = styled('div')<{ src: string }>(
    ({ src }) => `
    background-color: white;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-image: url(${src});
`
);

export const ProfilePicture = styled(ProfilePictureBase)<{ size: number }>(
    ({ size }) => `
    height: ${size}rem;
    width: ${size}rem;
    flex-shrink: 0;
`
);

const Container = styled('div')`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const InnerContainer = styled('div')<{ width: number }>(
    ({ width }) => `
    margin-top: 1.25rem;
    width: ${width}rem;
    @media (max-width: ${width + 17}rem) {
        width: 100%;
    }
`
);

export const BaseContainer = ({
    children,
    width = 50
}: {
    children: any;
    width?: number;
}) => (
    <Container>
        <InnerContainer width={width}>{children}</InnerContainer>
    </Container>
);

export default BaseCard;
