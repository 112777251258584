import firebase from 'firebase/compat/app';

import { frontEndRoot } from '../../constants';

export const content = {
    resetPassword: "We couldn't find an account associated with this email",
    login: 'The credentials you entered are invalid.'
};

export const createUser = (
    auth: firebase.auth.Auth | null,
    email: string,
    password: string
): Promise<{
    code: string;
    type?: string;
    message?: string;
    user?: firebase.User;
}> => {
    const user =
        auth
            ?.createUserWithEmailAndPassword(email, password)
            .then((userCredential) => ({
                code: 'success',
                type: 'createUser',
                user: userCredential.user
            }))
            .catch((error) => error) ??
        Promise.resolve({ code: 'error', message: 'auth is null' });
    return user;
};

export const logInUserWithGoogle = (auth: firebase.auth.Auth | null) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const user = auth?.signInWithRedirect(provider).then(() => ({
        code: 'success',
        type: 'googleLogInUser'
    }));
    return user;
};

export const resetPassword = (
    auth: firebase.auth.Auth | null,
    email: string
): Promise<{ code: string; type: string; message?: string }> => {
    const actionCodeSettings = {
        url: frontEndRoot
    };
    return (
        auth
            ?.sendPasswordResetEmail(email, actionCodeSettings)
            .then(() => ({ code: 'success', type: 'resetPassword' }))
            .catch(() => ({
                code: 'error',
                type: 'resetPassword',
                message: content.resetPassword
            })) ??
        Promise.resolve({
            code: 'error',
            type: 'resetPassword',
            message: 'auth is null'
        })
    );
};

export const logInUser = (
    auth: firebase.auth.Auth | null,
    email: string,
    password: string
): Promise<{
    code: string;
    type: string;
    user?: firebase.User | null;
    message?: string;
}> => {
    const user =
        auth
            ?.signInWithEmailAndPassword(email, password)
            .then((userCredential) => ({
                code: 'success',
                type: 'logInUser',
                user: userCredential.user
            }))
            .catch(() => ({
                code: 'error',
                type: 'logInUser',
                message: content.login
            })) ??
        Promise.resolve({
            code: 'error',
            type: 'logInUser',
            message: 'auth is null'
        });
    return user;
};
