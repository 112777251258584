import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useMemo } from 'react';

import { TextButton } from '../../../global/components/Button/Button';
import StyledCard from '../../../global/Core';

import type { Job } from '../../../../types/job';

const StyledCardHeader = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
`;

const StyledCardFooter = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
`;

const StyledJobName = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
`;

const Status = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
`;

const OpenStatus = styled('div')`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.25rem;
`;

const OpenCircle = styled('div')`
    display: flex;
    background-color: #8ef2be;
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 50%;
    margin-top: 1px;
`;

const ClosedCircle = styled('div')`
    display: flex;
    background-color: red;
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 50%;
    margin-top: 1px;
`;

const RemainingDaysPill = styled('div')`
    display: flex;
    background-color: #f6f6f6;
    border-radius: 0.5rem;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    @media (max-width: 600px) {
        display: none;
    }
`;

export default function EmployerJobCard({
    job,
    location,
    userID
}: {
    job: Job;
    location: string;
    userID: string;
}) {
    const daysRemainingOpen = useMemo(() => {
        if (!job.open) return null;
        const daysPosted = moment().diff(
            moment.utc(job.createdAt).local(),
            'days'
        );
        return 30 - daysPosted;
    }, [job.open, job.createdAt]);

    return (
        <StyledCard
            variant='outlined'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4rem',
                justifyContent: 'space-between',
                paddingBottom: '1.5rem'
            }}
        >
            <StyledCardHeader>
                <StyledJobName>
                    <Typography variant={'body1'}>{location}</Typography>
                    <Typography
                        variant={'h2'}
                        style={{
                            display: 'flex',
                            fontWeight: 400,
                            fontSize: '20px'
                        }}
                    >
                        {job.title}
                    </Typography>
                </StyledJobName>
                <Status>
                    {job.open ? (
                        <>
                            <OpenStatus>
                                <OpenCircle />
                                <Typography variant={'body1'}>
                                    {'Open'}
                                </Typography>
                            </OpenStatus>

                            <RemainingDaysPill>
                                <Typography variant={'body1'}>
                                    {`Closing in ${daysRemainingOpen} days`}
                                </Typography>
                            </RemainingDaysPill>
                        </>
                    ) : (
                        <>
                            <OpenStatus>
                                <ClosedCircle />
                                <Typography variant={'body1'}>
                                    {'Closed'}
                                </Typography>
                            </OpenStatus>
                        </>
                    )}
                </Status>
            </StyledCardHeader>
            <StyledCardFooter>
                <div
                    style={{
                        display: 'flex',
                        gap: '0.25rem',
                        alignItems: 'center '
                    }}
                >
                    <Typography
                        variant={'body2'}
                        color='primary'
                        fontWeight={700}
                        fontSize={28}
                        component='span'
                    >
                        {
                            job.interviews.filter(
                                (interview) => interview.status === 'scheduled'
                            ).length
                        }
                    </Typography>
                    <Typography variant={'body2'} component='span'>
                        <b style={{ fontSize: '28px' }}>{'/'}</b>
                    </Typography>
                    <Typography
                        variant={'body2'}
                        fontWeight={700}
                        fontSize={28}
                        component='span'
                    >
                        {
                            job.interviews.filter((interview) =>
                                ['scheduled', 'available'].includes(
                                    interview.status
                                )
                            ).length
                        }
                    </Typography>
                    <Typography
                        variant={'body2'}
                        component='span'
                        sx={{ ml: 0.5 }}
                    >
                        {'interviews booked'}
                    </Typography>
                </div>
                <TextButton
                    to={`/edit/${userID}/job/${job.companyID}/${job.id}/intro`}
                >
                    <Typography
                        style={{ display: 'flex', alignItems: 'center' }}
                        variant={'body2'}
                        component='span'
                    >
                        <b>{'Manage Posting'}</b>
                        <ChevronRightIcon />
                    </Typography>
                </TextButton>
            </StyledCardFooter>
        </StyledCard>
    );
}
