import React from 'react';

import { InterviewCardContent } from './InterviewCard';

import type { Interview } from '../../../types/interview';
import type { User } from '../../../types/user';

const FollowupCard = ({
    user,
    interviews
}: {
    user: User;
    interviews: Interview[];
}) => (
    <>
        <InterviewCardContent
            image={interviews[0].company.logoURL}
            statusText={'Follow-up Requested'}
            jobTitle={interviews[0].job.title}
            companyName={interviews[0].company.name}
            name={`${interviews[0].participant?.name.firstName} ${interviews[0].participant?.name.lastName}`}
            primaryAction={{
                text: 'Book Follow-up',
                to: `/secure/${user.userID}/schedulefollowup/${interviews[0].job.id}`
            }}
        />
    </>
);

export default FollowupCard;
