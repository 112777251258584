// todo add storybook stories

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface SelectFieldProps {
    id: string;
    label: string;
    color: 'primary' | 'secondary';
    error?: boolean;
    helperText?: string;
    errorText?: string;
    options: { value: string; label: string }[];
    control: Control<any>;
}

export default function SelectField(props: SelectFieldProps) {
    const { id, label, options, color, error, helperText, control, ...other } =
        props;
    let { errorText } = props;
    if (!errorText) {
        errorText = '';
    }
    return (
        <Controller
            name={id}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl
                    variant='standard'
                    sx={{ my: 1, minWidth: 120 }}
                    {...other}
                >
                    <InputLabel id='demo-simple-select-standard-label'>
                        {label}
                    </InputLabel>
                    <Select
                        id={id}
                        value={value}
                        onChange={onChange}
                        label={label}
                        color={color}
                        error={error}
                        variant='standard'
                    >
                        {options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        {error ? errorText : helperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
}
