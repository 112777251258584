import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import React from 'react';

export const StyledHeader = styled((props: ContainerProps) => (
    <Container {...props} />
))(({ justifyContent, alignItems, sx, gap, borderBottom, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent,
    alignItems,
    padding: sx ? '' : '0',
    margin: '0',
    width: 'auto',
    gap,
    borderBottom,
    [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
        paddingBottom: '1rem'
    }
}));

export default StyledHeader;
