import { useCallback, useEffect, useMemo, useState } from 'react';

import { provideInterviewFeedback } from '../../../../services/interviews';

import type { Interview } from '../../../../types/interview';

type FeedbackValues = {
    questions: { question: string; rating: string }[];
    generalFeedback: string;
    strongCurrentCandidate: string;
    strongFutureCandidate: string;
    userQuestions: { question: string; response: string }[];
};

const questionIDs = [
    'hard_skills',
    'soft_skills',
    'potential',
    'team_fit',
    'overall_impression'
];

export const createFeedbackMap = (interview: Interview | undefined) => {
    if (!interview) return null;
    const feedbackMap = { system: {}, user: {} } as {
        system: { [key: string]: Interview['feedback'][0] };
        user: { [key: string]: Interview['feedback'][0] };
    };
    interview.feedback.forEach((feedback) => {
        if (feedback.question.systemGeneratedID) {
            feedbackMap.system[feedback.question.systemGeneratedID] = feedback;
        } else {
            feedbackMap.user[feedback.question.id] = feedback;
        }
    });
    return feedbackMap;
};

export default function useInterviewFeedback(interview: Interview | undefined) {
    const [feedbackValues, setFeedbackValues] = useState<FeedbackValues>({
        questions: [],
        generalFeedback: '',
        strongCurrentCandidate: '',
        strongFutureCandidate: '',
        userQuestions: []
    });

    const feedbackMap = useMemo(
        () => createFeedbackMap(interview),
        [interview]
    );

    useEffect(() => {
        if (feedbackMap) {
            setFeedbackValues({
                generalFeedback:
                    feedbackMap.system.feedback.answer?.answer ?? '',
                questions: questionIDs.map((id) => ({
                    question: feedbackMap.system[id].question.question,
                    rating: feedbackMap.system[id].answer?.answer ?? '0'
                })),
                strongCurrentCandidate:
                    feedbackMap.system.current_candidate.answer?.answer ?? '',
                strongFutureCandidate:
                    feedbackMap.system.future_candidate.answer?.answer ?? '',
                userQuestions: Object.keys(feedbackMap.user).map(
                    (userQuestionID) => ({
                        question:
                            feedbackMap.user[userQuestionID].question.question,
                        response:
                            feedbackMap.user[userQuestionID].answer?.answer ??
                            ''
                    })
                )
            });
        }
    }, [feedbackMap]);

    const updateFeedbackValues = useCallback(
        (newFeedbackValues: Partial<FeedbackValues>) => {
            setFeedbackValues((prevFeedbackValues) => ({
                ...prevFeedbackValues,
                ...newFeedbackValues
            }));
        },
        []
    );

    const getFeedbackResponse = useCallback(
        (data: Partial<FeedbackValues>) => {
            const feedbackResponse = [] as {
                id: number;
                questionID: number;
                answer: string;
            }[];
            Object.keys(data).forEach((key) => {
                switch (key) {
                    case 'generalFeedback':
                        feedbackResponse.push({
                            id: feedbackMap?.system.feedback.answer?.id ?? 0,
                            questionID:
                                feedbackMap?.system.feedback.question.id ?? 0,
                            answer: data[key] ?? ''
                        });
                        break;
                    case 'strongCurrentCandidate':
                        feedbackResponse.push({
                            id:
                                feedbackMap?.system.current_candidate.answer
                                    ?.id ?? 0,
                            questionID:
                                feedbackMap?.system.current_candidate.question
                                    .id ?? 0,
                            answer: data[key] ?? ''
                        });
                        break;
                    case 'strongFutureCandidate':
                        feedbackResponse.push({
                            id:
                                feedbackMap?.system.future_candidate.answer
                                    ?.id ?? 0,
                            questionID:
                                feedbackMap?.system.future_candidate.question
                                    .id ?? 0,
                            answer: data[key] ?? ''
                        });
                        break;
                    case 'questions':
                        data.questions?.forEach((question) => {
                            const questionID =
                                Object.keys(feedbackMap?.system ?? {}).find(
                                    (qKey) =>
                                        feedbackMap?.system[qKey].question
                                            .question === question.question
                                ) ?? '';
                            feedbackResponse.push({
                                id:
                                    feedbackMap?.system[questionID].answer
                                        ?.id ?? 0,
                                questionID:
                                    feedbackMap?.system[questionID].question
                                        .id ?? 0,
                                answer: question.rating
                            });
                        });
                        break;
                    case 'userQuestions':
                        data.userQuestions?.forEach((question) => {
                            const questionID =
                                Object.keys(feedbackMap?.user ?? {}).find(
                                    (qKey) =>
                                        feedbackMap?.user[qKey].question
                                            .question === question.question
                                ) ?? '';
                            feedbackResponse.push({
                                id:
                                    feedbackMap?.user[questionID].answer?.id ??
                                    0,
                                questionID:
                                    feedbackMap?.user[questionID].question.id ??
                                    0,
                                answer: question.response
                            });
                        });
                        break;
                    default:
                        break;
                }
            });
            return feedbackResponse;
        },
        [feedbackMap]
    );

    const submitFeedback = useCallback(
        (data: Partial<FeedbackValues>) => {
            if (interview?.id && feedbackMap) {
                return provideInterviewFeedback(
                    interview.id,
                    getFeedbackResponse(feedbackValues),
                    false
                ).then(() => {
                    updateFeedbackValues(data);
                });
            }
            return Promise.resolve();
        },
        [
            feedbackValues,
            interview?.id,
            feedbackMap,
            getFeedbackResponse,
            updateFeedbackValues
        ]
    );

    return {
        feedbackValues,
        updateFeedbackValues,
        submitFeedback,
        content: {
            feedback: feedbackMap?.system.feedback.question.question ?? '',
            questions: questionIDs.map(
                (id) => feedbackMap?.system[id].question.question ?? ''
            )
        }
    };
}
