import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
// import ScreenShareIcon from '@mui/icons-material/ScreenShare';
// import SettingsIcon from '@mui/icons-material/Settings';
// import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React from 'react';

const Container = styled('div')`
    display: flex;
    gap: 1.5rem;
`;

const ChatButton = styled(IconButton)`
    @media (max-width: 900px) {
        display: none;
    }
`;

export default function ChatTray(props: {
    audio: boolean;
    video: boolean;
    screenShare: boolean;
    chat: boolean;
    updateLocal: (stream: 'audio' | 'video', value: boolean) => void;
    endSingleInterview: () => void;
    toggleSidebar: () => void;
}) {
    return (
        <Container>
            <IconButton
                size='large'
                color='primary'
                onClick={() => {
                    props.updateLocal('audio', !props.audio);
                }}
            >
                {props.audio ? (
                    <MicIcon fontSize='inherit' />
                ) : (
                    <MicOffIcon fontSize='inherit' />
                )}
            </IconButton>
            <IconButton
                size='large'
                color='primary'
                onClick={() => {
                    props.updateLocal('video', !props.video);
                }}
            >
                {props.video ? (
                    <VideocamIcon fontSize='inherit' />
                ) : (
                    <VideocamOffIcon fontSize='inherit' />
                )}
            </IconButton>
            {/* TODO: Add screenshare */}
            {/* <IconButton size='large' color='primary'>
                {props.screenShare ? (
                    <ScreenShareIcon fontSize='inherit' />
                ) : (
                    <StopScreenShareIcon fontSize='inherit' />
                )}
            </IconButton> */}
            <ChatButton
                size='large'
                color='primary'
                onClick={props.toggleSidebar}
            >
                {props.chat ? (
                    <ChatIcon fontSize='inherit' />
                ) : (
                    <CommentsDisabledIcon fontSize='inherit' />
                )}
            </ChatButton>
            <IconButton
                size='large'
                color='primary'
                onClick={props.endSingleInterview}
            >
                <CloseIcon fontSize='inherit' />
            </IconButton>
            {/* TODO: Add settings */}
            {/* <IconButton size='large' color='primary'>
                <SettingsIcon fontSize='inherit' />
            </IconButton> */}
        </Container>
    );
}
