import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Button from '../../../global/components/Button/Button';
import { ProfilePicture } from '../../../global/Core';

export const StyledCardHeader = styled(Grid)(() => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '400'
}));

export const CardHeaderText = styled('div')`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const BookmarkButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
    minWidth: 'unset',
    '&:hover': {
        padding: theme.spacing(0),
        backgroundColor: 'unset'
    },
    '&:focus': {
        backgroundColor: 'unset'
    },
    alignSelf: 'flex-start',
    marginTop: '3px'
}));

export const StyledCardBody = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#F9F9F9',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)}`
}));

export const StyledCardFooter = styled('div')`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
`;

export const StyledProfilePicture = styled(ProfilePicture)`
    @media (max-width: 600px) {
        display: none;
    }
`;
