import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import React, { useRef } from 'react';

const StyledPreviewContainer = styled(Container)(({ theme, isSelected }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem 1rem 1rem 1.5rem',
    margin: '0',
    cursor: 'pointer',
    transition: '.3s',
    width: '100%',
    backgroundColor: isSelected ? 'rgba(118, 40, 201, 0.06)' : '',
    borderTop: '1px solid #EFEFEF',
    '&:hover': {
        backgroundColor: 'rgba(118, 40, 201, 0.06)',
        transition: '.3s'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '1rem 1.5rem 1rem 2.5rem'
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}));

const StyledPreviewContentContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '0',
    padding: '0',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}));

interface CustomChannelPreviewProps {
    channel: any;
    channelUrl: string;
    setChannelUrl: (a: string) => void;
    allChannels?: any[];
}

const CustomChannelPreview = ({
    channel,
    channelUrl,
    setChannelUrl
}: // allChannels
CustomChannelPreviewProps) => {
    const sdkInstance = sendbirdSelectors.getSdk(useSendbirdStateContext());
    const targetUser = channel.members.filter(
        (member: any) => member.userId !== sdkInstance?.currentUser.userId
    )[0];
    const forwardedRef = useRef<HTMLDivElement>(null);

    // React.useEffect(() => {
    //     if (channel.url === allChannels[0].url) {
    //         forwardedRef?.current?.click();
    //     }
    // }, [allChannels, channel.url]);

    return (
        <StyledPreviewContainer
            isSelected={channel.url === channelUrl}
            onClick={() => {
                setChannelUrl(channel.url);
            }}
            ref={forwardedRef}
        >
            <Badge
                variant='dot'
                color='primary'
                invisible={channel.unreadMessageCount === 0}
            >
                <Avatar
                    alt={targetUser?.nickname}
                    src={targetUser?.profileUrl}
                    sx={{
                        width: 48,
                        height: 48,
                        mr: 1,
                        bgcolor: '#C4C4C4'
                    }}
                />
            </Badge>
            <StyledPreviewContentContainer>
                <Typography
                    sx={{
                        fontWeight: 400,
                        lineHeight: '1',
                        ml: 1
                    }}
                    variant={'body2'}
                    color='text.secondary'
                    component='h3'
                    mb={1}
                >
                    {targetUser.nickname}
                </Typography>
                {/* <Typography
                    sx={{
                        fontWeight: 400,
                        lineHeight: '1'
                    }}
                    variant={'body1'}
                    color='secondary'
                    component='p'
                >
                    {channel.name}
                </Typography> */}
            </StyledPreviewContentContainer>
        </StyledPreviewContainer>
    );
};

export default CustomChannelPreview;
