import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import TelegramIcon from '@mui/icons-material/Telegram';
import Title from '@mui/icons-material/Title';
import WorkIcon from '@mui/icons-material/Work';
import React from 'react';
import { useLocation } from 'react-router-dom';

import type { SideBarButtons } from './SidebarButton';

export const iconMapping = {
    Dashboard: <PersonIcon />,
    'My Interviews': <WorkIcon />,
    'My Network': <SchoolIcon />,
    Messaging: <TelegramIcon />,
    'Job Offers': <TelegramIcon />,
    Name: <TelegramIcon />,
    Location: <TelegramIcon />,
    'Ideal Role': <TelegramIcon />,
    Resume: <TelegramIcon />,
    Experience: <TelegramIcon />,
    Education: <TelegramIcon />,
    Certification: <TelegramIcon />,
    Skills: <TelegramIcon />,
    Portfolio: <TelegramIcon />,
    Questions: <TelegramIcon />,
    Demographic: <TelegramIcon />,
    Info: <TelegramIcon />,
    Description: <TelegramIcon />,
    Plans: <TelegramIcon />,
    Intro: <TelegramIcon />,
    Title: <Title />,
    Salary: <TelegramIcon />,
    Environment: <TelegramIcon />,
    Calendar: <TelegramIcon />,
    Time: <TelegramIcon />,
    Complete: <TelegramIcon />,
    'Career Seekers': <PersonIcon />,
    Employers: <WorkIcon />
};

export const useDefaultSidebarButtons = (userID: string): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        {
            text: 'Dashboard',
            to: `/secure/${userID}/dashboard`,
            active: pathname.includes('dashboard')
        },
        {
            text: 'My Interviews',
            to: `/secure/${userID}/upcominginterviews`,
            active: pathname.includes('upcominginterviews')
        },
        {
            text: 'My Network',
            to: `/secure/${userID}/network`,
            active: pathname.includes('network')
        },
        {
            text: 'Messaging',
            to: `/secure/${userID}/messaging`,
            active: pathname.includes('messaging')
        }
    ];
};

export const useCandidateProfileSidebarButtons = (
    userID: string
): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        { text: 'Dashboard', to: `/secure/${userID}/dashboard`, active: false },
        {
            text: 'Name',
            to: `/edit/${userID}/candidate/name`,
            active: pathname.includes('name')
        },
        {
            text: 'Location',
            to: `/edit/${userID}/candidate/location`,
            active: pathname.includes('location')
        },
        {
            text: 'Ideal Role',
            to: `/edit/${userID}/candidate/idealrole`,
            active: pathname.includes('idealrole')
        },
        {
            text: 'Resume',
            to: `/edit/${userID}/candidate/resume`,
            active: pathname.includes('resume')
        },
        {
            text: 'Experience',
            to: `/edit/${userID}/candidate/experience`,
            active: pathname.includes('experience')
        },
        {
            text: 'Education',
            to: `/edit/${userID}/candidate/education`,
            active: pathname.includes('education')
        },
        {
            text: 'Certification',
            to: `/edit/${userID}/candidate/certification`,
            active: pathname.includes('certification')
        },
        {
            text: 'Skills',
            to: `/edit/${userID}/candidate/skills`,
            active: pathname.includes('skills')
        },
        {
            text: 'Portfolio',
            to: `/edit/${userID}/candidate/portfolio`,
            active: pathname.includes('portfolio')
        },
        {
            text: 'Questions',
            to: `/edit/${userID}/candidate/questions`,
            active: pathname.includes('questions')
        },
        {
            text: 'Demographic',
            to: `/edit/${userID}/candidate/demographics`,
            active: pathname.includes('demographics')
        }
    ];
};

export const useBusinessProfileSidebarButtons = (
    userID: string,
    companyID: number
): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        { text: 'Dashboard', to: `/secure/${userID}/dashboard`, active: false },
        {
            text: 'Name',
            to: `/edit/${userID}/business/${companyID}/name`,
            active: pathname.includes('name')
        },
        {
            text: 'Location',
            to: `/edit/${userID}/business/${companyID}/location`,
            active: pathname.includes('location')
        },
        {
            text: 'Info',
            to: `/edit/${userID}/business/${companyID}/companypage`,
            active: pathname.includes('companypage')
        },
        {
            text: 'Description',
            to: `/edit/${userID}/business/${companyID}/companydescription`,
            active: pathname.includes('companydescription')
        },
        {
            text: 'Plans',
            to: `/edit/${userID}/business/${companyID}/plans`,
            active: pathname.includes('plans')
        }
    ];
};

export const useCreateJobSidebarButtons = (
    userID: string,
    companyID: number,
    jobID: number
): SideBarButtons => {
    const { pathname } = useLocation();
    return [
        { text: 'Dashboard', to: `/secure/${userID}/dashboard`, active: false },
        {
            text: 'Intro',
            to: `/edit/${userID}/job/${companyID}/${jobID}/intro`,
            active: pathname.includes('intro')
        },
        {
            text: 'Title',
            to: `/edit/${userID}/job/${companyID}/${jobID}/title`,
            active: pathname.includes('title')
        },
        {
            text: 'Education',
            to: `/edit/${userID}/job/${companyID}/${jobID}/education`,
            active: pathname.includes('education')
        },
        {
            text: 'Environment',
            to: `/edit/${userID}/job/${companyID}/${jobID}/environment`,
            active: pathname.includes('environment')
        },
        {
            text: 'Salary',
            to: `/edit/${userID}/job/${companyID}/${jobID}/salary`,
            active: pathname.includes('salary')
        },
        {
            text: 'Description',
            to: `/edit/${userID}/job/${companyID}/${jobID}/description`,
            active: pathname.includes('description')
        },
        {
            text: 'Calendar',
            to: `/edit/${userID}/job/${companyID}/${jobID}/interviewcalendar`,
            active: pathname.includes('interviewcalendar')
        },
        {
            text: 'Time',
            to: `/edit/${userID}/job/${companyID}/${jobID}/interviewtime`,
            active: pathname.includes('interviewtime')
        },
        {
            text: 'Complete',
            to: `/edit/${userID}/job/${companyID}/${jobID}/complete`,
            active: pathname.includes('complete')
        }
    ];
};
