/* eslint-disable max-lines */
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useParams,
    useRouteMatch
} from 'react-router-dom';

import {
    cancelInterviews,
    completeInterview,
    getInterview
} from '../../../services/interviews';
import { FirebaseContext } from '../../context/FirebaseContext';
import { UserContext } from '../../context/UserContext';

import BackgroundCard from './background/BackgroundCard';
import ChatModalSection from './ChatModal';
import EndFeedback from './EndFeedback';
import Feedback from './Feedback';
import useSessionInterviewIDs from './hooks/firestoreQuery';
import useDailyQuery, { participantEvents } from './hooks/useDailyQuery';
import useInterviewFeedback from './hooks/useInterviewFeedback';
import useTimer from './hooks/useTimer';
import Lobby from './Lobby';
import StarSection from './starsection/StarSection';
import ChatScreen from './videochat/ChatScreen';

import type { Interview as TypeInterview } from '../../../types/interview';

const Root = styled('div')`
    flex-grow: 1;
    display: flex;
    width: 100%;
    gap: 1.5rem;
`;

export default function Interview() {
    const { firestore } = useContext(FirebaseContext);
    const { user } = useContext(UserContext);
    const { interviewID } = useParams<{ interviewID: string }>();
    const sessionInterviewIDs = useSessionInterviewIDs(
        firestore,
        parseInt(interviewID, 10)
    );

    const [interviewDetails, setInterviewDetails] = useState<TypeInterview>();
    const [modal, setModal] = useState<'single' | 'all' | ''>('');
    const [showSidebar, setShowSidebar] = useState(false);
    const [redirect, setRedirect] = useState('');

    const { path } = useRouteMatch();
    const pathName = useLocation().pathname;

    const { call } = useDailyQuery(
        user.levylRole === 'candidate'
            ? interviewDetails?.participant?.roomID ?? ''
            : user.roomID,
        user.levylRole === 'candidate'
            ? interviewDetails?.candidateMeetingToken ?? ''
            : user.meetingToken,
        !pathName.endsWith('feedback')
    );

    const { feedbackValues, updateFeedbackValues, submitFeedback, content } =
        useInterviewFeedback(interviewDetails);

    useEffect(() => {
        const closeCall = async () => {
            await call?.leave();
        };
        return () => {
            closeCall();
        };
    }, [call]);

    useEffect(() => {
        getInterview(parseInt(interviewID, 10), user.id).then(
            (interview: TypeInterview) => {
                setInterviewDetails(interview);
            }
        );
    }, [user.id, interviewID]);

    const endInterview = (seconds: number, minutes: number) => {
        const interviewDuration =
            (interviewDetails?.duration ?? 0) * 60 - seconds - minutes * 60;

        return completeInterview(parseInt(interviewID, 10), interviewDuration);
    };

    const getInterviewIndex = (
        interviewList: { id: number; candidatePresent: boolean }[]
    ) =>
        interviewList.findIndex(
            (interview) => interview.id === parseInt(interviewID, 10)
        );

    const getNextInterviewID = () => {
        const currentInterviewIndex = getInterviewIndex(sessionInterviewIDs);
        if (currentInterviewIndex === -1) return '';
        if (currentInterviewIndex === sessionInterviewIDs.length - 1) return '';
        for (
            let i = currentInterviewIndex + 1;
            i < sessionInterviewIDs.length;
            i += 1
        ) {
            if (sessionInterviewIDs[i].status === 'scheduled') {
                return sessionInterviewIDs[i].id;
            }
        }
        return '';
    };

    const getInterviewIDsAfterCurrent = () => {
        const currentInterviewIndex = getInterviewIndex(sessionInterviewIDs);
        if (currentInterviewIndex === -1) return [];
        if (currentInterviewIndex === sessionInterviewIDs.length - 1) return [];
        return sessionInterviewIDs
            .slice(currentInterviewIndex + 1)
            .map((interview) => interview.id);
    };

    const toggleSidebar = () => {
        if (showSidebar && user.levylRole !== 'candidate') {
            submitFeedback(feedbackValues).then(() => {
                setShowSidebar(!showSidebar);
            });
        } else {
            setShowSidebar(!showSidebar);
        }
    };

    const endInterviewAndRedirect = (seconds: number, minutes: number) =>
        endInterview(seconds, minutes).then(() => {
            submitFeedback(feedbackValues).then(() => {
                setModal('');
                call?.leave();
                if (user.levylRole !== 'candidate') {
                    setRedirect(
                        `/secure/${user.userID}/interview/${interviewID}/feedback`
                    );
                } else {
                    setRedirect(
                        `/secure/${user.userID}/${user.levylRole}/dashboard`
                    );
                }
            });
        });

    const { seconds, minutes } = useTimer(
        moment.utc(interviewDetails?.startTime).unix(),
        () => {
            if (!pathName.endsWith('feedback')) {
                endInterviewAndRedirect(seconds, minutes);
            }
        }
    );

    const endAllInterviews = () => {
        const interviewIDs = getInterviewIDsAfterCurrent();
        return endInterview(seconds, minutes).then(
            cancelInterviews(interviewIDs).then(() => {
                setRedirect(
                    `/secure/${user.userID}/interview/${interviewID}/endfeedback`
                );
            })
        );
    };

    const submitPageFeedback = () =>
        submitFeedback(feedbackValues).then(() => {
            const nextInterviewID = getNextInterviewID();
            if (nextInterviewID) {
                setRedirect(
                    `/secure/${user.userID}/interview/${nextInterviewID}/lobby`
                );
            } else {
                setRedirect(
                    `/secure/${user.userID}/interview/${interviewID}/endfeedback`
                );
            }
        });

    return (
        <>
            <BackgroundCard
                lineUp={
                    !pathName.endsWith('lobby')
                        ? sessionInterviewIDs
                        : undefined
                }
                endAllInterview={() => {
                    setModal('all');
                }}
                isCandidate={user.levylRole === 'candidate'}
                interviewIndex={getInterviewIndex(sessionInterviewIDs)}
            >
                <Switch>
                    <Route path={`${path}/lobby`}>
                        <Lobby
                            role={interviewDetails?.job.title ?? ''}
                            company={interviewDetails?.company.name ?? ''}
                            interviewerName={`${interviewDetails?.participant?.name.firstName} ${interviewDetails?.participant?.name.lastName}`}
                            unixStartTime={moment
                                .utc(interviewDetails?.startTime)
                                .unix()}
                            available={
                                sessionInterviewIDs.filter(
                                    (interview) => interview.candidatePresent
                                ).length
                            }
                            unavailable={
                                sessionInterviewIDs.filter(
                                    (interview) => !interview.candidatePresent
                                ).length
                            }
                            isCandidate={user.levylRole === 'candidate'}
                        >
                            <ChatScreen
                                callerID={
                                    interviewDetails?.participant?.userID ?? ''
                                }
                                call={call}
                                endSingleInterview={() => setModal('single')}
                                preview
                                callerName={
                                    interviewDetails?.participant?.name
                                        .firstName ?? ''
                                }
                                callerProfilePicture={
                                    interviewDetails?.participant?.name
                                        .imageURL ?? ''
                                }
                                participantEvents={participantEvents}
                                toggleSidebar={toggleSidebar}
                            />
                        </Lobby>
                    </Route>
                    <Route path={`${path}/interview`}>
                        <Root>
                            <ChatScreen
                                callerID={
                                    interviewDetails?.participant?.userID ?? ''
                                }
                                call={call}
                                unixStartTime={
                                    moment
                                        .utc(interviewDetails?.startTime)
                                        .unix() +
                                    (interviewDetails?.duration ?? 0) * 60
                                }
                                endSingleInterview={() => setModal('single')}
                                callerName={
                                    interviewDetails?.participant?.name
                                        .firstName ?? ''
                                }
                                callerProfilePicture={
                                    interviewDetails?.participant?.name
                                        .imageURL ?? ''
                                }
                                participantEvents={participantEvents}
                                toggleSidebar={toggleSidebar}
                            />
                            {showSidebar && (
                                <StarSection
                                    interview={
                                        interviewDetails as TypeInterview
                                    }
                                    isCandidate={user.levylRole === 'candidate'}
                                    chatURL={
                                        interviewDetails?.messageChatURL ?? ''
                                    }
                                    feedbackTitle={content.feedback}
                                    questions={content.questions}
                                    onSubmit={submitFeedback}
                                    values={feedbackValues}
                                    setValues={updateFeedbackValues}
                                />
                            )}
                        </Root>
                    </Route>
                    <Route path={`${path}/feedback`}>
                        <Feedback
                            name={
                                interviewDetails?.participant?.name.firstName ??
                                ''
                            }
                            feedbackValues={feedbackValues}
                            setFeedbackValues={updateFeedbackValues}
                            submitFeedback={submitPageFeedback}
                            unixStartTime={moment
                                .utc(interviewDetails?.startTime)
                                .add(8, 'minutes')
                                .unix()}
                        />
                    </Route>
                    <Route path={`${path}/endfeedback`}>
                        <EndFeedback
                            interviewIDs={sessionInterviewIDs}
                            user={user}
                        />
                    </Route>
                </Switch>
            </BackgroundCard>
            <ChatModalSection
                uid={user.userID}
                modal={modal}
                setModal={setModal}
                onClick={
                    modal === 'all'
                        ? endAllInterviews
                        : () => endInterviewAndRedirect(seconds, minutes)
                }
            />
            {redirect && <Redirect to={redirect} />}
        </>
    );
}
