// todo add storybook stories

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface CheckboxProps {
    id: string;
    label: string;
    color: 'primary' | 'secondary';
    error?: boolean;
    helperText?: string;
    errorText?: string;
    title?: string;
    control: Control;
}

export default function Checkbox(props: CheckboxProps) {
    const { id, label, color, error, helperText, control, title, ...other } =
        props;
    let { errorText } = props;
    if (!errorText) {
        errorText = '';
    }
    return (
        <Controller
            name={id}
            control={control}
            render={({ field: { onChange, value } }) => (
                <FormControl
                    error={error}
                    component='fieldset'
                    variant='standard'
                    id={id}
                    color={color}
                >
                    <FormLabel component='legend'>{title}</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <MaterialCheckbox
                                    sx={{ color: 'secondary.main' }}
                                />
                            }
                            label={label}
                            value={value}
                            onChange={onChange}
                            {...other}
                        />
                    </FormGroup>
                    <FormHelperText>
                        {error ? errorText : helperText}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
}
