export const format = (
    money: number,
    locale = 'en-CA',
    currency = 'CAD',
    minimumFractionDigits = 2
) =>
    new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits
    }).format(money);

const exports = {
    format
};

export default exports;
