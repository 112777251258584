import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import Button from '../global/components/Button/Button';

const StyledBackground = styled('div')(({ theme }) => ({
    background: 'linear-gradient(180deg, #f7f7ff 47.69%, #f9f9f9 100%)',
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem 8rem',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap-reverse'
    }
}));

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: `${(6 / 12) * 100}%`,

    [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: theme.spacing(64)
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(32)
    }
}));

const StyledContent = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: `${(6 / 12) * 100}%`,
    paddingRight: theme.spacing(13.625),
    margin: '0',
    [theme.breakpoints.down('lg')]: {
        paddingRight: theme.spacing(6)
    },
    [theme.breakpoints.down('md')]: {
        paddingRight: '0',
        paddingTop: theme.spacing(6),
        flexBasis: '100%',
        maxWidth: theme.spacing(76)
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(32)
    }
}));

export interface IntroProps {
    image: { file: { url: string } };
    heading: string;
    content: string;
    link: { link: string; content: string; id: string };
}

export default function IntroSection(props: IntroProps) {
    return (
        <StyledBackground>
            <StyledContainer disableGutters>
                <StyledContent disableGutters>
                    <Typography variant='h1' color='text.secondary' mb={4}>
                        {props.heading}
                    </Typography>
                    <Typography color='text.primary' variant='body2' mb={4}>
                        {props.content}
                    </Typography>
                    <div>
                        <Button
                            link={'#waitlist'}
                            variant='contained'
                            id={props.link.id}
                        >
                            {props.link.content}
                        </Button>
                    </div>
                </StyledContent>
                <StyledImage alt='' src={props.image.file.url} />
            </StyledContainer>
        </StyledBackground>
    );
}
