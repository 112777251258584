import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../global/components/Button/Button';
import RadioButton from '../../global/components/RadioButton';

import useTimer from './hooks/useTimer';
import ChatTimer from './videochat/ChatTimer';

import type { Control, FieldValues } from 'react-hook-form';

const Container = styled('div')`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const FeedbackFormContainer = styled('form')`
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    max-width: 42rem;
`;

const StyledRadioButton = styled(RadioButton)`
    width: 100%;
`;

const StyledSubmitButton = styled(Button)`
    margin-top: 1rem;
`;

const StyledTimer = styled('div')(
    ({ theme }) => `
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.5rem;
    background: ${theme.palette.secondary.light};
    border-radius: 1rem;
    margin: 1rem;
`
);

const formSchema = yup
    .object()
    .shape({
        strongCurrentCandidate: yup
            .string()
            .oneOf(['1', '2', '3', '4', '5'])
            .required(),
        strongFutureCandidate: yup
            .string()
            .oneOf(['1', '2', '3', '4', '5'])
            .required()
    })
    .required();

export default function Feedback({
    name,
    feedbackValues,
    setFeedbackValues,
    submitFeedback,
    unixStartTime
}: {
    name: string;
    feedbackValues: {
        strongCurrentCandidate: string;
        strongFutureCandidate: string;
    };
    setFeedbackValues: (data: {
        strongCurrentCandidate: string;
        strongFutureCandidate: string;
    }) => void;
    submitFeedback: () => Promise<void>;
    unixStartTime: number;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: feedbackValues
    });

    const { seconds, minutes } = useTimer(unixStartTime);

    const currentCandidateValue = watch('strongCurrentCandidate');
    const futureCandidateValue = watch('strongFutureCandidate');

    useEffect(() => {
        setFeedbackValues({
            strongCurrentCandidate: currentCandidateValue,
            strongFutureCandidate: futureCandidateValue
        });
    }, [setFeedbackValues, currentCandidateValue, futureCandidateValue]);

    return (
        <>
            <Container>
                <StyledTimer>
                    <ChatTimer minutes={minutes} seconds={seconds} />
                </StyledTimer>
                <Typography variant={'h2'} color={'secondary'}>
                    {`Feedback for ${name}`}
                </Typography>
                <FeedbackFormContainer onSubmit={handleSubmit(submitFeedback)}>
                    <StyledRadioButton
                        id='strongCurrentCandidate'
                        title={`${name} is a strong candidate for the role I am hiring for.`}
                        color='primary'
                        error={!!errors.strongCurrentCandidate}
                        errorText={errors.strongCurrentCandidate?.message}
                        control={control as unknown as Control<FieldValues>}
                        labels={[
                            'Strongly disagree',
                            'Disagree',
                            'Neutral',
                            'Agree',
                            'Strongly agree'
                        ].map((label, index) => ({
                            label,
                            value: `${index + 1}`
                        }))}
                        row
                        labelTop
                    />
                    <StyledRadioButton
                        id='strongFutureCandidate'
                        title={`${name} could be a strong candidate for roles in the future.`}
                        color='primary'
                        error={!!errors.strongFutureCandidate}
                        errorText={errors.strongFutureCandidate?.message}
                        control={control as unknown as Control<FieldValues>}
                        labels={[
                            'Strongly disagree',
                            'Disagree',
                            'Neutral',
                            'Agree',
                            'Strongly agree'
                        ].map((label, index) => ({
                            label,
                            value: `${index + 1}`
                        }))}
                        row
                        labelTop
                    />
                    <StyledSubmitButton variant='contained' submit>
                        {'Submit'}
                    </StyledSubmitButton>
                </FeedbackFormContainer>
            </Container>
        </>
    );
}
