import { yupResolver } from '@hookform/resolvers/yup';
import MaterialModal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../global/components/Button/Button';
import RadioButton from '../../global/components/RadioButton';
import TextField from '../../global/components/TextField/TextField';

import useInterviewFeedback from './hooks/useInterviewFeedback';
import StarRating from './starsection/StarRating';

import type { Interview } from '../../../types/interview';
import type { Control, FieldValues } from 'react-hook-form';

const modalContent = { modalClose: 'Cancel' };

const StyledWrapper = styled('form')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    gap: 1rem;
`;

const StyledModal = styled(MaterialModal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
    max-height: 90%;
    overflow-y: auto;
`;

const StyledModalAction = styled('div')`
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
    justify-content: flex-end;
    width: 100%;
`;

const ModalButton = styled(Button)`
    flex: 1;
`;

export const CompanyPicture = styled('div')<{ src: string }>`
    background-color: #000000;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-image: url(${(props) => props.src});
    display: flex;
    justify-content: flex-end;
`;

const StarRow = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex-shrink: 1;
    gap: 1.5rem;
`;

const StyledRadioButton = styled(RadioButton)`
    width: 100%;
`;

const formSchema = yup.object().shape({
    generalFeedback: yup.string(),
    questions: yup.array().of(
        yup.object().shape({
            question: yup.string(),
            rating: yup.string()
        })
    ),
    strongCurrentCandidate: yup
        .string()
        .oneOf(['1', '2', '3', '4', '5'])
        .required(),
    strongFutureCandidate: yup
        .string()
        .oneOf(['1', '2', '3', '4', '5'])
        .required(),
    userQuestions: yup.array().of(
        yup.object().shape({
            question: yup.string(),
            response: yup.string()
        })
    )
});

const getCompletionPercent = (feedbackValues: {
    questions: { question: string; rating: string }[];
    generalFeedback: string;
    strongCurrentCandidate: string;
    strongFutureCandidate: string;
    userQuestions: { question: string; response: string }[];
}) => {
    let totalQuestions = feedbackValues.questions.map(
        (question) => question.rating
    );
    totalQuestions = totalQuestions.concat(
        feedbackValues.userQuestions.map((question) => question.response)
    );
    totalQuestions = totalQuestions.concat([
        feedbackValues.generalFeedback,
        feedbackValues.strongCurrentCandidate,
        feedbackValues.strongFutureCandidate
    ]);
    const completedQuestions = totalQuestions.filter(
        (question) => !['0', ''].includes(question)
    ).length;
    return Math.round((completedQuestions / totalQuestions.length) * 100);
};

export default function FeedbackModal(props: {
    open: boolean;
    onClose: () => void;
    interview: Interview;
    setCompletionPercent: (percent: number) => void;
}) {
    const { setCompletionPercent } = props;
    const { feedbackValues, submitFeedback } = useInterviewFeedback(
        props.interview
    );

    const {
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: feedbackValues
    });

    useEffect(() => {
        reset(feedbackValues);
        setCompletionPercent(getCompletionPercent(feedbackValues));
    }, [feedbackValues, reset, setCompletionPercent]);

    const questionValues = watch('questions');
    const generalFeedbackValue = watch('generalFeedback');
    const currentCandidateValue = watch('strongCurrentCandidate');
    const futureCandidateValue = watch('strongFutureCandidate');
    const userQuestions = watch('userQuestions');

    const updateValue = (index: number, value: number) => {
        setValue(`questions.${index}.rating`, value.toString());
    };

    const submitOnClose = () => {
        submitFeedback({
            questions: questionValues,
            generalFeedback: generalFeedbackValue,
            strongCurrentCandidate: currentCandidateValue,
            strongFutureCandidate: futureCandidateValue,
            userQuestions
        }).then(() => {
            props.setCompletionPercent(getCompletionPercent(watch()));
            props.onClose();
        });
    };

    const submitForm = (data: {
        questions: { question: string; rating: string }[];
        generalFeedback: string;
        strongCurrentCandidate: string;
        strongFutureCandidate: string;
        userQuestions: { question: string; response: string }[];
    }) => {
        submitFeedback(data).then(() => {
            props.setCompletionPercent(getCompletionPercent(data));
            props.onClose();
        });
    };

    return (
        <StyledModal
            open={props.open}
            onClose={submitOnClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
            disableScrollLock
        >
            <StyledModalContent>
                <StyledWrapper onSubmit={handleSubmit(submitForm)}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            width: '100%'
                        }}
                    >
                        <CompanyPicture
                            src={props.interview.participant?.name.imageURL}
                        />
                        <Typography
                            id='modal-title'
                            sx={{ fontWeight: 'bold' }}
                            color={'secondary'}
                            variant={'h2'}
                        >
                            {props.interview.participant?.name.firstName}
                        </Typography>
                    </div>
                    <Typography variant={'h6'} color={'primary'}>
                        {'Internal User Rating'}
                    </Typography>
                    {questionValues.map((question, index) => (
                        <div
                            key={question.question}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center'
                            }}
                        >
                            <Typography color={'text.primary'}>
                                {question.question}
                            </Typography>
                            <StarRow sx={{ mb: 1 }}>
                                <StarRating
                                    value={parseFloat(
                                        questionValues[index].rating
                                    )}
                                    setValue={(value) => {
                                        updateValue(index, value);
                                    }}
                                />
                            </StarRow>
                        </div>
                    ))}
                    <StyledRadioButton
                        id='strongCurrentCandidate'
                        title={`${props.interview.participant?.name.firstName} is a strong candidate for the role I am hiring for.`}
                        color='primary'
                        error={!!errors.strongCurrentCandidate}
                        errorText={errors.strongCurrentCandidate?.message}
                        control={control as unknown as Control<FieldValues>}
                        labels={[
                            'Strongly disagree',
                            'Disagree',
                            'Neutral',
                            'Agree',
                            'Strongly agree'
                        ].map((label, index) => ({
                            label,
                            value: `${index + 1}`
                        }))}
                        row
                        labelTop
                    />
                    <StyledRadioButton
                        id='strongFutureCandidate'
                        title={`${props.interview.participant?.name.firstName} could be a strong candidate for roles in the future.`}
                        color='primary'
                        error={!!errors.strongFutureCandidate}
                        errorText={errors.strongFutureCandidate?.message}
                        control={control as unknown as Control<FieldValues>}
                        labels={[
                            'Strongly disagree',
                            'Disagree',
                            'Neutral',
                            'Agree',
                            'Strongly agree'
                        ].map((label, index) => ({
                            label,
                            value: `${index + 1}`
                        }))}
                        row
                        labelTop
                    />
                    <Typography variant={'h6'} color={'primary'}>
                        {'Questions from the candidate'}
                    </Typography>
                    {userQuestions.map((question, index) => (
                        <>
                            <Typography>{question.question}</Typography>
                            <TextField
                                id={`userQuestions.${index}.response`}
                                label={'Please share any thoughts...'}
                                color='primary'
                                error={!!errors.userQuestions?.[index]?.message}
                                errorText={
                                    errors.userQuestions?.[index]?.message
                                }
                                placeholder={''}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                                outlined
                                rows={3}
                                sx={{ width: '100%', mb: 2 }}
                            />
                        </>
                    ))}
                    <Typography variant={'h6'} color={'primary'}>
                        {'General Feedback'}
                    </Typography>
                    <TextField
                        id='generalFeedback'
                        label={
                            'General Feedback (will not be shared with candidate)'
                        }
                        color='primary'
                        error={!!errors.generalFeedback}
                        errorText={errors.generalFeedback?.message}
                        placeholder={''}
                        control={control as unknown as Control<FieldValues>}
                        outlined
                        rows={4}
                        sx={{ width: '100%', mb: 2 }}
                    />
                    <StyledModalAction>
                        <ModalButton variant='outlined' onClick={submitOnClose}>
                            {modalContent.modalClose}
                        </ModalButton>
                        <ModalButton
                            id='modalPrimaryCTAAction'
                            variant='contained'
                            submit
                        >
                            {'Save'}
                        </ModalButton>
                    </StyledModalAction>
                </StyledWrapper>
            </StyledModalContent>
        </StyledModal>
    );
}
