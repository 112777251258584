import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MaterialModal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import waitlistImage from '../../../../static/illustrations/waitlist-confirm.svg';

const StyledModal = styled(MaterialModal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    max-width: 50rem;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
    position: relative;
    padding-bottom: 4rem;
`;

const ModalMiddleSection = styled('div')`
    display: flex;
    gap: 2rem;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const ModalImage = styled('img')`
    @media (max-width: 600px) {
        display: none;
    }
`;

export function ModalContent(props: {
    heading: string;
    children: React.ReactNode;
    onClose: () => void;
}) {
    return (
        <StyledModalContent>
            <Typography
                id='modal-title'
                variant={'h2'}
                color={'primary'}
                sx={{ mb: 2 }}
            >
                {props.heading}
            </Typography>
            <IconButton
                aria-label='close'
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}
                size='large'
                color={'primary'}
            >
                <CloseIcon />
            </IconButton>
            <ModalMiddleSection>
                {props.children}
                <ModalImage src={waitlistImage} alt='' />
            </ModalMiddleSection>
        </StyledModalContent>
    );
}

export default function NoActionModal(props: {
    heading: string;
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
}) {
    return (
        <StyledModal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
            disableScrollLock
        >
            <ModalContent heading={props.heading} onClose={props.onClose}>
                {props.children}
            </ModalContent>
        </StyledModal>
    );
}
