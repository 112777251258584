import moment from 'moment';

export const getMinExperience = (experience?: string) => {
    switch (experience) {
        case '0-2':
            return 0;
        case '2-4':
            return 2;
        case '4-9':
            return 4;
        default:
            return 10;
    }
};

export const getMaxExperience = (experience?: string) => {
    switch (experience) {
        case '0-2':
            return 2;
        case '2-4':
            return 4;
        case '4-9':
            return 9;
        default:
            return 10;
    }
};

export const generateEndTime = (
    startTime: string | undefined,
    duration: string | undefined
) => {
    if (!startTime || !duration) {
        return '';
    }
    const endTime = moment(startTime, 'h:mm A')
        .add(Number(duration), 'minutes')
        .format('h:mm A');
    return endTime;
};

export const generateOptions = (duration: string | undefined) => {
    if (!duration) {
        return [];
    }
    const options = [];
    const startDate = new Date();
    startDate.setHours(8, 0, 0, 0);

    const endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + Number(duration));

    const maxDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        8,
        0,
        0
    );
    maxDate.setDate(maxDate.getDate() + 1);

    const increment = Number(duration);

    while (endDate <= maxDate) {
        const label = startDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });
        const value = startDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });
        options.push({ value, label });
        startDate.setMinutes(startDate.getMinutes() + increment);
        endDate.setMinutes(endDate.getMinutes() + increment);
    }

    return options;
};
