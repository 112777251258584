import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import Checkbox from '../../../global/components/Checkbox';
import RadioButton from '../../../global/components/RadioButton';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../styles';

import commonContent from './content';

import type { User } from '../../../../types/user';
import type { LevylUser, UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        location: yup.string(),
        workInCanada: yup
            .string()
            .oneOf(['yes'], commonContent.location.errors.workInCanada)
            .required(commonContent.common.errors.required),
        termsAndConditions: yup
            .bool()
            .oneOf([true], commonContent.location.errors.termsAndConditions)
            .required(commonContent.common.errors.required)
    })
    .required();

export default function LocationSlide(props: {
    profile: UserProfile['location'];
    updateProfile: (value: UserProfile['location']) => void;
    userRole: LevylUser;
    submit?: (data: Partial<User>) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            location: props.profile.location,
            workInCanada: props.profile.workInCanada ? 'yes' : 'no',
            termsAndConditions: props.profile.termsAndConditions
        }
    });

    const onSubmit = (data: {
        location: string;
        workInCanada: string;
        termsAndConditions: boolean;
    }) => {
        if (props.userRole === 'candidate') {
            props.updateProfile({
                location: data.location,
                workInCanada: data.workInCanada === 'yes',
                termsAndConditions: data.termsAndConditions
            });
        } else {
            props.submit?.({
                location: {
                    location: data.location,
                    workInCanada: data.workInCanada === 'yes',
                    termsAndConditions: data.termsAndConditions
                }
            });
        }
    };

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={props.userRole}
            onClose={props.onClose}
            id={'locationSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    {commonContent.common.stepOneSubtitle}
                </Typography>
                <TextInput
                    id='location'
                    label={commonContent.location.location.label}
                    color='primary'
                    error={!!errors.location}
                    errorText={errors.location?.message}
                    placeholder={commonContent.location.location.placeholder}
                    control={control as unknown as Control<FieldValues>}
                />
                <RadioButton
                    id='workInCanada'
                    title={commonContent.location.workInCanada.label}
                    color='primary'
                    error={!!errors.workInCanada}
                    errorText={errors.workInCanada?.message}
                    control={control as unknown as Control<FieldValues>}
                    labels={commonContent.location.workInCanada.options}
                />
                <Checkbox
                    id='termsAndConditions'
                    label={commonContent.location.termsAndConditions.label}
                    color='primary'
                    error={!!errors.termsAndConditions}
                    errorText={errors.termsAndConditions?.message}
                    control={control as unknown as Control<FieldValues>}
                />
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {commonContent.common.back}
                    </TextButton>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
