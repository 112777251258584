import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getEligibleUserCount } from '../../../services/user';
import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { UserEducation } from '../../../types/enum';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { TextButton } from '../../global/components/Button/Button';
import Select from '../../global/components/SelectField';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import { FormSection, NextSteps, StyledButton } from '../Profile/styles';

import content from './content';
import { getMaxExperience, getMinExperience } from './CreateJob.utils';

import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    workExperience: yup.string().oneOf(['0-2', '2-4', '4-9', '10']),
    educationType: yup
        .string()
        .oneOf([
            'none',
            'high_school_diploma',
            'college_diploma',
            'bachelors_degree',
            'masters_degree',
            'doctorate_degree'
        ])
});

export default function EducationSlide(props: {
    job: SimpleJob;
    setJob: (job: SimpleJob) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    prevURL: string;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            workExperience:
                props.job.minExperience && props.job.maxExperience
                    ? `${props.job.minExperience}-${props.job.maxExperience}`
                    : '',
            educationType: props.job.educationType
        }
    });

    const onSubmit = (data: {
        workExperience: string;
        educationType: UserEducation;
    }) => {
        props.setJob({
            ...props.job,
            minExperience: getMinExperience(data.workExperience),
            maxExperience: getMaxExperience(data.workExperience),
            educationType: data.educationType
        });
    };

    const { query, setQuery, setCandidateCount } = props;

    useEffect(() => {
        const subscription = watch((value) => {
            const { workExperience, educationType } = value;
            setQuery({
                ...query,
                min_experience: getMinExperience(workExperience),
                max_experience: getMaxExperience(workExperience),
                min_education_level: educationType
            });
            if (workExperience !== '') {
                getEligibleUserCount({
                    ...query,
                    min_experience: getMinExperience(workExperience),
                    max_experience: getMaxExperience(workExperience)
                }).then((count: number) => {
                    setCandidateCount(count);
                });
            }
            if (educationType) {
                getEligibleUserCount({
                    ...query,
                    min_education_level: educationType
                }).then((count: number) => {
                    setCandidateCount(count);
                });
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, query, setQuery, setCandidateCount]);

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Select
                    id='workExperience'
                    label={content.education.workExperience.label}
                    color='primary'
                    error={!!errors.workExperience}
                    errorText={content.common.errors.required}
                    control={control as unknown as Control<FieldValues>}
                    options={content.education.workExperience.options}
                />
                <Select
                    id='educationType'
                    label={content.education.educationLevel.label}
                    color='primary'
                    error={!!errors.educationType}
                    errorText={content.common.errors.required}
                    control={control as unknown as Control<FieldValues>}
                    options={content.education.educationLevel.options}
                />
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {content.common.back}
                    </TextButton>
                    <StyledButton variant='outlined' sx={{ m: 2 }} submit>
                        {content.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
