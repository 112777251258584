import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { CircularProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getCompanyJobs } from '../../../../services/jobs';
import Button from '../../../global/components/Button/Button';

import EmployerJobCard from './EmployerJobCard';

import type { Company } from '../../../../types/company';
import type { Job } from '../../../../types/job';
import type { User } from '../../../../types/user';

const CompanyHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1.5rem;
        margin-bottom: 1.5rem;
    }
`;

const NoPlanCard = ({ user, company }: { user: User; company: Company }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column'
        }}
    >
        <Typography variant={'h2'} sx={{ mt: 2 }}>
            {'Thank you for signing up!'}
        </Typography>
        <Typography
            variant='body2'
            style={{
                display: 'flex',
                marginTop: '0.875rem',
                marginBottom: '1.5rem'
            }}
        >
            {
                'In order to post a job for this company, please sign up for a plan:'
            }
        </Typography>
        <div style={{ marginRight: 'auto' }}>
            <Button
                variant='outlined'
                to={`/edit/${user.userID}/business/${company.id}/plans`}
            >
                {'Find a plan'}
            </Button>
        </div>
    </div>
);

const LoadingWheel = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center'
        }}
    >
        <CircularProgress />
    </div>
);

export default function EmployerJobList({
    user,
    company
}: {
    user: User;
    company: Company;
}) {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (company.id) {
            setIsLoading(true);
            getCompanyJobs(company.id)
                .then((response: Job[]) => {
                    setJobs(response);
                    setIsLoading(false);
                })
                .catch((err: any) => {
                    // TODO:
                    // eslint-disable-next-line no-console
                    console.log(err);
                    setIsLoading(false);
                });
        }
    }, [company.id]);

    if (isLoading) return <LoadingWheel />;

    return (
        <>
            <CompanyHeader>
                <div key={company.id}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                        }}
                    >
                        <Typography variant={'h2'} color={'secondary'}>
                            {`${company.name} Jobs`}
                        </Typography>
                        <Link
                            to={`/secure/${user.userID}/statistics/${company.id}`}
                        >
                            <IconButton color={'primary'}>
                                <BarChartIcon />
                            </IconButton>
                        </Link>
                        <Link
                            to={`/edit/${user.userID}/business/${company.id}/name`}
                        >
                            <IconButton color={'primary'}>
                                <SettingsIcon />
                            </IconButton>
                        </Link>
                    </div>
                    {company.active && (
                        <Typography
                            variant='body2'
                            style={{
                                display: 'flex',
                                marginTop: '0.875rem'
                            }}
                        >
                            {'You have '}
                            <Typography
                                style={{
                                    marginLeft: '0.25rem',
                                    marginRight: '0.25rem'
                                }}
                                color='primary'
                                variant='body2'
                                component='span'
                            >
                                <b>{jobs.length}</b>
                            </Typography>
                            {'active job postings.'}
                        </Typography>
                    )}
                </div>
                {company.active && (
                    <div>
                        <Button
                            to={`/create/${user.userID}/job/${company.id}/intro`}
                            variant='contained'
                        >
                            {'Post a job'}
                        </Button>
                    </div>
                )}
            </CompanyHeader>
            {company.active ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                    }}
                >
                    {jobs.map((job, index) => (
                        <EmployerJobCard
                            key={index}
                            job={job}
                            location={company.location}
                            userID={user.userID}
                        />
                    ))}
                    {jobs.length === 0 && (
                        <>
                            <Typography
                                variant='h2'
                                color={'secondary'}
                                sx={{ mt: 2 }}
                            >
                                {
                                    "You don't have any jobs postings yet. Create your first role!"
                                }
                            </Typography>
                        </>
                    )}
                </div>
            ) : (
                <NoPlanCard user={user} company={company} />
            )}
        </>
    );
}
