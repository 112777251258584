import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/compat/app';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { checkWaitlist } from '../../../services/user';
import {
    type FirebaseContextType,
    withFirebaseContext
} from '../../context/FirebaseContext';
import WaitlistModal from '../../public/WaitlistModal';

import content from './content.json';
import LoginDisplay from './LoginDisplay';
import { createUser, logInUser, resetPassword } from './userManagement';

export type WaitlistType =
    | 'joinedWaitlist'
    | 'onWaitlist'
    | 'alreadyAccepted'
    | 'doesNotExist';

const submitForm = (
    auth: firebase.auth.Auth | null,
    submitType: 'login' | 'signup' | 'resetpassword',
    fieldValues: { email: string; password?: string }
) => {
    if (fieldValues.password) {
        if (submitType === 'signup') {
            const { email, password } = fieldValues;
            return createUser(auth, email, password);
        }
        if (submitType === 'login') {
            return logInUser(auth, fieldValues.email, fieldValues.password);
        }
    }
    return resetPassword(auth, fieldValues.email);
};

const formSchema = yup
    .object()
    .shape({
        email: yup
            .string()
            .email(content.errors.email)
            .required(content.errors.required),
        password: yup
            .string()
            .matches(
                /^$|^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                content.errors.password
            )
            .when('primaryAction', {
                is: 'resetpassword',
                then: (schema) => schema,
                otherwise: (schema) => schema.required(content.errors.required)
            }),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), ''], content.errors.confirmPassword)
            .when('primaryAction', {
                is: 'signup',
                then: (schema) => schema.required(content.errors.required)
            }),
        primaryAction: yup
            .string()
            .oneOf(['login', 'signup', 'resetpassword'])
            .required()
    })
    .required();

export function Login({
    firebaseContext
}: {
    firebaseContext: FirebaseContextType;
}) {
    const [open, setOpen] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const { auth } = firebaseContext;
    const history = useHistory();
    const { pathname } = useLocation();
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        setError,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            primaryAction: pathname !== '/' ? pathname.split('/')[1] : 'login',
            email: '',
            password: '',
            confirmPassword: ''
        }
    });

    register('primaryAction');
    const primaryAction = watch('primaryAction') as
        | 'login'
        | 'signup'
        | 'resetpassword';
    const passwordValue = watch('password');

    const onSubmit = (data: { email: string; password?: string }) => {
        checkWaitlist(data.email).then((response) => {
            if (response === 'waitlist') {
                setOpen('onWaitlist');
            } else if (response === 'not_found') {
                setOpen('doesNotExist');
            } else if (response === 'accepted') {
                submitForm(auth, primaryAction, data).then((res) => {
                    if (res.code !== 'success' && 'message' in res) {
                        setError('email', { message: res.message });
                    } else if (res.type === 'resetPassword') {
                        setModalOpen(true);
                    }
                });
            }
        });
    };

    useEffect(() => {
        const path = pathname.split('/')[1];
        setValue('primaryAction', pathname !== '/' ? path : 'login');
    }, [pathname, setValue]);

    useEffect(() => {
        if (primaryAction !== 'signup') {
            history.push(`/${primaryAction}`);
        }
    }, [primaryAction, history]);

    return (
        <>
            <LoginDisplay
                auth={auth}
                content={content}
                primaryAction={primaryAction}
                control={control}
                errors={errors}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setValue={setValue}
                onSubmit={handleSubmit(onSubmit)}
                passwordValue={passwordValue}
            />
            {open !== '' && (
                <WaitlistModal
                    open={open !== ''}
                    onClose={() => setOpen('')}
                    waitlistType={open as WaitlistType}
                />
            )}
        </>
    );
}

export default withFirebaseContext(Login);
