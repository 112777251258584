import type { UserEducation, WorkingPreference } from '../../types/enum';

export const getWorkingPreference = (preference: WorkingPreference): string => {
    switch (preference) {
        case 'no_preference':
            return 'No Preference';
        case 'office':
            return 'Office';
        case 'remote':
            return 'Remote';
        case 'hybrid':
            return 'Hybrid';
        default:
            return '';
    }
};

export const getUserEducation = (education: UserEducation): string => {
    switch (education) {
        case 'none':
            return 'None';
        case 'high_school_diploma':
            return 'High School Diploma';
        case 'college_diploma':
            return 'College Diploma';
        case 'bachelors_degree':
            return "Bachelor's Degree";
        case 'masters_degree':
            return "Master's Degree";
        case 'doctorate_degree':
            return 'Doctorate Degree';
        default:
            return '';
    }
};
