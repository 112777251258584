import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import React, { ComponentType, createContext, useEffect } from 'react';

import { projectID } from '../constants';

import type { ReactNode } from 'react';

const firebaseConfig = {
    apiKey: 'AIzaSyAkDBEMTdFu6hd3S9AmZzoHjEF6YyeLjtM',
    authDomain: 'levyl.app',
    projectId: projectID,
    storageBucket: 'levyl-11cd1.appspot.com',
    messagingSenderId: '892286571541',
    appId: '1:892286571541:web:8a0420416cd089ee3caa1c',
    measurementId: 'G-9B648E267X'
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export type FirebaseContextType = {
    auth: firebase.auth.Auth | null;
    firestore: firebase.firestore.Firestore | null;
    storage: firebase.storage.Storage | null;
};

export const FirebaseContext = createContext<FirebaseContextType>({
    auth: null,
    firestore: null,
    storage: null
});

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
    const [auth, setAuth] = React.useState<firebase.auth.Auth | null>(null);
    const [firestore, setFirestore] =
        React.useState<firebase.firestore.Firestore | null>(null);
    const [storage, setStorage] =
        React.useState<firebase.storage.Storage | null>(null);

    useEffect(() => {
        setAuth(firebaseApp.auth());
        setFirestore(firebaseApp.firestore());
        setStorage(firebaseApp.storage());
    }, []);

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            auth?.useEmulator('http://localhost:9099');
        }

        if (window.location.hostname === 'localhost') {
            storage?.useEmulator('localhost', 9199);
        }

        if (window.location.hostname === 'localhost') {
            firestore?.useEmulator('localhost', 8080);
        }
    }, [auth, firestore, storage]);

    return (
        <FirebaseContext.Provider value={{ auth, firestore, storage }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export const withFirebaseContext = <P extends object>(
    Component: ComponentType<P & { firebaseContext: FirebaseContextType }>
) => {
    const parent = (props: any) => (
        <FirebaseContext.Consumer>
            {(firebaseContext) => (
                <Component {...props} firebaseContext={firebaseContext} />
            )}
        </FirebaseContext.Consumer>
    );
    const componentName =
        Component.displayName || Component.name || 'Component';
    parent.displayName = `withFirebaseContext(${componentName})`;
    return parent;
};
