import Typography from '@mui/material/Typography';
import React from 'react';

import candidateCountImg from '../../../static/illustrations/candidate-count.svg';
import { CreateInterviewDetails } from '../../../types/interview';
import { TextButton } from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import Calendar from '../BookInterview/Calendar';
import { SelectionSection } from '../BookInterview/ScheduleInterview';
import { DateYMDString } from '../BookInterview/types';
import { NextSteps, StyledButton } from '../Profile/styles';

import content from './content';

export default function CalendarSlide(props: {
    date: DateYMDString;
    setDate: (date: DateYMDString) => void;
    interview: CreateInterviewDetails;
    setInterview: (interview: CreateInterviewDetails) => void;
    candidateCount: number;
    nextURL: string;
    prevURL: string;
}) {
    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'job'}
            image={{
                src: candidateCountImg,
                alt: 'total candidates',
                overlayText: {
                    title: String(props.candidateCount),
                    body: 'Candidate matches'
                }
            }}
        >
            <Typography>{content.calendar.title}</Typography>
            <Typography>{content.calendar.body}</Typography>
            <SelectionSection>
                <Calendar
                    date={props.date}
                    setDate={props.setDate}
                    availableDates={[]}
                />
            </SelectionSection>
            <NextSteps>
                <TextButton to={props.prevURL}>
                    {content.common.back}
                </TextButton>
                <StyledButton
                    variant='outlined'
                    sx={{ m: 2 }}
                    to={props.nextURL}
                >
                    {content.common.submit}
                </StyledButton>
            </NextSteps>
        </ColumnCard>
    );
}
