/* eslint-disable react/jsx-no-literals */

import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';

import logo from '../../../static/logos/name.svg';
import FooterSection from '../FooterSection';
import NavBarSection from '../NavBarSection';

const StyledBackground = styled('div')(({ theme }) => ({
    background: 'linear-gradient(180deg, #f7f7ff 47.69%, #f9f9f9 100%)',
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem 8rem',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const StyledContainer = styled(Container)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0'
}));

export interface ContainerSectionProps {
    heading: string;
}

const ContainerSection = (props: ContainerSectionProps) => (
    <StyledBackground>
        <StyledContainer disableGutters>
            <Typography
                variant='h1'
                color='text.secondary'
                mb={4}
                sx={{ textAlign: 'center' }}
            >
                {props.heading}
            </Typography>
            <Typography color='text.primary' variant='body2' mb={4}>
                <p>
                    <strong>
                        <span>Privacy Policy</span>
                    </strong>
                </p>
                <p>
                    <span>Last modified: June 27, 2022</span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>INTRODUCTION</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        Levyl Inc. and our affiliates and subsidiaries (
                    </span>
                    <strong>
                        <span>&quot;Company&quot;</span>
                    </strong>
                    <span>&nbsp;or</span>
                    <strong>
                        <span>&nbsp;&quot;We&quot;</span>
                    </strong>
                    <span>
                        ) respect your privacy and are committed to protecting
                        it by complying with this policy.
                    </span>
                </p>
                <p>
                    <span>This policy describes:</span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                How we collect, use, disclose, and protect the
                                personal information of our customers and
                                website users (&quot;
                            </span>
                            <strong>
                                <span>you</span>
                            </strong>
                            <span>&quot;).</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Describes the types of information we may
                                collect from you or that you may provide when
                                you visit the website www.levyl.app (our &quot;
                            </span>
                            <strong>
                                <span>Website</span>
                            </strong>
                            <span>&quot;).</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Our practices for collecting, using,
                                maintaining, protecting, and disclosing that
                                information.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        We will only use your personal information in accordance
                        with this policy unless otherwise required by applicable
                        law. We take steps to ensure that the personal
                        information that we collect about you is adequate,
                        relevant, not excessive, and used for limited purposes.
                    </span>
                </p>
                <p>
                    <span>
                        Privacy laws in Canada generally define &quot;personal
                        information&quot; as any information about an
                        identifiable individual, which includes information that
                        can be used on its own or with other information to
                        identify, contact, or locate a single person. Personal
                        information does not include business contact
                        information, including your name, title, or business
                        contact information.
                    </span>
                </p>
                <p>
                    <span>
                        This policy applies to information we collect, use, or
                        disclose about you:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>On this Website.</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                In email, text, and other electronic messages
                                between you and this Website.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Through mobile and desktop applications you
                                download from this Website, which provide
                                dedicated non-browser-based interaction between
                                you and this Website.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                When you interact with our advertising and
                                applications on third-party websites and
                                services if those applications or advertising
                                include links to this policy.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        The Website may include links to third-party websites,
                        plug-ins, services, social networks, or applications.
                        Clicking on those links or enabling those connections
                        may allow the third party to collect or share data about
                        you. If you follow a link to a third-party website or
                        engage a third-party plugin, please note that these
                        third parties have their own privacy policies and we do
                        not accept any responsibility or liability for these
                        policies. We do not control these third-party websites,
                        and we encourage you to read the privacy policy of every
                        website you visit.
                    </span>
                </p>
                <p>
                    <span>This policy DOES NOT apply to information that:</span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                We collect offline or through any other means,
                                including on any other Company or third-party
                                website (including our affiliates and
                                subsidiaries).
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                You provide to or is collected by any third
                                party (including our affiliates and
                                subsidiaries), through any application or
                                content (including advertising) that may link to
                                or be accessible from or on the Website.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        Please read this policy carefully to understand our
                        policies and practices for collecting, processing, and
                        storing your information. If you do not agree with our
                        policies and practices, your choice is not to use our
                        Website. By accessing or using this Website, you
                        indicate that you understand, accept, and consent to the
                        practices described in this policy. This policy may
                        change from time to time (see&nbsp;
                    </span>
                    <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.44sinio'>
                        <span>Changes to Our Privacy Policy</span>
                    </a>
                    <span>
                        ). Your continued use of this Website after we make
                        changes indicates that you accept and consent to those
                        changes, so please check the policy periodically for
                        updates. We will notify you in advance of any material
                        changes to this policy and obtain your consent to any
                        new ways that we collect, use, and disclose your
                        personal information.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>INFORMATION WE COLLECT ABOUT YOU</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We collect and use several types of information from and
                        about you, including:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>
                                <span>Personal information</span>
                            </strong>
                            <span>
                                , that we can reasonably use to directly or
                                indirectly identify you, such as your name,
                                mailing address, e-mail address, telephone
                                number, Internet protocol (IP) address used to
                                connect your computer to the Internet, user name
                                or other similar identifier, billing and account
                                information, social insurance number, and any
                                other identifier we may use to contact you
                                (&quot;
                            </span>
                            <strong>
                                <span>personal information</span>
                            </strong>
                            <span>&quot;).&nbsp;</span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        We provide an opportunity for any user to unsubscribe
                        from our platform or opt-out of contact for marketing
                        purposes on an ongoing basis by accessing our Website,
                        using the unsubscribe mechanism at the bottom of our
                        e-mails, or e-mailing to contact@levyl.app.
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>
                                <span>Non-personal information</span>
                            </strong>
                            <span>
                                , that does not directly or indirectly reveal
                                your identity or directly relate to an
                                identifiable individual, such as, demographic
                                information, or statistical or aggregated
                                information. Statistical or aggregated data does
                                not directly identify a specific person, but we
                                may derive non-personal statistical or
                                aggregated data from personal information. For
                                example, we may aggregate personal information
                                to calculate the percentage of users accessing a
                                specific Website feature.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>Technical information</span>
                            </strong>
                            <span>
                                , including your login information, browser type
                                and version, time zone setting, browser plug-in
                                types and versions, operating system and
                                platform, or information about your internet
                                connection, the equipment you use to access our
                                Website, and usage details.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>
                                    Non-personal details about your Website
                                    interactions
                                </span>
                            </strong>
                            <span>
                                , including the full Uniform Resource Locators
                                (URLs), clickstream to, through and from our
                                Website (including date and time), products you
                                viewed or searched for, page response times,
                                download errors, length of visits to certain
                                pages, page interaction information (such as
                                scrolling, clicks, and mouse-overs), methods
                                used to browse away from the page, or any phone
                                number used to call our customer service number.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>
                        <u>
                            <span>HOW WE COLLECT INFORMATION ABOUT YOU</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We use different methods to collect your information,
                        including through:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>
                                <span>Direct interactions with you</span>
                            </strong>
                            <span>&nbsp;</span>
                            <strong>
                                <span>when you provide it to us</span>
                            </strong>
                            <span>
                                , for example, by filling in forms or
                                corresponding with us by phone, email, or
                                otherwise.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>User contributions</span>
                            </strong>
                            <span>
                                . You may also provide information for us to
                                publish or display on public Website areas or
                                transmit to other Website users or third
                                parties.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>
                                    Automated technologies or interactions
                                </span>
                            </strong>
                            <span>
                                , as you navigate through our Website.
                                Information collected automatically may include
                                usage details, IP addresses, and information
                                collected through cookies, web beacons, and
                                other tracking technologies.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>
                                    Third parties or publicly available sources
                                </span>
                            </strong>
                            <span>, for example, our business partners.</span>
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>
                        <u>
                            <span>Information You Provide to Us&nbsp;</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        The information we collect directly from you on or
                        through our Website may include:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                Information that you provide by filling in forms
                                on our Website. This includes information
                                provided at the time of registering to use our
                                Website, subscribing to our service, posting
                                material, and requesting further services. We
                                may also ask you for information when you enter
                                a contest or promotion sponsored by us, and when
                                you report a problem with our Website.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Records and copies of your correspondence
                                (including email addresses), if you contact us.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Your responses to surveys that we might ask you
                                to complete for research purposes.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Details of transactions you carry out through
                                our Website and of the fulfillment of your
                                orders. You may be required to provide financial
                                information before placing an order through our
                                Website.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Your search queries on the Website.</span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        You may also provide information to be published or
                        displayed (hereinafter, &quot;
                    </span>
                    <strong>
                        <span>posted</span>
                    </strong>
                    <span>
                        &quot;) on public areas of the Website or transmitted to
                        other users of the Website or third parties
                        (collectively, &quot;
                    </span>
                    <strong>
                        <span>User Contributions</span>
                    </strong>
                    <span>
                        &quot;). Your User Contributions are posted on and
                        transmitted to others at your own risk. Although you may
                        set certain privacy settings for such information by
                        logging into your account profile, please be aware that
                        no security measures are perfect. Additionally, we
                        cannot control the actions of other users of the Website
                        with whom you may choose to share your User
                        Contributions. Therefore, we cannot and do not guarantee
                        that unauthorized persons will not view your User
                        Contributions.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>
                                Information We Collect Through Cookies and Other
                                Automatic Data Collection Technologies &nbsp;
                            </span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        As you navigate through and interact with our Website,
                        we may use cookies or other automatic data collection
                        technologies to collect certain information about your
                        equipment, browsing actions, and patterns, including:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                Details of your visits to our Website, including
                                traffic data, location data, logs, and other
                                communication data and the resources that you
                                access and use on the Website.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Information about your computer and internet
                                connection, including your IP address, operating
                                system, and browser type.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        We may also use these technologies to collect
                        information about your online activities over time and
                        across third-party websites or other online services
                        (behavioral tracking). To learn more or to opt-out of
                        tailored advertising please visit&nbsp;Digital
                        Advertising Alliance of Canada Opt-Out Tool for
                        information on how you can opt out of behavioral
                        tracking on this Website and how we respond to web
                        browser signals and other mechanisms that enable
                        consumers to exercise choice about behavioral tracking.
                    </span>
                </p>
                <p>
                    <span>
                        The information we collect automatically is statistical
                        data and does not include personal information is
                        statistical information and may include personal
                        information is statistical information that includes
                        personal information, and we may maintain it or
                        associate it with personal information we collect in
                        other ways, that you provide to us, or receive from
                        third parties. It helps us to improve our Website and to
                        deliver a better and more personalized service,
                        including by enabling us to:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                Estimate our audience size and usage patterns.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Store information about your preferences,
                                allowing us to customize our Website according
                                to your individual interests.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Speed up your searches.</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Recognize you when you return to our Website.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        The technologies we use for this automatic data
                        collection may include:
                    </span>
                </p>
                <ul>
                    <li>
                        <strong>
                            <ul>
                                <li>
                                    <p>
                                        <span>
                                            Cookies (or browser cookies).
                                        </span>
                                        <span>
                                            &nbsp;A cookie is a small file
                                            placed on the hard drive of your
                                            computer. You may refuse to accept
                                            browser cookies by activating the
                                            appropriate setting on your browser.
                                            However, if you select this setting
                                            you may be unable to access certain
                                            parts of our Website. Unless you
                                            have adjusted your browser setting
                                            so that it will refuse cookies, our
                                            system will issue cookies when you
                                            direct your browser to our
                                            Website.&nbsp;
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </strong>
                        <strong>
                            <ul>
                                <li>
                                    <p>
                                        <span>Flash Cookies.</span>
                                        <span>
                                            &nbsp;Certain features of our
                                            Website may use local stored objects
                                            (or Flash cookies) to collect and
                                            store information about your
                                            preferences and navigation to, from,
                                            and on our Website. Flash cookies
                                            are not managed by the same browser
                                            settings that are used for browser
                                            cookies. For information about
                                            managing your privacy and security
                                            settings for Flash cookies,
                                            see&nbsp;
                                        </span>
                                        <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.17dp8vu'>
                                            <span>
                                                Choices About How We Use and
                                                Disclose Your Information
                                            </span>
                                        </a>
                                        <span>.</span>
                                    </p>
                                </li>
                            </ul>
                        </strong>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>Web Beacons.</span>
                            </strong>
                            <span>
                                &nbsp;Pages of our Website and our e-mails may
                                contain small electronic files known as web
                                beacons (also referred to as clear gifs, pixel
                                tags, and single-pixel gifs) that permit the
                                Company, for example, to count users who have
                                visited those pages or opened an email and for
                                other related website statistics (for example,
                                recording the popularity of certain website
                                content and verifying system and server
                                integrity).&nbsp;
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>
                        <span>
                            Third Party Use of Cookies and Other Tracking
                            Technologies
                        </span>
                    </strong>
                </p>
                <p>
                    <span>
                        Some content or applications on the Website, including
                        advertisements, are served by third-parties, including
                        advertisers, ad networks and servers, content providers,
                        and application providers. These third parties may use
                        cookies alone or in conjunction with web beacons or
                        other tracking technologies to collect information about
                        you when you use our Website. The information they
                        collect may be associated with your personal information
                        or they may collect information, including personal
                        information, about your online activities over time and
                        across different websites and other online services.
                        They may use this information to provide you with
                        interest-based (behavioural) advertising or other
                        targeted content. In addition to helping advertisers
                        reach the right people for their products and services,
                        behavioural advertising helps support our Website so
                        that you can enjoy free content.
                    </span>
                </p>
                <p>
                    <span>
                        You can opt-out of several third party ad servers&apos;
                        and networks&apos; cookies simultaneously by using an
                        opt-out tool created by the Digital Advertising Alliance
                        of Canada or an opt-out tool created by the Network
                        Advertising Initiative. You can also access these
                        websites to learn more about online behavioural
                        advertising and how to stop websites from placing
                        cookies on your device. Opting out of a network does not
                        mean you will no longer receive online advertising. It
                        does mean that the network from which you opted out will
                        no longer deliver ads tailored to your web preferences
                        and usage patterns.
                    </span>
                </p>
                <p>
                    <span>
                        We do not control these third parties&apos; tracking
                        technologies or how they are used. If you have any
                        questions about an advertisement or other targeted
                        content, you should contact the responsible provider
                        directly. For more information about how you can opt out
                        of receiving targeted advertising from many providers,
                        see&nbsp;
                    </span>
                    <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.17dp8vu'>
                        <span>
                            Choices About How We Use and Disclose Your
                            Information
                        </span>
                    </a>
                    <span>.</span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>HOW WE USE YOUR INFORMATION</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We use information that we collect about you or that you
                        provide to us, including any personal information:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                To present our Website and its contents to you.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To provide you with information, products, or
                                services that you request from us.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To fulfill the purposes for which you provided
                                the information or that were described when it
                                was collected, or any other purpose for which
                                you provide it.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To provide you with notices about your
                                account/subscription, including expiration and
                                renewal notices.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To carry out our obligations and enforce our
                                rights arising from any contracts with you,
                                including for billing and collection or to
                                comply with legal requirements.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To notify you about changes to our Website or
                                any products or services we offer or provide
                                though it.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To improve our Website, products or services,
                                marketing, or customer relationships and
                                experiences.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To allow you to participate in interactive
                                features, social media, or similar features on
                                our Website.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To measure or understand the effectiveness of
                                the advertising we serve to you and others, and
                                to deliver relevant advertising to you.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                In any other way we may describe when you
                                provide the information.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                For any other purpose with your consent.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        We may also use your information to contact you about
                        our own and third-parties&apos; goods and services that
                        may be of interest to you, as permitted by law. If you
                        do not want us to use your information in this way,
                        please check the relevant box located on the form on
                        which we collect your data (the order form/registration
                        form),adjust your user preferences in your account
                        profile, use the unsubscribe mechanism at the bottom of
                        our e-mails and e-mail us at contact@levyl.app. For more
                        information, see&nbsp;
                    </span>
                    <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.17dp8vu'>
                        <span>
                            Choices About How We Use and Disclose Your
                            Information
                        </span>
                    </a>
                    <span>.</span>
                </p>
                <p>
                    <span>
                        We may use the information we have collected from you to
                        enable us to display advertisements to our
                        advertisers&apos; target audiences. Even though we do
                        not disclose your personal information for these
                        purposes without your consent, if you click on or
                        otherwise interact with an advertisement, the advertiser
                        may assume that you meet its target criteria.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>DISCLOSURE OF YOUR INFORMATION</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We may disclose aggregated information about our users,
                        and information that does not identify any individual,
                        without restriction.
                    </span>
                </p>
                <p>
                    <span>
                        We may disclose personal information that we collect or
                        you provide as described in this privacy policy:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>To our subsidiaries and affiliates.</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                In accordance with applicable law, to a buyer or
                                other successor in the event of a merger,
                                divestiture, restructuring, reorganization,
                                dissolution, or other sale or transfer of some
                                or all of Levyl Inc.&apos;s assets, whether as a
                                going concern or as part of bankruptcy,
                                liquidation, or similar proceeding, in which
                                personal information held by Levyl Inc. about
                                our customers and users is among the assets
                                transferred.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To advertisers and advertising networks that
                                require the information to select and serve
                                relevant advertisements to you and others. We do
                                not disclose data about identifiable individuals
                                to our advertisers, but we may provide them with
                                aggregate information about our users (for
                                example, we may inform them that 400 women
                                between 30 and 45 have clicked on their
                                advertisement on a specific day). We may also
                                use such aggregate information to help
                                advertisers target a specific audience (for
                                example, men in a specific location). We may
                                make use of the personal information we have
                                collected from you to enable us to display our
                                advertisers&apos; advertisement to that target
                                audience.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To third parties to market their products or
                                services to you if you have consented to/not
                                opted out of these disclosures. We contractually
                                require these third parties to keep personal
                                information confidential and use it only for the
                                purposes for which we disclose it to them. For
                                more information, see&nbsp;
                            </span>
                            <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.17dp8vu'>
                                <span>
                                    Choices About How We Use and Disclose Your
                                    Information
                                </span>
                            </a>
                            <span>.</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To contractors, service providers, and other
                                third parties we use to support our business
                                (such as analytics and search engine providers
                                that assist us with Website improvement and
                                optimization) and who are contractually
                                obligated to keep personal information
                                confidential, use it only for the purposes for
                                which we disclose it to them, and to process the
                                personal information with the same standards set
                                out in this policy.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To fulfill the purpose for which you provide it.
                                For example, if you give us an email address to
                                use the &quot;email a friend&quot; feature of
                                our Website, we will transmit the contents of
                                that email and your email address to the
                                recipients.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                For any other purpose disclosed by us when you
                                provide the information.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>With your consent.</span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>We may also disclose your personal information:</span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                To comply with any court order, law, or legal
                                process, including to respond to any government
                                or regulatory request, in accordance with
                                applicable law.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                To enforce or apply our terms of use
                                levyl.app/transparency/terms-and-conditions or
                                service agreement
                                levyl.app/transparency/services-agreement and
                                other agreements, including for billing and
                                collection purposes.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                If we believe disclosure is necessary or
                                appropriate to protect the rights, property, or
                                safety of Levyl Inc., our customers, or others.
                                This includes exchanging information with other
                                companies and organizations for the purposes of
                                fraud protection and credit risk reduction.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>
                        <u>
                            <span>TRANSFERRING YOUR PERSONAL INFORMATION</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We may transfer personal information that we collect or
                        that you provide as described in this policy to
                        contractors, service providers, and other third parties
                        we use to support our business (such as analytics and
                        search engine providers that assist us with Website
                        improvement and optimization) and who are contractually
                        obligated to keep personal information confidential, use
                        it only for the purposes for which we disclose it to
                        them, and to process the personal information with the
                        same standards set out in this policy.
                    </span>
                </p>
                <p>
                    <span>
                        We may process, store, and transfer your personal
                        information in and to a foreign country, with different
                        privacy laws that may or may not be as comprehensive as
                        Canadian law. In these circumstances, the governments,
                        courts, law enforcement, or regulatory agencies of that
                        country may be able to obtain access to your personal
                        information through the laws of the foreign country.
                        Whenever we engage a service provider, we require that
                        its privacy and security standards adhere to this policy
                        and applicable Canadian privacy legislation.
                    </span>
                </p>
                <p>
                    <span>
                        You are welcome to contact us to obtain further
                        information about Company policies regarding service
                        providers outside of Canada. See&nbsp;
                    </span>
                    <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.2jxsxqh'>
                        <span>
                            Contact Information and Challenging Compliance
                        </span>
                    </a>
                    <span>.</span>
                </p>
                <p>
                    <span>
                        By submitting your personal information or engaging with
                        the Website, you consent to this transfer, storage, or
                        processing.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>
                                CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR
                                INFORMATION
                            </span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We strive to provide you with choices regarding the
                        personal information you provide to us. We have created
                        mechanisms to provide you with the following control
                        over your information:&nbsp;
                    </span>
                </p>
                <ul>
                    <li>
                        <strong>
                            <ul>
                                <li>
                                    <p>
                                        <span>
                                            Tracking Technologies and
                                            Advertising.
                                        </span>
                                        <span>
                                            &nbsp;You can set your browser to
                                            refuse all or some browser cookies,
                                            or to alert you when cookies are
                                            being sent. To learn how you can
                                            manage your Flash cookie settings,
                                            visit the Flash player settings page
                                            on Adobe&apos;s website. If you
                                            disable or refuse cookies, please
                                            note that some parts of this Website
                                            may not be accessible or may not
                                            function properly. For more
                                            information about tracking
                                            technologies, see&nbsp;
                                        </span>
                                        <a href='https://docs.google.com/document/d/1Sr0uanMOz4SoX_9mFr6-h9s2uJSeYOhV/edit#bookmark=id.tyjcwt'>
                                            <span>
                                                Information We Collect Through
                                                Cookies and Other Automatic Data
                                                Collection Technologies
                                            </span>
                                        </a>
                                        <span>.&nbsp;</span>
                                    </p>
                                </li>
                            </ul>
                        </strong>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>Third-Party Advertising.</span>
                            </strong>
                            <span>
                                &nbsp;If you do not want us to share your
                                personal information with unaffiliated or
                                non-agent third parties for promotional
                                purposes, you can opt-out by checking the
                                relevant box located on the form where we
                                collect your data (the order form/registration
                                form). You can also opt-out by logging into the
                                Website and adjusting your user preferences in
                                your account profile by checking or unchecking
                                the relevant boxes or by sending us an email
                                stating your request to contact@levyl.app.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>
                                    Promotional Offers from the Company.
                                </span>
                            </strong>
                            <span>
                                &nbsp;If you have opted in to receive certain
                                emails from us but no longer wish to have your
                                email address/contact information used by the
                                Company to promote our own or third
                                parties&apos; products or services, you can
                                opt-out by checking the relevant box located on
                                the form on which we collect your data (the
                                order form/registration form) or at any other
                                time by logging into the Website and adjusting
                                your user preferences in your account profile by
                                checking or unchecking the relevant boxes or by
                                sending us an email stating your request to
                                contact@levyl.app. If we have sent you a
                                promotional email, you may unsubscribe by
                                clicking the unsubscribe link we have included
                                in the email. This opt-out does not apply to
                                information provided to the Company as part of a
                                product purchase, warranty registration, product
                                service experience, or other transactions.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>
                                <span>Targeted Advertising.</span>
                            </strong>
                            <span>
                                &nbsp;If you do not want us to use information
                                that we collect or that you provide to us to
                                deliver advertisements according to our
                                advertisers&apos; target-audience preferences,
                                you can opt out by checking the relevant box
                                located on the form on which we collect your
                                data (the order form/registration form) or at
                                any other time by logging into the Website and
                                adjusting your user preferences in your account
                                profile by checking or unchecking the relevant
                                boxes or by sending us an email stating your
                                request to contact@levyl.app. For this opt-out
                                mechanism to function, you must have your
                                browser set to accept browser cookies.&nbsp;
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        We do not control third parties&apos; collection or use
                        of your information to serve interest-based advertising.
                        However, these third parties may provide you with ways
                        to choose not to have your information collected or used
                        in this way. You can opt out of several third party ad
                        servers&apos; and networks&apos; cookies simultaneously
                        by using an opt-out tool created by the Digital
                        Advertising Alliance of Canada and an opt-out tool
                        created by the Network Advertising Initiative. You can
                        also access these websites to learn more about online
                        behavioural advertising and how to stop websites from
                        placing cookies on your device. Opting out of a network
                        does not mean you will no longer receive online
                        advertising. It does mean that the network from which
                        you opted out will no longer deliver ads tailored to
                        your web preferences and usage patterns.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>DATA SECURITY</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        The security of your personal information is very
                        important to us. We use physical, electronic, and
                        administrative measures designed to secure your personal
                        information from accidental loss and from unauthorized
                        access, use, alteration, and disclosure. We store all
                        information you provide to us behind firewalls on our
                        secure servers. Any payment transactions and will be
                        encrypted using SSL technology.
                    </span>
                </p>
                <p>
                    <span>
                        The safety and security of your information also depends
                        on you. Where we have given you (or where you have
                        chosen) a password for access to certain parts of our
                        Website, you are responsible for keeping this password
                        confidential. We ask you not to share your password with
                        anyone. We urge you to be careful about giving out
                        information in public areas of the Website like message
                        boards, which any Website visitor can view.
                    </span>
                </p>
                <p>
                    <span>
                        Unfortunately, the transmission of information via the
                        Internet is not completely secure. Although we do our
                        best to protect your personal information, we cannot
                        guarantee the security of your personal information
                        transmitted to our Website. Any transmission of personal
                        information is at your own risk. We are not responsible
                        for circumvention of any privacy settings or security
                        measures contained on the Website.&nbsp;
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>DATA RETENTION</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        Except as otherwise permitted or required by applicable
                        law or regulation, we will only retain your personal
                        information for as long as necessary to fulfill the
                        purposes we collected it for, including for the purposes
                        of satisfying any legal, accounting, or reporting
                        requirements. Under some circumstances we may anonymize
                        your personal information so that it can no longer be
                        associated with you. We reserve the right to use such
                        anonymous and de-identified data for any legitimate
                        business purpose without further notice to you or your
                        consent.&nbsp;
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>CHILDREN UNDER THE AGE OF 13</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        Our Website is not intended for children under 13 years
                        of age. No one under age 13 may provide any personal
                        information to or on the Website. We do not knowingly
                        collect personal information from children under 13. If
                        you are under 13, do not use or provide any information
                        on this Website or on or through any of its features. If
                        we learn we have collected or received personal
                        information from a child under 13 without verification
                        of parental consent, we will delete that information. If
                        you believe we might have any information from or about
                        a child under 13, please contact us at
                        contact@levyl.app.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>
                                ACCESSING AND CORRECTING YOUR PERSONAL
                                INFORMATION
                            </span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        It is important that the personal information we hold
                        about you is accurate and current. Please keep us
                        informed if your personal information changes. By law
                        you have the right to request access to and to correct
                        the personal information that we hold about you.
                    </span>
                </p>
                <p>
                    <span>
                        You can review and change your personal information by
                        logging into the Website and visiting your account
                        profile page.
                    </span>
                </p>
                <p>
                    <span>
                        If you want to review, verify, correct, or withdraw
                        consent to the use of your personal information you may
                        also send us an email at contact@levyl.app to request
                        access to, correct, or delete any personal information
                        that you have provided to us. We cannot delete your
                        personal information except by also deleting your user
                        account. We may not accommodate a request to change
                        information if we believe the change would violate any
                        law or legal requirement or cause the information to be
                        incorrect. We may charge you a fee to access your
                        personal information, however, we will notify you of any
                        fee in advance.
                    </span>
                </p>
                <p>
                    <span>
                        We may request specific information from you to help us
                        confirm your identity and your right to access, and to
                        provide you with the personal information that we hold
                        about you or make your requested changes. Applicable law
                        may allow or require us to refuse to provide you with
                        access to some or all of the personal information that
                        we hold about you, or we may have destroyed, erased, or
                        made your personal information anonymous in accordance
                        with our record retention obligations and practices. If
                        we cannot provide you with access to your personal
                        information, we will inform you of the reasons why,
                        subject to any legal or regulatory restrictions.
                    </span>
                </p>
                <p>
                    <span>
                        We will provide access to your personal information,
                        subject to exceptions set out in applicable privacy
                        legislation. Examples of such exceptions include:
                    </span>
                </p>
                <ul>
                    <li>
                        <p>
                            <span>
                                Information protected by solicitor-client
                                privilege.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Information that is part of a formal dispute
                                resolution process.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Information that is about another individual
                                that would reveal their personal information or
                                confidential commercial information.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>
                                Information that is prohibitively expensive to
                                provide.
                            </span>
                        </p>
                    </li>
                </ul>
                <p>
                    <span>
                        If you are concerned about our response or would like to
                        correct the information provided, you may contact our
                        Privacy Officer at contact@levyl.app.
                    </span>
                </p>
                <p>
                    <span>
                        If you delete your User Contributions from the Website,
                        copies of your User Contributions may remain viewable in
                        cached and archived pages or might have been copied or
                        stored by other Website users. Proper access and use of
                        information provided on the Website, including User
                        Contributions, is governed by our terms of use
                        levyl.app/transparency/terms-and-conditions.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>WITHDRAWING YOUR CONSENT</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        Where you have provided your consent to the collection,
                        use, and transfer of your personal information, you may
                        have the legal right to withdraw your consent under
                        certain circumstances. To withdraw your consent, if
                        applicable, contact us at contact@levyl.app. Please note
                        that if you withdraw your consent we may not be able to
                        provide you with a particular product or service. We
                        will explain the impact to you at the time to help you
                        with your decision.&nbsp;
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>CHANGES TO OUR PRIVACY POLICY</span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        It is our policy to post any changes we make to our
                        privacy policy on this page with a notice that the
                        privacy policy has been updated on the Website home
                        page. If we make material changes to how we treat our
                        users&apos; personal information, we will notify you by
                        email to the primary email address specified in your
                        account or through a notice on the Website home
                        page.&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        We include the date the privacy policy was last revised
                        at the top of the page. You are responsible for ensuring
                        we have an up-to-date, active, and deliverable email
                        address for you, and for periodically visiting our
                        Website and this privacy policy to check for any
                        changes.
                    </span>
                </p>
                <p>
                    <strong>
                        <u>
                            <span>
                                CONTACT INFORMATION AND CHALLENGING COMPLIANCE
                            </span>
                        </u>
                    </strong>
                </p>
                <p>
                    <span>
                        We welcome your questions, comments, and requests
                        regarding this privacy policy and our privacy practices.
                        Please contact us at:&nbsp;
                    </span>
                </p>
                <p>
                    <span>Privacy Officer</span>
                </p>
                <p>
                    <span>contact@levyl.app&nbsp;</span>
                </p>
                <p>
                    <span>
                        We have procedures in place to receive and respond to
                        complaints or inquiries about our handling of personal
                        information, our compliance with this policy, and with
                        applicable privacy laws. To discuss our compliance with
                        this policy please contact our Privacy Officer using the
                        contact information listed above.
                    </span>
                </p>
            </Typography>
        </StyledContainer>
    </StyledBackground>
);

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBarSection
                logo={{ file: { url: logo } }}
                links={[
                    {
                        content: 'Career Seekers',
                        link: '/public/career-seekers'
                    },
                    { content: 'Employers', link: '/public/companies' }
                ]}
                logoLink=''
            />
            <ContainerSection heading={'Privacy Policy'} />
            <FooterSection content={''} />
        </>
    );
};

export default PrivacyPolicy;
