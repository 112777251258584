import { styled, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { getJobOffers } from '../../../services/jobs';
import { getNetworkConnections } from '../../../services/user';
import thumbsUp from '../../../static/icons/thumbs-up.svg';
import { UserContext } from '../../context/UserContext';
import { BaseContainer } from '../../global/Core';

import ContactCard from './ContactCard';

import type { JobOffer } from '../../../types/job';
import type { NetworkConnection } from '../../../types/user';

const ContentContainer = styled('div')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    padding-top: 4rem;
    gap: 1rem;
`;

export default function CandidateNetwork() {
    const [contacts, setContacts] = useState<NetworkConnection[]>([]);
    const [offers, setOffers] = useState<JobOffer[]>([]);
    const [tab, setTab] = useState<'offers' | 'contacts'>('contacts');
    const { user } = useContext(UserContext);

    const openOfferContactIDs = useMemo(
        () =>
            offers.map((offer) =>
                user.levylRole === 'candidate'
                    ? offer.managerID
                    : offer.candidateID
            ),
        [offers, user.levylRole]
    );

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: 'offers' | 'contacts'
    ) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (user.id) {
            getNetworkConnections(user.id).then(
                (connections: NetworkConnection[]) => {
                    setContacts(connections);
                }
            );
            getJobOffers(user.id).then((offerlist: JobOffer[]) => {
                setOffers(
                    offerlist.filter((offer) => offer.status === 'pending')
                );
            });
        }
    }, [user.id, user.levylRole]);

    return (
        <BaseContainer>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem'
                }}
            >
                <Typography color={'text.secondary'} variant={'h1'}>
                    {'Network'}
                </Typography>
                <img src={thumbsUp} style={{ marginLeft: '1.5rem' }} />
            </div>
            <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label='interview status'
                color='primary'
            >
                <Tab
                    label={
                        <Typography
                            fontWeight={'600'}
                            fontSize={'1rem'}
                            style={{ textTransform: 'none' }}
                        >
                            {'Contacts'}
                        </Typography>
                    }
                    aria-label='interview'
                    value='contacts'
                />
                <Tab
                    label={
                        <Typography
                            fontWeight={'600'}
                            fontSize={'1rem'}
                            style={{ textTransform: 'none' }}
                        >
                            {'Job Offers'}
                        </Typography>
                    }
                    aria-label='home'
                    value='offers'
                />
            </Tabs>
            {tab === 'offers' && (
                <ContentContainer container spacing={2}>
                    {contacts
                        .filter((connection) =>
                            openOfferContactIDs.includes(connection.contact.id)
                        )
                        .map((contact, idx) => (
                            <ContactCard
                                key={idx}
                                contact={contact}
                                uid={user.id}
                                userID={user.userID}
                                role={user.levylRole}
                                offer={offers.find((offer) =>
                                    user.levylRole === 'candidate'
                                        ? offer.managerID === contact.contact.id
                                        : offer.candidateID ===
                                          contact.contact.id
                                )}
                            ></ContactCard>
                        ))}
                </ContentContainer>
            )}
            {tab === 'contacts' && (
                <ContentContainer container spacing={2}>
                    {contacts
                        .filter(
                            (connection) =>
                                !openOfferContactIDs.includes(
                                    connection.contact.id
                                )
                        )
                        .map((contact, idx) => (
                            <ContactCard
                                key={idx}
                                contact={contact}
                                uid={user.id}
                                userID={user.userID}
                                role={user.levylRole}
                            ></ContactCard>
                        ))}
                </ContentContainer>
            )}
        </BaseContainer>
    );
}
