import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled, Typography } from '@mui/material';
import React from 'react';

import Button, { TextButton } from '../../components/Button/Button';
import { BaseCard, ProfilePicture } from '../../Core';

export const StyledButton = styled(Button)`
    margin-top: 1.5rem;
`;

export const StyledTextButton = styled(TextButton)`
    margin-top: 0.5rem;
`;

export const StyledSmallCard = styled(BaseCard)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
`;

export type SmallCardProps = {
    imageSrc: string;
    children?: React.ReactNode;
    statusText: string;
    primaryButton?: {
        text: string;
        onClick?: () => void;
        to?: string;
    };
    secondaryButton?: {
        text: string;
        onClick?: () => void;
        to?: string;
    };
};

export default function SmallCard({
    imageSrc,
    children,
    statusText,
    primaryButton,
    secondaryButton
}: SmallCardProps) {
    return (
        <StyledSmallCard variant='outlined'>
            <ProfilePicture size={2} src={imageSrc} />
            {children}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.25rem'
                }}
            >
                <AccessTimeIcon sx={{ mr: 0.5 }} fontWeight={'400'} />
                <Typography
                    variant={'body2'}
                    fontWeight={'500'}
                    style={{ textAlign: 'center' }}
                >
                    {statusText}
                </Typography>
            </div>
            {primaryButton && (
                <StyledButton
                    variant='contained'
                    onClick={primaryButton.onClick}
                    to={primaryButton.to}
                >
                    {primaryButton.text}
                </StyledButton>
            )}
            {secondaryButton && (
                <StyledTextButton
                    variant='text'
                    onClick={secondaryButton.onClick}
                    to={secondaryButton.to}
                    color='secondary'
                >
                    {secondaryButton.text}
                </StyledTextButton>
            )}
        </StyledSmallCard>
    );
}
