import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import TextInput from '../../../global/components/TextField/TextField';
import Upload from '../../../global/components/UploadFile';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import {
    FormSection,
    NextSteps,
    ProfilePicture,
    StyledButton
} from '../styles';

import commonContent from './content';

import type { LevylUser, UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        firstName: yup.string().required(commonContent.common.errors.required),
        lastName: yup.string().required(commonContent.common.errors.required),
        currentJobTitle: yup.string(),
        pronouns: yup.string()
    })
    .required();

export default function NameSlide(props: {
    profile: UserProfile['name'];
    updateProfile: (value: UserProfile['name']) => void;
    userRole: LevylUser;
    onClose: () => Promise<void>;
}) {
    const { imageURL: profileImage, ...profile } = props.profile;
    const [imageURL, setImageURL] = useState(profileImage);
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: profile
    });
    const { uid } = useParams<{ uid: string }>();

    const onSubmit = (data: {
        firstName: string;
        lastName: string;
        currentJobTitle: string;
        pronouns: string;
    }) => {
        props.updateProfile({ ...data, imageURL });
    };

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={props.userRole}
            onClose={props.onClose}
            id={'nameSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>{commonContent.common.stepOneSubtitle}</Typography>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '1rem',
                        justifyContent: 'space-around'
                    }}
                >
                    <ProfilePicture src={imageURL} sx={{ mr: 1 }} />
                    <Upload
                        userId={uid}
                        value={imageURL}
                        setValue={setImageURL}
                        id={'profileImage'}
                        label={commonContent.name.imageURL.label}
                    />
                </div>
                <TextInput
                    id='firstName'
                    label={commonContent.name.firstName.label}
                    color='primary'
                    error={!!errors.firstName}
                    errorText={errors.firstName?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <TextInput
                    id='lastName'
                    label={commonContent.name.lastName.label}
                    color='primary'
                    error={!!errors.lastName}
                    errorText={errors.lastName?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <TextInput
                    id='currentJobTitle'
                    label={commonContent.name.currentJobTitle.label}
                    color='primary'
                    error={!!errors.currentJobTitle}
                    errorText={errors.currentJobTitle?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <TextInput
                    id='pronouns'
                    label={commonContent.name.pronouns.label}
                    color='primary'
                    error={!!errors.pronouns}
                    helperText={commonContent.name.pronouns.helperText}
                    errorText={errors.pronouns?.message}
                    placeholder={''}
                    control={control as unknown as Control<FieldValues>}
                />
                <NextSteps>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id='submit'
                    >
                        {commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
