import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

import { iconMapping } from './SidebarList';

export type SideBarButtons = {
    text: keyof typeof iconMapping;
    to: string;
    active: boolean;
}[];

const StyledListItem = styled(ListItem)<{ active: boolean }>(
    ({ theme, active }) => `
    border-radius: 0.5rem;
    background-color: ${active ? theme.palette.primary.light : 'transparent'};
    &:hover {
        background-color: ${theme.palette.primary.light};
    }
    `
);

const StyledListItemIcon = styled(ListItemIcon)<{ active: boolean }>(
    ({ theme, active }) => `
    min-width: 14px;
    padding-right: 8px;
    color: ${
        active ? theme.palette.primary.main : theme.palette.secondary.main
    };
`
);

const StyledListItemText = styled(ListItemText)<{ active: boolean }>(
    ({ theme, active }) => `
    color: ${
        active ? theme.palette.primary.main : theme.palette.secondary.main
    };
`
);

const scrollOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -88;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export default function SideBarButton(props: {
    to: string;
    text: keyof typeof iconMapping;
    active: boolean;
}) {
    return (
        <StyledListItem
            component={HashLink}
            to={props.to}
            scroll={scrollOffset}
            button
            active={props.active}
        >
            <StyledListItemIcon active={props.active}>
                {iconMapping[props.text]}
            </StyledListItemIcon>
            <StyledListItemText active={props.active} primary={props.text} />
        </StyledListItem>
    );
}
