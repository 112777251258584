import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';

import { UserContext } from '../../../context/UserContext';
import {
    BackButton,
    TextButton
} from '../../../global/components/Button/Button';

import StyledHeader from './ChannelHeader.styles';

const StyledBackButton = styled(BackButton)`
    @media (min-width: 1200px) {
        display: none;
    }
`;

const ChannelHeader = (props: { name?: string; profileImageURL: string }) => {
    const { user } = useContext(UserContext);
    return (
        <StyledHeader
            sx={{ py: 4, pl: 3, pr: 2 }}
            justifyContent={'flex-start'}
            alignItems={'center'}
            borderBottom={'1px #EFEFEF solid'}
            style={{ flexDirection: 'row' }}
        >
            <StyledHeader
                justifyContent={'flex-start'}
                alignItems={'center'}
                style={{ gap: '1rem', flexDirection: 'row' }}
            >
                <StyledBackButton
                    to={`/secure/${user.userID}/messaging`}
                    startIcon={<KeyboardBackspaceIcon />}
                    color='primary'
                    style={{ height: '2rem' }}
                />
                <div style={{ display: 'flex' }}>
                    <Avatar
                        alt={props.name}
                        src={props.profileImageURL}
                        sx={{
                            width: 64,
                            height: 64,
                            mr: 1,
                            bgcolor: '#C4C4C4'
                        }}
                    />
                    <TextButton
                        to={`/secure/${user.userID}/network`}
                        variant='text'
                    >
                        <Typography
                            variant={'body2'}
                            fontWeight={400}
                            component='h3'
                            mb={1}
                        >
                            {props.name}
                        </Typography>
                    </TextButton>
                </div>
            </StyledHeader>
        </StyledHeader>
    );
};

export default ChannelHeader;
