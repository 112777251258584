import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

export const CalendarCard = styled(Card)`
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    width: 100%;
    min-width: 25rem;
    max-width: 40rem;
    padding: 4rem;
    @media (max-width: 35rem) {
        padding: 2rem;
        min-width: unset;
    }
`;

export const Header = styled('div')`
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CalendarWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;

export const DaySquare = styled('div')`
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14%;
    padding-bottom: 14%;
`;

export const DayHeader = styled('div')`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const SingleDay = styled(ButtonBase)`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: inherit;
`;

export const AvailableDay = styled('div')<{ selected: boolean }>(
    ({ theme, selected }) => `
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    max-width: 3rem;
    max-height: 3rem;
    background: ${selected ? theme.palette.primary.main : 'transparent'};
    color: ${
        selected
            ? theme.palette.primary.contrastText
            : theme.palette.primary.main
    };
`
);

// box-shadow: 0rem 0rem 2rem ${theme.palette.primary.main}40;
