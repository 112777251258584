import { styled } from '@mui/material';
import MUIButton from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useState } from 'react';

import { createFollowUpInterviews } from '../../../services/interviews';
import Button, { TextButton } from '../../global/components/Button/Button';
import { ProfilePicture } from '../../global/Core';

import type { NetworkConnection } from '../../../types/user';

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
    max-height: 90%;
    overflow-y: auto;
    max-width: 60rem;
`;

const TimeSlotButton = styled(MUIButton)`
    padding-left: 3rem;
    padding-right: 3rem;
    @media (max-width: 600px) {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
`;

export default function FollowupInterview(props: {
    open: boolean;
    handleClose: () => void;
    connection: NetworkConnection;
    uid: number;
}) {
    const { connection } = props;
    const [duration, setDuration] = useState<number>(15);

    const [timeList, setTimeList] = useState<moment.Moment[]>([]);

    const [value, setValue] = useState<moment.Moment | null>(moment());

    const addValue = (newValue: moment.Moment) => {
        setTimeList([...timeList, newValue]);
    };

    const removeTime = (index: number) => {
        setTimeList(timeList.filter((_, i) => i !== index));
    };

    return (
        <StyledModal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            disableScrollLock
        >
            <StyledModalContent
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginBottom: '1rem'
                    }}
                >
                    <ProfilePicture
                        src={connection.contact.name.imageURL}
                        size={4}
                        style={{ marginRight: '1rem' }}
                    />
                    <div>
                        <Typography
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.25rem'
                            }}
                            variant={'body2'}
                        >
                            <strong>{`${connection.contact.name.firstName} ${connection.contact.name.lastName}`}</strong>
                        </Typography>
                        <Typography
                            style={{ marginBottom: '0.25rem' }}
                            variant={'body2'}
                        >{`${connection.contact.name.currentJobTitle}`}</Typography>
                    </div>
                </div>
                <Typography sx={{ mt: 0 }}>
                    {"Let's book a follow-up interview!"}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    {
                        'Choose some available dates and times that works for you and we will send them to the candidate.'
                    }
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ButtonGroup
                        variant='outlined'
                        aria-label='Basic button group'
                        style={{ display: 'flex' }}
                    >
                        <TimeSlotButton
                            onClick={() => setDuration(15)}
                            variant={duration === 15 ? 'contained' : 'outlined'}
                        >
                            {'15'}
                        </TimeSlotButton>
                        <TimeSlotButton
                            onClick={() => setDuration(30)}
                            variant={duration === 30 ? 'contained' : 'outlined'}
                        >
                            {'30'}
                        </TimeSlotButton>
                        <TimeSlotButton
                            onClick={() => setDuration(45)}
                            variant={duration === 45 ? 'contained' : 'outlined'}
                        >
                            {'45'}
                        </TimeSlotButton>
                        <TimeSlotButton
                            onClick={() => setDuration(60)}
                            variant={duration === 60 ? 'contained' : 'outlined'}
                        >
                            {'60'}
                        </TimeSlotButton>
                    </ButtonGroup>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginTop: '1rem'
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            label='Basic date time picker'
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            onClose={() => addValue(value as moment.Moment)}
                        />
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Stack direction='column' spacing={1}>
                        {timeList.map((time, index) => (
                            <Chip
                                label={time.format('YYYY-MM-DD - HH:mm')}
                                variant={'outlined'}
                                key={index}
                                onDelete={() => removeTime(index)}
                            />
                        ))}
                    </Stack>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '1rem'
                    }}
                >
                    <TextButton
                        onClick={() => {
                            props.handleClose();
                        }}
                    >
                        {'Cancel'}
                    </TextButton>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            createFollowUpInterviews(
                                connection.interviews[0].jobID,
                                props.uid,
                                timeList.map((time) => time.format()),
                                duration,
                                connection.contact.id
                            ).then(() => {
                                props.handleClose();
                            });
                        }}
                    >
                        {'Send'}
                    </Button>
                </div>
            </StyledModalContent>
        </StyledModal>
    );
}
