import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';

import { addSavedJob, removeSavedJob } from '../../../../services/jobs';
import { format } from '../../../../utils/currency';
import getShortenedName from '../../../../utils/getShortenedName';
import { UserContext } from '../../../context/UserContext';
import Button, { TextButton } from '../../../global/components/Button/Button';
import StyledCard from '../../../global/Core';

import {
    BookmarkButton,
    CardHeaderText,
    StyledProfilePicture as ProfilePicture,
    StyledCardBody,
    StyledCardFooter,
    StyledCardHeader
} from './SummaryJobCard.styles';

import type { Job } from '../../../../types/job';

export default function JobCard({ job }: { job: Job }) {
    const [bookmark, setBookmark] = useState(false);
    const { user } = useContext(UserContext);

    const handleBookmark = async () => {
        if (bookmark) {
            await removeSavedJob(user.id, job.id);
        } else {
            await addSavedJob(user.id, job.id);
        }
        setBookmark(!bookmark);
    };

    useEffect(() => {
        if (job.saved) {
            setBookmark(true);
        }
    }, [job.saved]);

    return (
        <StyledCard variant='outlined'>
            <StyledCardHeader>
                <ProfilePicture
                    size={3}
                    src={job.company.logoURL}
                    sx={{ ml: 1, mr: 2 }}
                />
                <CardHeaderText>
                    <div>
                        <Typography
                            variant={'h6'}
                            style={{
                                display: 'flex',
                                fontWeight: 400,
                                fontSize: 16,
                                flexWrap: 'wrap'
                            }}
                        >
                            <TextButton
                                style={{ display: 'contents' }}
                                variant='text'
                                to={`/secure/${user.userID}/company/${job.company.id}`}
                            >
                                {job.company.name}
                            </TextButton>
                            <span
                                style={{
                                    marginLeft: '0.25rem',
                                    marginRight: '0.25rem'
                                }}
                            >
                                {'is hiring a'}
                            </span>
                            <span
                                style={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    fontWeight: 600
                                }}
                            >
                                {job.title}
                            </span>
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: '4px'
                            }}
                        >
                            <Typography variant={'body1'}>
                                {job.company.location}
                            </Typography>
                        </div>
                    </div>
                    {!job.hideSalary && (
                        <Typography
                            variant={'body2'}
                            sx={{ mr: 0.25, pb: 0.125 }}
                        >
                            {format(job.maxSalary, 'en-CA', 'CAD', 0)}
                        </Typography>
                    )}
                </CardHeaderText>
                <BookmarkButton
                    id={`bookmark-${job.id}`}
                    onClick={handleBookmark}
                    startIcon={
                        bookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />
                    }
                    color='primary'
                />
            </StyledCardHeader>
            <StyledCardBody>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                        {getShortenedName(
                            `${job.createdBy.name.firstName} ${job.createdBy.name.lastName}`
                        )}
                    </Typography>
                    <ProfilePicture
                        src={job.createdBy.name.imageURL}
                        size={1.5}
                        sx={{ mx: 1 }}
                    />
                </div>
                <Typography
                    sx={{ my: 2 }}
                    variant={'body2'}
                    dangerouslySetInnerHTML={{
                        __html: job.shortDescription
                    }}
                />
                <TextButton to={`/secure/${user.userID}/job/${job.id}`}>
                    {'Read more'}
                </TextButton>
            </StyledCardBody>
            <StyledCardFooter>
                <Typography
                    variant={'body2'}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '16px'
                    }}
                >
                    <Typography
                        variant={'body2'}
                        color='primary'
                        component='span'
                        fontWeight={700}
                        sx={{ mr: 0.5 }}
                    >
                        {
                            job.interviews.filter(
                                (interview) => interview.status === 'available'
                            ).length
                        }
                    </Typography>
                    {'spots left'}
                </Typography>
                <Button
                    variant='contained'
                    to={`/secure/${user.userID}/schedule/${job.id}`}
                >
                    {'Book a slot'}
                </Button>
            </StyledCardFooter>
        </StyledCard>
    );
}
