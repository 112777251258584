// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
// import Typography from '@mui/material/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import logo from '../../static/logos/logo_white.svg';
// import { Typography } from '@mui/material';

const StyledBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#302f2f',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '4rem 8rem',
    color: '#f1f1f3',
    gap: '10rem',
    [theme.breakpoints.down('md')]: {
        padding: '2rem 4rem',
        flexDirection: 'column',
        gap: '0.5rem',
        alignItems: 'flex-start'
    }
}));

const StyledContent = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0 0 0 0;
`;

export interface FooterProps {
    content: string;
}

const StyledLogo = styled('img')(({ theme }) => ({
    width: theme.spacing(7),
    marginBottom: theme.spacing(4)
}));

const StyledListItem = styled(ListItem)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

// const renderIcon = (icon: string) => {
//     const props = { sx: { fontSize: 12, color: 'text.secondary' } };
//     switch (icon) {
//         case 'Twitter':
//             return <TwitterIcon {...props} />;
//         case 'LinkedIn':
//             return <LinkedInIcon {...props} />;
//         case 'YouTube':
//             return <YouTubeIcon {...props} />;
//         default:
//             return <div>{'No Icon'}</div>;
//     }
// };

// eslint-disable-next-line
export default function FooterSection(props: FooterProps) {
    // Todo: remove demo data and use Contentful instead
    const propsDemo: any = {
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing Phasellus facilisis porttitor odio, at pulvinar a neque blandit vehicula ac id nisl.',
        links: [
            { label: 'Terms & Conditions', href: '/public/terms' },
            { label: 'Privacy Policy', href: '/public/privacy-policy' },
            { label: 'Services Agreement', href: '/public/services-agreement' }
        ],
        page: [
            { label: 'Login', href: '/login' },
            { label: 'Career Seekers', href: '/public/career-seekers' },
            { label: 'Companies', href: '/public/companies' }
        ],
        email: 'contact@levyl.app',
        socialLinks: {
            Twitter: 'https://twitter.com',
            LinkedIn: 'https://linkedin.com',
            YouTube: 'https://youtube.com'
        }
    };
    return (
        <StyledBackground>
            <StyledContent>
                <div>
                    <RouterLink to={'/secure/dashboard'}>
                        <StyledLogo src={logo} />
                    </RouterLink>
                </div>
                {/* <Typography variant={'body2'} mb={4}>
                    {propsDemo.content}
                </Typography> */}
                <Link
                    href={`mailto:${propsDemo.email}`}
                    color={'common.white'}
                    variant={'body2'}
                    underline='none'
                >
                    {propsDemo.email}
                </Link>
            </StyledContent>
            <StyledContent component='ul'>
                {propsDemo.links.map((link: any) => (
                    <StyledListItem
                        key={link.label}
                        disableGutters
                        disablePadding
                    >
                        <Link
                            variant='body2'
                            component={RouterLink}
                            to={link.href}
                            underline='none'
                            color='grey.300'
                            my={0.5}
                        >
                            {link.label}
                        </Link>
                    </StyledListItem>
                ))}
            </StyledContent>
            {/* <StyledContent component='ul' isRow sx={{ mt: 1 }}>
                    {Object.entries(propsDemo.socialLinks).map(
                        (link: any, i: number, arr) => (
                            <ListItem
                                key={link[0]}
                                disableGutters
                                disablePadding
                                sx={{ pr: i === arr.length - 1 ? 0 : 2 }}
                            >
                                <Link
                                    href={link[1]}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        bgcolor: '#f1f1f3',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {renderIcon(link[0])}
                                </Link>
                            </ListItem>
                        )
                    )}
                </StyledContent> */}
            <StyledContent component='ul'>
                {propsDemo.page.map((link: any) => (
                    <StyledListItem
                        key={link.label}
                        disableGutters
                        disablePadding
                    >
                        <Link
                            variant='body2'
                            component={RouterLink}
                            to={link.href}
                            underline='none'
                            color='grey.300'
                            my={0.5}
                        >
                            {link.label}
                        </Link>
                    </StyledListItem>
                ))}
            </StyledContent>
        </StyledBackground>
    );
}
