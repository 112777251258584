import { ThemeProvider } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import React, { useContext, useEffect, useState } from 'react';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';

import { DevCycleProvider } from './context/DevCycleContext';
import { FirebaseContext, FirebaseProvider } from './context/FirebaseContext';
import { UserProvider } from './context/UserContext';
import AdminPage from './pages/Admin/AdminPage';
import JobPost from './pages/CreateJob/JobPost';
import LoginRoutes from './pages/Login/LoginRoutes';
import BusinessProfile from './pages/Profile/business/BusinessProfile';
import CandidateProfile from './pages/Profile/candidate/CandidateProfile';
import CandidatesPublicPage from './public/pages/Candidates';
import EmployersPublicPage from './public/pages/Employers';
import PrivacyPolicyPage from './public/pages/PrivacyPolicy';
import ServicesAgreementPage from './public/pages/ServicesAgreement';
import TermsPage from './public/pages/Terms';
import redirects from './redirects';
import SecureSite from './SecureSite';
import theme from './Theme';

function App({
    firebaseUser
}: {
    firebaseUser: firebase.User | null | undefined;
}) {
    return (
        <ThemeProvider theme={theme}>
            <Switch>
                {redirects.map(({ path, to }) => (
                    <Redirect key={path} from={path} to={to} />
                ))}
                <Route path='/public/career-seekers'>
                    <CandidatesPublicPage />
                </Route>
                <Route path='/public/companies'>
                    <EmployersPublicPage />
                </Route>
                <Route path='/public/terms'>
                    <TermsPage />
                </Route>
                <Route path='/public/privacy-policy'>
                    <PrivacyPolicyPage />
                </Route>
                <Route path='/public/services-agreement'>
                    <ServicesAgreementPage />
                </Route>
                <Route path='*'>
                    <Switch>
                        <Route path='/secure/:uid'>
                            {firebaseUser && <SecureSite />}
                            {firebaseUser === null && <Redirect to='/login' />}
                        </Route>
                        <Route
                            path={[
                                '/create/:uid/candidate',
                                '/edit/:uid/candidate'
                            ]}
                        >
                            {firebaseUser && <CandidateProfile />}
                            {firebaseUser === null && <Redirect to='/login' />}
                        </Route>
                        <Route
                            path={[
                                '/create/:uid/business',
                                '/edit/:uid/business/:companyID'
                            ]}
                        >
                            {firebaseUser && <BusinessProfile />}
                            {firebaseUser === null && <Redirect to='/login' />}
                        </Route>
                        <Route
                            path={[
                                '/create/:uid/job/:companyID',
                                '/edit/:uid/job/:companyID/:jobID'
                            ]}
                        >
                            {firebaseUser && <JobPost />}
                            {firebaseUser === null && <Redirect to='/login' />}
                        </Route>
                        <Route
                            exact
                            path={[
                                '/login',
                                '/resetpassword',
                                '/signup/select',
                                '/signup/user',
                                '/signup/business'
                            ]}
                        >
                            <LoginRoutes user={firebaseUser} />
                        </Route>
                        <Route path={'/admin'}>
                            <AdminPage />
                        </Route>
                        <Redirect
                            exact
                            from={'/'}
                            to={'/public/career-seekers'}
                        />
                        <Redirect from={'*'} to={'/login'} />
                    </Switch>
                </Route>
            </Switch>
        </ThemeProvider>
    );
}

const AppWithContext = () => {
    const [firebaseUser, setFirebaseUser] = useState<
        firebase.User | null | undefined
    >(undefined);
    const { auth } = useContext(FirebaseContext);

    useEffect(() => {
        const unsubscribe = auth?.onAuthStateChanged((newUser) => {
            if (newUser) {
                setFirebaseUser(newUser);
            } else {
                setFirebaseUser(null);
            }
        });
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [auth]);

    useEffect(() => {
        auth?.getRedirectResult().then((userCredential) => {
            if (userCredential.user !== null) {
                setFirebaseUser(userCredential.user);
            }
        });
    }, [auth]);

    return (
        <Router>
            <UserProvider firebaseUser={firebaseUser as firebase.User}>
                <DevCycleProvider>
                    <App firebaseUser={firebaseUser} />
                </DevCycleProvider>
            </UserProvider>
        </Router>
    );
};

const FirebaseApp = () => (
    <FirebaseProvider>
        <AppWithContext />
    </FirebaseProvider>
);

export default FirebaseApp;
