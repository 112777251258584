import { styled } from '@mui/material/styles';
import React from 'react';

import logo from '../../../../static/logos/name.svg';

import ChatName from './ChatName';
import ChatTimer from './ChatTimer';
import ChatTray from './ChatTray';
import Tile from './VideoTile';

const VideoScreen = styled('div')`
    position: relative;
    flex-grow: 1;
    background: #ebebeb;
    width: 100%;
    border-radius: 1rem;
`;

const VideoOverlay = styled('div')`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    flex-direction: column;
    padding: 1rem;
`;

const Container = styled('div')`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;

const StyledLogo = styled('img')`
    height: 1.5rem;
    margin: 1rem;
`;

const StyledVideo = styled(Tile)`
    border: 1px solid black;
    padding: 1rem;
`;

const LocalVideo = styled('div')`
    background: black;
    width: 12rem;
    height: 8rem;
    border-radius: 0.5rem;
`;

const VideoOverlayTop = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const VideoOverlayBottom = styled('div')`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export default function ChatScreenDisplay(props: {
    localAudio: MediaStreamTrack | undefined;
    localVideo: MediaStreamTrack | undefined;
    callerAudio?: MediaStreamTrack | undefined;
    callerVideo?: MediaStreamTrack | undefined;
    updateLocal: (stream: 'audio' | 'video', value: boolean) => void;
    endSingleInterview: () => void;
    profilePicture: string;
    name: string;
    timer: { minutes: number; seconds: number };
    preview?: boolean;
    toggleSidebar: () => void;
}) {
    return (
        <Container>
            {!props.preview && <StyledLogo src={logo} />}
            <VideoScreen>
                <StyledVideo
                    // todo remove
                    // @ts-ignore
                    videoTrack={
                        props.preview ? props.localVideo : props.callerVideo
                    }
                    // todo remove
                    // @ts-ignore
                    audioTrack={
                        props.preview ? props.localAudio : props.callerAudio
                    }
                    isLocal={props.preview}
                />
                {!props.preview && (
                    <VideoOverlay>
                        <VideoOverlayTop>
                            <ChatName
                                name={props.name}
                                src={props.profilePicture}
                            />
                            <ChatTimer
                                minutes={props.timer.minutes}
                                seconds={props.timer.seconds}
                            />
                        </VideoOverlayTop>
                        <VideoOverlayBottom>
                            <LocalVideo>
                                <StyledVideo
                                    isLocal
                                    // todo remove
                                    // @ts-ignore
                                    videoTrack={props.localVideo}
                                    // todo remove
                                    // @ts-ignore
                                    audioTrack={props.localAudio}
                                />
                            </LocalVideo>
                        </VideoOverlayBottom>
                    </VideoOverlay>
                )}
            </VideoScreen>
            {!props.preview && (
                <ChatTray
                    audio={!!props.localAudio}
                    video={!!props.localVideo}
                    screenShare={false}
                    chat={false}
                    updateLocal={props.updateLocal}
                    endSingleInterview={props.endSingleInterview}
                    toggleSidebar={props.toggleSidebar}
                />
            )}
        </Container>
    );
}
