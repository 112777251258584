import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { VictoryPie } from 'victory';

import { ColourStats, convertDataToPercent, Stats } from './companyStats.utils';

const LegendColour = styled('div')<{ colour: string }>(
    ({ colour }) => `
    width: 1rem;
    height: 1rem;
    background-color: ${colour};
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    flex-shrink: 0;
`
);

export default function PieChart(props: {
    title: string;
    data: Stats;
    hide?: boolean;
}) {
    const data: ColourStats | null = convertDataToPercent(props.data);
    return (
        data && (
            <>
                <Typography
                    variant='h2'
                    style={{
                        marginTop: '1.5rem',
                        marginBottom: '1rem'
                    }}
                >
                    {props.title}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {!props.hide && (
                        <VictoryPie
                            data={Object.keys(data).map((key) => ({
                                x: props.data[key] || 0,
                                y: data[key].value,
                                color: data[key].colour
                            }))}
                            innerRadius={50}
                            style={{
                                labels: {
                                    fontFamily: '"Lato",sans-serif'
                                },
                                data: {
                                    fill: ({ datum }) => datum.color
                                }
                            }}
                        />
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.keys(data).map((key) => (
                            <div
                                key={key}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.5rem'
                                }}
                            >
                                <LegendColour colour={data[key].colour} />
                                <Typography
                                    variant='body2'
                                    style={{ textWrap: 'nowrap' }}
                                >
                                    {`${key} - ${data[key].value}%`}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    );
}
