import React from 'react';

import fourthImage from '../../../static/illustrations/public/employers/fourth.svg';
import firstImage from '../../../static/illustrations/public/employers/intro.png';
import secondImage from '../../../static/illustrations/public/employers/second.svg';
import thirdImage from '../../../static/illustrations/public/employers/third.png';
import logo from '../../../static/logos/name.svg';
import FooterSection from '../FooterSection';
import HowSection from '../HowSection';
import IntroSection from '../IntroSection';
import NavBarSection from '../NavBarSection';
// import PricingTable from '../PricingTable';
import WaitlistSection from '../WaitlistSection';
import WhatSection from '../WhatSection';
import WhySection from '../WhySection';

const Employers = () => (
    <>
        <NavBarSection
            logo={{ file: { url: logo } }}
            links={[
                {
                    content: 'Career Seekers',
                    link: '/public/career-seekers'
                },
                { content: 'Employers', link: '/public/companies' }
            ]}
            logoLink=''
        />
        <IntroSection
            heading={'Hire and attract diverse talent'}
            content={
                'Connect with candidates who are qualified yet often overlooked with our equitable hiring process.'
            }
            link={{
                id: 'join-waitlist-cta-business',
                link: '#waitlist',
                content: 'Join the waitlist'
            }}
            image={{ file: { url: firstImage } }}
        />
        <WhatSection
            heading={'Promote innovation with inclusive hiring'}
            content={
                'A diverse team can bring new solutions to old problems. When you commit to inclusive hiring, you foster an environment of creativity and out-of-the-box thinking.'
            }
            image={{ file: { url: secondImage } }}
        />
        <HowSection
            heading={'Here’s how it works'}
            steps={[
                {
                    title: 'Post a role and set your availability',
                    image: { file: { url: '' } },
                    stepImage: { file: { url: '' } },
                    content:
                        'Select the times you’re available for interviews, and let us do the rest.'
                },
                {
                    title: 'We’ll match the right candidates to the role',
                    image: { file: { url: '' } },
                    stepImage: { file: { url: '' } },
                    content:
                        'Qualified candidates will book a 7-minute video interview with you.'
                },
                {
                    title: 'Meet up to 7 candidates in an hour',
                    image: { file: { url: '' } },
                    stepImage: { file: { url: '' } },
                    content:
                        'Narrow down your favourite candidates and save the rest for future roles'
                }
            ]}
        />
        <WhySection
            heading={'A more human way to build your team'}
            image={{ file: { url: thirdImage } }}
            steps={[
                {
                    title: 'Hire candidates for their potential',
                    content:
                        'Get access to seasoned professionals, recent grads and Canadian newcomers. You’re not only breaking down barriers for marginalized candidates, you’re also increasing your talent pool.'
                },
                {
                    title: 'No more flipping through resumes',
                    content:
                        'Replace long hours of resume screening with face-to-face interactions with potential hires. And all that spare time you have now? Use it to grow your business.'
                },
                {
                    title: 'Eliminate biases through anonymous interviews',
                    content:
                        'We filter candidates based on their profile. Any reference to their ethnicity, gender, age and work history won’t be shared with you before the initial interview.'
                }
            ]}
        />
        {/* <PricingTable /> */}
        <WaitlistSection
            heading={'Ready to improve your hiring process?'}
            content={''}
            fieldHelperText={'Enter your email address'}
            ctaText={'Join the waitlist'}
            image={{ file: { url: fourthImage } }}
        />
        <FooterSection content={''} />
    </>
);

export default Employers;
