import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const StyledChatHeader = styled((props: ContainerProps) => (
    <Container {...props} />
))(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1rem',
    margin: 0
}));

const StyledMessagesSection = styled((props: ContainerProps) => (
    <Container {...props} />
))(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0
}));

interface ChatHeaderProps {
    unreadChats: number;
}
const ChatHeader = ({ unreadChats }: ChatHeaderProps) => (
    <>
        <StyledChatHeader>
            <StyledMessagesSection>
                <Typography variant={'body2'} color={'text.secondary'}>
                    {'Messages'}
                </Typography>
                <Typography
                    variant={'body2'}
                    color={'secondary'}
                    sx={{
                        fontWeight: 400
                    }}
                >
                    {`You have ${unreadChats} new message${
                        unreadChats === 1 ? '' : 's'
                    }`}
                </Typography>
            </StyledMessagesSection>
        </StyledChatHeader>
    </>
);

export default ChatHeader;
