const content = {
    common: {
        submit: 'Next',
        back: 'Back',
        post: 'Post job',
        errors: {
            required: 'This field is required'
        }
    },
    intro: {
        title: 'Find your next hire',
        body: "Create a job posting and we'll bring the right candidates to you.",
        cta: 'Post a job'
    },
    title: {
        title: { label: 'Job title', placeholder: 'Operations Manager' },
        endDate: { label: 'End date', placeholder: 'YYYY-MM-DD' },
        add: 'Additional experience',
        remove: 'Remove experience'
    },
    education: {
        workExperience: {
            label: 'Work experience',
            options: [
                {
                    value: '0-2',
                    label: '0-2 years'
                },
                {
                    value: '2-4',
                    label: '2-4 years'
                },
                {
                    value: '4-9',
                    label: '4-9 years'
                },
                {
                    value: '10',
                    label: '10+ years'
                }
            ]
        },
        educationLevel: {
            label: 'Education level',
            options: [
                {
                    value: 'none',
                    label: 'None'
                },
                {
                    value: 'high_school_diploma',
                    label: 'High School Diploma'
                },
                {
                    value: 'college_diploma',
                    label: 'College Diploma'
                },
                {
                    value: 'bachelors_degree',
                    label: "Bachelor's Degree"
                },
                {
                    value: 'masters_degree',
                    label: "Master's Degree"
                },
                {
                    value: 'doctorate_degree',
                    label: 'Doctorates'
                }
            ]
        }
    },
    environment: {
        label: 'Work Environment',
        options: [
            {
                value: 'remote',
                label: 'Remote'
            },
            {
                value: 'hybrid',
                label: 'Hybrid'
            },
            {
                value: 'office',
                label: 'In Office'
            }
        ]
    },
    salary: {
        title: 'Set a salary range',
        body: 'The average salary for roles like this is ',
        minimumSalary: { label: 'Minimum salary' },
        maximumSalary: { label: 'Maximum salary' },
        checkbox: {
            label: "Don't show salary details",
            description:
                'Are you sure? Including a salary range helps us better match you to candidates. Plus it makes hiring more equitable.'
        },
        benefits: { label: 'Benefits (optional)' }
    },
    description: {
        label: 'Job description',
        body: "Let candidates know what to expect in the role. Don't be afraid to show your personality!"
    },
    shortDescription: {
        label: 'Short job description',
        body: 'Grab your candidates attention with a short eye catching description about the role'
    },
    calendar: {
        title: 'When are you free for interviews?',
        body: 'Select some dates that work for you.'
    },
    time: {
        title: 'Select a time slot',
        duration: {
            label: 'Duration',
            options: [
                {
                    value: '30',
                    label: '30 mins.'
                },
                {
                    value: '45',
                    label: '45 mins.'
                },
                {
                    value: '60',
                    label: '60 mins.'
                }
            ]
        },
        startTime: { label: 'From' },
        endTime: { label: 'To' },
        addSlot: 'Add another slot',
        addDate: 'Add another date',
        remove: { ariaLabel: 'remove' }
    }
};

export default content;
