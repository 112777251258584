import BusinessIcon from '@mui/icons-material/Business';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublicIcon from '@mui/icons-material/Public';
import { Divider, Grid, styled, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getCompany } from '../../../services/company';
import { getCompanyJobs, getUserJobs } from '../../../services/jobs';
import { UserContext } from '../../context/UserContext';
import { BackButton, TextButton } from '../../global/components/Button/Button';
import { BaseCard, BaseContainer, ProfilePicture } from '../../global/Core';
import JobCard from '../Dashboard/CandidateDashboard/SummaryJobCard';

import type { Company } from '../../../types/company';
import type { Job } from '../../../types/job';

const StyledBaseCard = styled(BaseCard)`
    margin-bottom: 2rem;
`;

const CardHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    min-height: 2rem;
`;

const CompanyHeader = styled('div')`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1.5rem;
`;

const StatSection = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

const SummarySection = styled(StatSection)`
    gap: 1.5rem;
    padding-bottom: 1rem;
`;

const CompanyStat = styled('div')`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

export default function BusinessDetails() {
    const [company, setCompany] = React.useState<Company>();
    const [jobs, setJobs] = React.useState<Job[]>([]);
    const theme = useTheme();
    const { user } = useContext(UserContext);
    const history = useHistory();
    const { companyID } = useParams<{ companyID: string }>();

    useEffect(() => {
        getCompany(parseInt(companyID, 10)).then((res: Company) => {
            setCompany(res);
        });
        if (user.levylRole === 'candidate' && user.id) {
            getUserJobs(user.id).then((res: Job[]) => {
                setJobs(
                    res.filter(
                        (job) => job.companyID === parseInt(companyID, 10)
                    )
                );
            });
        } else {
            getCompanyJobs(parseInt(companyID, 10)).then((res: Job[]) => {
                setJobs(res);
            });
        }
    }, [user.id, user.levylRole, companyID]);

    const companyStats = [
        {
            title: 'Location',
            value: company?.location,
            icon: LocationOnIcon
        },
        {
            title: 'Industry',
            value: company?.industry,
            icon: BusinessIcon
        },
        {
            title: 'Company Size',
            value: `${company?.size} employees`,
            icon: PeopleAltIcon
        }
    ];

    return (
        <BaseContainer>
            <StyledBaseCard variant='outlined'>
                <CardHeader>
                    <BackButton
                        onClick={() => {
                            history.goBack();
                        }}
                        startIcon={<KeyboardBackspaceIcon />}
                        color='primary'
                    />
                </CardHeader>
                <CompanyHeader>
                    <ProfilePicture
                        src={company?.logoURL}
                        size={2}
                        sx={{ mr: 1 }}
                    />
                    <Typography
                        variant={'h5'}
                        color={'info.main'}
                        fontWeight={700}
                    >
                        {company?.name}
                    </Typography>
                </CompanyHeader>
                <StatSection>
                    {companyStats.map((stat, index) => (
                        <CompanyStat key={index}>
                            <stat.icon
                                style={{ color: theme.palette.text.secondary }}
                            />
                            <Typography variant={'body2'} component={'span'}>
                                {`${stat.title}: `}
                                <Typography
                                    variant={'body2'}
                                    color={'text.secondary'}
                                    fontWeight={400}
                                    component={'span'}
                                >
                                    {stat.value}
                                </Typography>
                            </Typography>
                        </CompanyStat>
                    ))}
                    <CompanyStat>
                        <PublicIcon color={'primary'} />
                        <Typography variant={'body2'} component={'span'}>
                            <TextButton
                                variant='text'
                                link={company?.websiteURL}
                            >
                                {company?.websiteURL}
                            </TextButton>
                        </Typography>
                    </CompanyStat>
                </StatSection>
                <Divider variant='middle' flexItem />
                <SummarySection>
                    <Typography variant={'h6'}>{'Description'}</Typography>
                    <Typography
                        variant={'body2'}
                        dangerouslySetInnerHTML={{
                            __html: company?.description ?? ''
                        }}
                    ></Typography>
                </SummarySection>
            </StyledBaseCard>
            <Typography
                variant={'h6'}
                color={'text.secondary'}
                sx={{ mb: 5, mt: 8 }}
            >
                {'Current Company Postings'}
            </Typography>
            <Grid container spacing={3} sx={{ mb: 4 }}>
                {jobs.map((job, index) => (
                    <Grid item xs={12} key={index}>
                        <JobCard key={index} job={job} />
                    </Grid>
                ))}
            </Grid>
        </BaseContainer>
    );
}
