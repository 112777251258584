import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import DraftsIcon from '@mui/icons-material/Drafts';
import EventIcon from '@mui/icons-material/Event';
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from 'react';

import {
    acceptJobOffer,
    createJobOffer,
    recindJobOffer,
    rejectJobOffer
} from '../../../services/jobs';
import { removeNetworkConnection } from '../../../services/user';
import offerRoleImg from '../../../static/illustrations/offer-role.svg';
import Modal from '../../global/components/Modal/Modal';
import BaseContactCard from '../../global/layouts/ContactCard/ContactCard';
import PositiveModal from '../../global/layouts/PositiveModal/PositiveModal';

import FollowupInterview from './FollowupInterview';
import ProfileModal from './ProfileModal';

import type { JobOffer } from '../../../types/job';
import type { NetworkConnection } from '../../../types/user';

export default function ContactCard(props: {
    contact: NetworkConnection;
    uid: number;
    userID: string;
    role: 'candidate' | 'business';
    offer?: JobOffer;
}) {
    const [modal, setModal] = useState<
        | 'profile'
        | 'offer'
        | 'remove'
        | 'recind'
        | 'reject'
        | 'sendfollowup'
        | ''
    >('');

    const offerKey = props.offer ? 'offer' : 'contact';

    const actions = {
        viewProfile: {
            text: 'View Profile',
            onClick: () => setModal('profile'),
            icon: null
        },
        viewCompany: {
            text: 'View Company',
            to: `/secure/${props.userID}/company/${
                props.contact.interviews[props.contact.interviews.length - 1]
                    ?.company?.id
            }`,
            icon: <BusinessIcon fontSize={'small'} />
        },
        sendMessage: {
            text: 'Message',
            to: `/secure/${props.userID}/messaging/${props.contact.messageChatURL}`,
            icon: <SendIcon fontSize={'small'} />
        },
        bookInterview: {
            text: 'Interview',
            onClick: () => setModal('sendfollowup'),
            icon: <EventIcon fontSize={'small'} />
        },
        removeContact: {
            text: 'Remove Contact',
            onClick: () => setModal('remove'),
            icon: null
        },
        sendOffer: {
            text: 'Send Offer',
            onClick: () => setModal('offer'),
            icon: <DraftsIcon fontSize={'small'} />
        },
        acceptOffer: {
            text: 'Accept Offer',
            onClick: () => setModal('offer'),
            icon: <DraftsIcon fontSize={'small'} />
        },
        recindOffer: {
            text: 'Recind Offer',
            onClick: () => setModal('recind'),
            icon: <CloseIcon fontSize={'small'} />
        },
        rejectOffer: {
            text: 'Reject Offer',
            onClick: () => setModal('reject'),
            icon: null
        }
    } as {
        [key: string]: {
            text: string;
            onClick?: () => void;
            to?: string;
            icon: any;
        };
    };

    const modalActions = {
        remove: {
            title: 'Remove Contact?',
            content: 'Are you sure you want to remove this contact?',
            action: () => {
                const ids: [number, number] =
                    props.role === 'candidate'
                        ? [props.uid, props.contact.id]
                        : [props.contact.id, props.uid];
                removeNetworkConnection(...ids).then(() => {
                    setModal('');
                });
            }
        },
        recind: {
            title: 'Recind Offer?',
            content: 'Are you sure you want to recind this offer?',
            action: () => {
                recindJobOffer(props.offer?.id ?? 0).then(() => {
                    setModal('');
                });
            }
        },
        reject: {
            title: 'Reject Offer?',
            content: 'Are you sure you want to reject this offer?',
            action: () => {
                rejectJobOffer(props.offer?.id ?? 0).then(() => {
                    setModal('');
                });
            }
        }
    };

    const sendOrAccceptOffer = () => {
        if (props.role === 'candidate') {
            acceptJobOffer(props.offer?.id ?? 0).then(() => {
                setModal('');
            });
        } else {
            createJobOffer(
                props.contact.interviews[props.contact.interviews.length - 1]
                    .job.id,
                props.contact.id,
                props.uid
            ).then(() => {
                setModal('');
            });
        }
    };

    const actionMap = {
        candidate: {
            offer: {
                actions: [actions.acceptOffer, actions.sendMessage],
                more: [actions.rejectOffer]
            },
            contact: {
                actions: [actions.sendMessage, actions.viewCompany],
                more: [actions.removeContact]
            }
        },
        business: {
            offer: {
                actions: [actions.sendMessage, actions.recindOffer],
                more: [actions.viewProfile]
            },
            contact: {
                actions: [actions.sendOffer, actions.bookInterview],
                more: [actions.viewProfile, actions.removeContact]
            }
        }
    };

    return (
        <>
            <BaseContactCard
                name={`${props.contact.contact.name.firstName} ${props.contact.contact.name.lastName}`}
                jobTitle={`${props.contact.contact.name.currentJobTitle}`}
                imageURL={props.contact.contact.name.imageURL}
                actions={actionMap[props.role][offerKey].actions}
                drawerActions={actionMap[props.role][offerKey].more}
            />
            {modal === 'profile' && (
                <ProfileModal
                    open={modal === 'profile'}
                    handleClose={() => setModal('')}
                    connection={props.contact}
                />
            )}
            {modal === 'sendfollowup' && (
                <FollowupInterview
                    open={modal === 'sendfollowup'}
                    handleClose={() => setModal('')}
                    connection={props.contact}
                    uid={props.uid}
                />
            )}
            {modal === 'offer' && (
                <PositiveModal
                    open={modal === 'offer'}
                    onClose={() => setModal('')}
                    onClick={sendOrAccceptOffer}
                    title={'Offer role?'}
                    subtitle={`We are thrilled to confirm and all set to let ${props.contact.contact.name.firstName} know about the offer.`}
                    image={offerRoleImg}
                    primaryActionText={'Confirm'}
                />
            )}
            {modal &&
                modal !== 'profile' &&
                modal !== 'offer' &&
                modal !== 'sendfollowup' && (
                    <Modal
                        heading={modalActions[modal].title}
                        content={modalActions[modal].content}
                        open={!!modal}
                        onClose={() => setModal('')}
                        primaryCTAContent={'Submit'}
                        primaryCTAAction={modalActions[modal].action}
                    />
                )}
        </>
    );
}
