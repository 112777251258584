import { styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';

import Button from '../../components/Button/Button';

const StyledModal = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledModalContent = styled('div')`
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
    margin: 1rem;
    max-height: 90%;
    overflow-y: auto;
    max-width: 60rem;
`;

const StyledImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        margin-top: 2rem;
    }
`;

const StyledContent = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const TextContainer = styled('div')`
    margin-top: 2rem;
    text-align: center;
`;

const Title = styled(Typography)`
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
`;

const Subtitle = styled(Typography)`
    margin-bottom: 0.25rem;
`;

const ButtonList = styled('div')`
    display: flex;
    margin-top: 1rem;
`;

const ButtonContainer = styled('div')`
    margin-right: 0.5rem;
`;

const ButtonText = styled(Typography)`
    display: flex;
`;

export function ModalContent(props: {
    onClose: () => void;
    onClick: () => void;
    title: string;
    subtitle: string;
    image: string;
    primaryActionText: string;
}) {
    return (
        <StyledModalContent>
            <StyledContent>
                <StyledImage src={props.image} />
                <TextContainer>
                    <Title variant={'body2'} color={'text.secondary'}>
                        <strong>{props.title}</strong>
                    </Title>
                    <Subtitle variant={'body1'} color={'text.secondary'}>
                        {props.subtitle}
                    </Subtitle>
                </TextContainer>
                <ButtonList>
                    <ButtonContainer>
                        <Button variant='outlined' onClick={props.onClose}>
                            <ButtonText variant={'body2'} component='span'>
                                <b>{'Cancel'}</b>
                            </ButtonText>
                        </Button>
                    </ButtonContainer>
                    <ButtonContainer>
                        <Button variant='contained' onClick={props.onClick}>
                            <ButtonText variant={'body2'} component='span'>
                                <b>{props.primaryActionText}</b>
                            </ButtonText>
                        </Button>
                    </ButtonContainer>
                </ButtonList>
            </StyledContent>
        </StyledModalContent>
    );
}

export default function PositiveModal(props: {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
    title: string;
    subtitle: string;
    image: string;
    primaryActionText: string;
}) {
    return (
        <StyledModal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            disableScrollLock
        >
            <ModalContent
                onClose={props.onClose}
                onClick={props.onClick}
                title={props.title}
                subtitle={props.subtitle}
                image={props.image}
                primaryActionText={props.primaryActionText}
            />
        </StyledModal>
    );
}
