// todo verify props are complete and comprehensive

import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import IconButton from '@mui/material/IconButton';
import React, { Fragment } from 'react';

export default function StarRating(props: {
    value: number;
    setValue: (value: number) => void;
}) {
    const handleClick = (position: number) => {
        if (position - props.value === 0.5) {
            props.setValue(props.value - 0.5);
        } else if (position === props.value) {
            props.setValue(props.value - 0.5);
        } else {
            props.setValue(position);
        }
    };

    return (
        <Fragment>
            {[1, 2, 3, 4, 5].map((position) => (
                <IconButton
                    aria-label='delete'
                    size='large'
                    key={position}
                    onClick={() => handleClick(position)}
                    sx={{ p: 0.5 }}
                >
                    {(props.value - position >= 0.5 ||
                        props.value === position) && (
                        <StarIcon
                            fontSize='medium'
                            sx={{ color: 'text.primary' }}
                        />
                    )}
                    {position - props.value >= 1 && (
                        <StarOutlineIcon
                            fontSize='medium'
                            sx={{ color: 'text.primary' }}
                        />
                    )}
                    {position - props.value === 0.5 && (
                        <StarHalfIcon
                            fontSize='medium'
                            sx={{ color: 'text.primary' }}
                        />
                    )}
                </IconButton>
            ))}
        </Fragment>
    );
}
