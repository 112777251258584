import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Button, { TextButton } from '../../global/components/Button/Button';
import TextField from '../../global/components/TextField/TextField';

export const StyledButton = styled(Button)`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const StyledTextButton = styled(TextButton)`
    font-weight: 400;
`;

export const StyledFormSubmitButton = styled(Button)`
    margin-top: 1.5rem;
    margin-bottom: 1rem;
`;

export const FormSection = styled('form')(({ theme }) => ({
    width: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));

export const StyledCollapse = styled(Collapse)`
    width: 100%;
`;

export const StyledTextInput = styled(TextField)`
    width: 100%;
`;

export const ButtonWithTextContainer = styled(Typography)`
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: 400;
}}
`;
