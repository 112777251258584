import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import { getInterview } from '../../../services/interviews';
import Button from '../../global/components/Button/Button';

import InterviewCard from './InterviewCard';

import type { Interview } from '../../../types/interview';
import type { User } from '../../../types/user';

const Container = styled('div')`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const FeedbackContainer = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
`;

const FirstButton = styled(Button)`
    margin-top: 0rem;
`;

const StyledGrid = styled('div')`
    display: flex;
    gap: 1rem;
    width: 100%;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
`;

const StyledGridItem = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 20rem;
`;

export default function EndFeedback({
    interviewIDs,
    user
}: {
    interviewIDs: { id: number; candidatePresent: boolean; status: string }[];
    user: User;
}) {
    const [interviews, setInterviews] = useState<Interview[]>([]);

    useEffect(() => {
        const completedInterviews = interviewIDs
            .filter((i) => i.status === 'completed')
            .map(
                (interview) => getInterview(interview.id, user.id)
                // todo create and convert to getInterviews endpoint
            );
        Promise.all(completedInterviews).then((interviewSet) => {
            setInterviews(interviewSet);
        });
    }, [interviewIDs, user.id]);

    const removeInterview = (index: number) => {
        const newInterviews = [...interviews];
        newInterviews.splice(index, 1);
        setInterviews(newInterviews);
    };

    return (
        <Container>
            <FeedbackContainer>
                <Typography
                    variant={'h5'}
                    color={'text.primary'}
                    sx={{ mt: 2, textAlign: 'center' }}
                >
                    {'Provide Feedback for all Candidates'}
                </Typography>
                <StyledGrid>
                    {interviews.map(
                        (interview, index) =>
                            interview && (
                                <StyledGridItem key={index}>
                                    <InterviewCard
                                        interview={interview}
                                        removeInterview={() =>
                                            removeInterview(index)
                                        }
                                    />
                                </StyledGridItem>
                                // <InterviewCard
                                //     key={index}
                                //     interview={interview}
                                //     removeInterview={() =>
                                //         removeInterview(index)
                                //     }
                                // />
                            )
                    )}
                </StyledGrid>
                <FirstButton
                    variant='outlined'
                    to={`/secure/${user.userID}/${user.levylRole}/dashboard`}
                >
                    {'Exit Interview'}
                </FirstButton>
            </FeedbackContainer>
        </Container>
    );
}
