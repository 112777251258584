import { styled } from '@mui/material/styles';
import React from 'react';

import ChatStatus from './ChatStatus';

import type { InterviewStatus } from '../../../../types/enum';

const Background = styled('div')(
    ({ theme }) => `
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 1rem;
    background: ${theme.palette.grey[300]};
    padding: 1rem;
    gap: 0.5rem;
`
);

export default function BackgroundCard(props: {
    lineUp?: {
        id: number;
        candidatePresent: boolean;
        status: InterviewStatus;
    }[];
    endAllInterview: () => void;
    isCandidate: boolean;
    interviewIndex: number;
    children: any;
}) {
    return (
        <Background>
            <ChatStatus
                lineUp={props.lineUp}
                endAllInterview={props.endAllInterview}
                isCandidate={props.isCandidate}
                interviewIndex={props.interviewIndex}
            />
            {props.children}
        </Background>
    );
}
