import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const StyledBackground = styled('div')(({ theme }) => ({
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem 8rem',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between'
    },
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
    }
}));

const StyledContent = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: `${(5 / 12) * 100}%`,
    margin: '0',

    [theme.breakpoints.down('md')]: {
        flexBasis: `${(6 / 12) * 100}%`
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(32),
        flexBasis: '100%'
    }
}));

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: `${(4 / 12) * 100}%`,
    marginRight: '6rem',
    [theme.breakpoints.down('md')]: {
        maxWidth: `${(5 / 12) * 100}%`,
        marginRight: '0'
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: `${(10 / 12) * 100}%`,
        minWidth: theme.spacing(32),
        marginBottom: theme.spacing(6)
    }
}));

export interface WhatProps {
    heading: string;
    content: string;
    image: { file: { url: string } };
}

export default function WhatSection(props: WhatProps) {
    return (
        <StyledBackground>
            <StyledContainer disableGutters>
                <StyledImage src={props.image.file.url} />
                <StyledContent disableGutters>
                    <Typography
                        variant='h2'
                        color='text.secondary'
                        component='h2'
                        sx={{ fontSize: '2.5rem', fontWeight: 'medium' }}
                        my={2}
                    >
                        {props.heading}
                    </Typography>
                    <Typography color='text.primary' variant='body2'>
                        {props.content}
                    </Typography>
                </StyledContent>
            </StyledContainer>
        </StyledBackground>
    );
}
