import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useTimer as useTimerHook } from 'react-timer-hook';

export const isLessThanAnHourFromNow = (unixStartTime?: number) => {
    const start = moment.unix(unixStartTime || 0).local();
    const diff = start.diff(moment(), 'minutes');
    return diff < 60 && diff > 0;
};

export default function useTimer(
    unixStartTime?: number,
    onExpire?: () => void
) {
    const { seconds, minutes, restart } = useTimerHook({
        expiryTimestamp: moment().toDate(),
        onExpire
    });
    const restartTimer = useRef(restart);

    useEffect(() => {
        if (unixStartTime) {
            const newTime = moment
                .unix(unixStartTime || 0)
                .local()
                .toDate();
            restartTimer.current(newTime);
        }
    }, [unixStartTime]);

    return {
        seconds,
        minutes
    };
}
