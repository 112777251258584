import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ProfilePicture } from '../../../global/Core';

const Container = styled('div')`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const Name = styled(Typography)`
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.53);
`;

export default function ChatName(props: { name: string; src: string }) {
    return (
        <Container>
            <ProfilePicture src={props.src} size={3} />
            <Name fontWeight='700' color='white'>
                {props.name}
            </Name>
        </Container>
    );
}
