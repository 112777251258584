import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: 'https://2ece2ff20ab186202ec42e256f3a9807@o4506944743997440.ingest.us.sentry.io/4506944747995136',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/levyl-11cd1\.web\.app\//
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development
    // and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0
    // If you're not already sampling the entire session, change the sample rate to 100%
    // when sampling sessions where errors occur.
});

const tagManagerArgs = {
    gtmId: 'GTM-T2WZPD9'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
