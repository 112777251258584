import React, { Fragment } from 'react';

import Modal from '../../global/components/Modal/Modal';

const EndSingleModal = (props: {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}) => (
    <Modal
        heading={'Are you sure you want to leave the interview?'}
        content={
            "If you leave the interview you won't be able to continue it and save the candidate's personal information."
        }
        open={props.open}
        onClose={props.onClose}
        primaryCTAContent={'Yes, I want to leave'}
        primaryCTAAction={props.onClick}
    />
);

const EndAllModal = (props: {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}) => (
    <Modal
        heading={'Are you sure you want to end all interviews?'}
        content={
            "If you leave the interview you won't be able to continue it and save the candidate's personal information."
        }
        open={props.open}
        onClose={props.onClose}
        primaryCTAContent={'Yes, I want to leave'}
        primaryCTAAction={props.onClick}
    />
);

export default function ChatModalSection(props: {
    uid: string;
    modal: 'single' | 'all' | '';
    setModal: (modal: 'single' | 'all' | '') => void;
    onClick: () => Promise<void>;
}) {
    return (
        <Fragment>
            <EndSingleModal
                open={props.modal === 'single'}
                onClose={() => props.setModal('')}
                onClick={props.onClick}
            />
            <EndAllModal
                open={props.modal === 'all'}
                onClose={() => props.setModal('')}
                onClick={props.onClick}
            />
        </Fragment>
    );
}
