import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Channel from './Channel/Channel';
import ChannelList from './ChannelList/ChannelList';
import StyledChatContainer from './Messaging.styles';

const StyledChannel = styled('div')<{ chat: boolean }>(
    ({ chat }) => `
    max-width: 40rem;
    flex-grow: 1;
    display: flex;
    @media (max-width: 1200px) {
        display: ${chat ? 'flex' : 'none'};
    }
`
);

const Messaging = () => {
    const [channelURL, setChannelURL] = React.useState('');
    const { messageID } = useParams<{ messageID: string }>();
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <StyledChatContainer id={'levyl'}>
            {(width >= 1200 || (width < 1200 && !messageID)) && (
                <ChannelList
                    channelURL={channelURL}
                    setChannelURL={setChannelURL}
                />
            )}
            <StyledChannel chat={!!messageID}>
                <Channel channelURL={channelURL} />
            </StyledChannel>
        </StyledChatContainer>
    );
};

export default Messaging;
