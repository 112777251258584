import { createTheme } from '@mui/material/styles';

const theme = {
    palette: {
        primary: {
            main: '#7628C9',
            light: '#E9DFF3',
            dark: '#581E96'
        },
        secondary: {
            main: '#6C757D',
            light: '#ABB5BE',
            dark: '#54595E'
        },
        success: {
            main: '#118E4D',
            light: '#D6FCE8'
        },
        error: {
            main: '#AE1111',
            light: '#FCD6D6'
        },
        info: {
            main: '#975DD5'
        },
        text: {
            primary: '#747474',
            secondary: '#4B4B4B'
        },
        grey: {
            200: '#EBEBEB',
            300: '#FBFBFB',
            400: '#F2F2F2'
        }
    },
    typography: {
        fontFamily: '"Lato", sans-serif',
        fontWeightMedium: 600,
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 700
        },
        body1: {
            fontSize: '0.875rem'
        },
        body2: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5'
        }
    }
};

type CustomTheme = {
    [Key in keyof typeof theme]: typeof theme[Key];
};

declare module '@mui/material/styles/createTheme' {
    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

export default createTheme(theme);
