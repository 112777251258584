import { yupResolver } from '@hookform/resolvers/yup';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../../global/components/Button/Button';
import TextField from '../../../global/components/TextField/TextField';
import Channel from '../../Messaging/Channel/Channel';

import StarRating from './StarRating';

import type { Interview } from '../../../../types/interview';
import type { Control, FieldValues } from 'react-hook-form';

const StyledWrapper = styled('form')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 1rem;
`;

const StyledCardSection = styled(Card)<{ showChat: boolean }>(
    ({ showChat }) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: ${showChat ? '0rem' : '1rem'};
    justify-content: space-between;
    align-items: stretch;
    margin-top: 0.5rem;
    padding-top: ${showChat ? '0rem' : '1.5rem'};
    min-width: 20rem;
    max-width: 20rem;
`
);

const StarRow = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex-shrink: 1;
    gap: 0.5rem;
`;

const StyledButton = styled(Button)`
    margin-left: 1rem;
    margin-right: 1rem;
`;

const formSchema = yup.object().shape({
    generalFeedback: yup.string(),
    questions: yup.array().of(
        yup.object().shape({
            question: yup.string(),
            rating: yup.string()
        })
    )
});

export default function StarSection(props: {
    interview: Interview;
    isCandidate: boolean;
    chatURL: string;
    values: {
        generalFeedback: string;
        questions: { question: string; rating: string }[];
    };
    setValues: (values: {
        generalFeedback: string;
        questions: { question: string; rating: string }[];
    }) => void;
    onSubmit: (values: {
        generalFeedback: string;
        questions: { question: string; rating: string }[];
    }) => Promise<void>;
    questions: string[];
    feedbackTitle: string;
}) {
    const [showChat, setShowChat] = useState(true);
    const { setValues } = props;

    const {
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: props.values
    });

    const questionValues = watch('questions');
    const generalFeedbackValue = watch('generalFeedback');

    const updateValue = (index: number, value: number) => {
        setValue(`questions.${index}.rating`, value.toString());
    };

    useEffect(() => {
        setValues({
            generalFeedback: generalFeedbackValue,
            questions: questionValues
        });
    }, [generalFeedbackValue, questionValues, setValues]);

    return (
        <StyledWrapper
            style={{ flexGrow: 0, alignItems: 'center' }}
            onSubmit={handleSubmit(props.onSubmit)}
        >
            <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
                {!props.isCandidate && (
                    <Chip
                        color='primary'
                        label='Evaluation'
                        variant={!showChat ? 'filled' : 'outlined'}
                        sx={{ fontWeight: '600' }}
                        onClick={() => setShowChat(false)}
                    />
                )}
                <Chip
                    color='primary'
                    label='Chat'
                    variant={showChat ? 'filled' : 'outlined'}
                    sx={{ fontWeight: '600' }}
                    onClick={() => setShowChat(true)}
                />
            </Stack>
            <StyledCardSection showChat={showChat}>
                {!showChat ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center'
                            }}
                        >
                            {props.questions.map((question, index) => (
                                <Fragment key={question}>
                                    <Typography color={'text.primary'}>
                                        {question}
                                    </Typography>
                                    <StarRow sx={{ mb: 1 }}>
                                        <StarRating
                                            value={parseFloat(
                                                questionValues[index].rating
                                            )}
                                            setValue={(value) => {
                                                updateValue(index, value);
                                            }}
                                        />
                                    </StarRow>
                                </Fragment>
                            ))}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center'
                            }}
                        >
                            <TextField
                                id='generalFeedback'
                                label={props.feedbackTitle}
                                color='primary'
                                error={!!errors.generalFeedback}
                                errorText={errors.generalFeedback?.message}
                                placeholder={''}
                                control={
                                    control as unknown as Control<FieldValues>
                                }
                                outlined
                                rows={6}
                                sx={{ width: '100%', mb: 2 }}
                            />
                            <StyledButton
                                sx={{ mx: 2 }}
                                variant='contained'
                                submit
                            >
                                {'Save Draft'}
                            </StyledButton>
                        </div>
                    </>
                ) : (
                    <Channel channelURL={props.chatURL} />
                )}
            </StyledCardSection>
        </StyledWrapper>
    );
}
