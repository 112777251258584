import {
    useDevCycleClient,
    useIsDevCycleInitialized,
    withDevCycleProvider
} from '@devcycle/react-client-sdk';
import React, {
    ComponentType,
    createContext,
    useContext,
    useEffect,
    useState
} from 'react';

import { UserContext } from './UserContext';

import type { ReactNode } from 'react';

export type DevCycleContextType = {
    isWaitlist: boolean;
};

export const DevCycleContext = createContext<DevCycleContextType>({
    isWaitlist: false
});

const DevCycleProviderSimple = ({ children }: { children?: ReactNode }) => {
    const devCycleReady = useIsDevCycleInitialized();
    const devCycleClient = useDevCycleClient();
    const { user } = useContext(UserContext);
    const [isWaitlist, setIsWaitlist] = useState(true);

    useEffect(() => {
        if (user.userID) {
            devCycleClient
                .identifyUser({ user_id: user.userID })
                .then((variables) => {
                    setIsWaitlist(!!variables.waitlist.value);
                });
        }
    }, [devCycleClient, user.userID]);

    return (
        (devCycleReady && (
            <DevCycleContext.Provider value={{ isWaitlist }}>
                {children}
            </DevCycleContext.Provider>
        )) ||
        null
    );
};

export const DevCycleProvider = withDevCycleProvider({
    sdkKey:
        window.location.hostname === 'localhost'
            ? 'dvc_client_599c0d57_c41e_46b7_a230_8bd67d0c3da2_6b8f604'
            : 'dvc_client_be8bedfd_0d33_4e44_9330_66eb93a20c55_b7151d6'
})(DevCycleProviderSimple);

export const withDevCycleContext = <P extends object>(
    Component: ComponentType<P & { devCycleContext: DevCycleContextType }>
) => {
    const parent = (props: any) => (
        <DevCycleContext.Consumer>
            {(devCycleContext) => (
                <Component {...props} devCycleContext={devCycleContext} />
            )}
        </DevCycleContext.Consumer>
    );
    const componentName =
        Component.displayName || Component.name || 'Component';
    parent.displayName = `withDevCycleContext(${componentName})`;
    return parent;
};
