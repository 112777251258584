import OneGraySVG from '../../../../static/icons/onegray.svg';
import OnePurpleSVG from '../../../../static/icons/onepurple.svg';
import ThreeGraySVG from '../../../../static/icons/threegray.svg';
import ThreePurpleSVG from '../../../../static/icons/threepurple.svg';
import TwoGraySVG from '../../../../static/icons/twogray.svg';
import TwoPurpleSVG from '../../../../static/icons/twopurple.svg';
import loginImage from '../../../../static/illustrations/login.svg';

const content = {
    steps: {
        common: {
            one: {
                icon: { gray: OneGraySVG, purple: OnePurpleSVG }
            },
            two: {
                icon: { gray: TwoGraySVG, purple: TwoPurpleSVG }
            },
            three: {
                icon: { gray: ThreeGraySVG, purple: ThreePurpleSVG }
            }
        },
        candidate: {
            one: {
                text: 'Create your profile'
            },
            two: {
                text: 'Your ideal role'
            },
            three: {
                text: 'Your background'
            }
        },
        business: {
            one: {
                text: 'Create your profile'
            },
            two: {
                text: 'Create your page'
            },
            three: {
                text: 'Choose a plan'
            }
        },
        job: {
            one: {
                text: 'Job details'
            },
            two: {
                text: 'Compensation'
            },
            three: {
                text: 'Availability'
            }
        }
    },
    modal: {
        heading: 'Exit profile creation',
        content: 'Are you sure you want to exit? All progress will be lost.'
    },
    image: {
        src: loginImage,
        alt: ''
    }
};

export default content;
