/* eslint-disable react/jsx-no-literals */

import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';

import logo from '../../../static/logos/name.svg';
import FooterSection from '../FooterSection';
import NavBarSection from '../NavBarSection';

const StyledBackground = styled('div')(({ theme }) => ({
    background: 'linear-gradient(180deg, #f7f7ff 47.69%, #f9f9f9 100%)',
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem 8rem',

    [theme.breakpoints.down('lg')]: {
        padding: '4rem 6rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem'
    }
}));

const StyledContainer = styled(Container)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0'
}));

export interface ContainerSectionProps {
    heading: string;
}

const ContainerSection = (props: ContainerSectionProps) => (
    <StyledBackground>
        <StyledContainer disableGutters>
            <Typography
                variant='h1'
                color='text.secondary'
                mb={4}
                sx={{ textAlign: 'center' }}
            >
                {props.heading}
            </Typography>
            <Typography color='text.primary' variant='body2' mb={4}>
                <p>
                    <strong>
                        <span>Services Agreement</span>
                    </strong>
                </p>
                <p>
                    <span>Last Modified: June 27, 2022</span>
                </p>
                <p>
                    <span>This Services Agreement (this &quot;</span>
                    <strong>
                        <span>Agreement</span>
                    </strong>
                    <span>
                        &quot;) is a binding contract between you (&quot;
                    </span>
                    <strong>
                        <span>Customer</span>
                    </strong>
                    <span>&quot;, &quot;</span>
                    <strong>
                        <span>you</span>
                    </strong>
                    <span>&quot;, or &quot;</span>
                    <strong>
                        <span>your</span>
                    </strong>
                    <span>&quot;) and Levyl Inc. (&quot;</span>
                    <strong>
                        <span>Provider</span>
                    </strong>
                    <span>&quot;, &quot;</span>
                    <strong>
                        <span>we</span>
                    </strong>
                    <span>&quot;, or &quot;</span>
                    <strong>
                        <span>us</span>
                    </strong>
                    <span>
                        &quot;). This Agreement governs your access to and use
                        of the Services.&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE &quot;I
                        ACCEPT&quot; BUTTON BELOW OR BY ACCESSING OR USING THE
                        SERVICES (the &quot;
                    </span>
                    <strong>
                        <span>Effective Date</span>
                    </strong>
                    <span>
                        &quot;). BY CLICKING ON THE &quot;I ACCEPT&quot; BUTTON
                        BELOW OR BY ACCESSING OR USING THE SERVICES YOU (A)
                        ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS
                        AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE THE
                        RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT
                        AND, IF ENTERING INTO THIS AGREEMENT FOR AN
                        ORGANIZATION, THAT YOU HAVE THE LEGAL AUTHORITY TO BIND
                        THAT ORGANIZATION; AND (C) ACCEPT THIS AGREEMENT AND
                        AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.&nbsp;
                    </span>
                </p>
                <p>
                    <span>
                        IF YOU DO NOT AGREE TO THESE TERMS, PLEASE SELECT THE
                        &quot;I DECLINE&quot; BUTTON BELOW. IF YOU DO NOT ACCEPT
                        THESE TERMS, YOU MAY NOT ACCESS OR USE THE
                        SERVICES.&nbsp;
                    </span>
                </p>
                <ol>
                    <li>
                        <p>
                            <u>
                                <span>Definitions</span>
                            </u>
                            <span>.&nbsp;</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Aggregated Statistics</span>
                                    </strong>
                                    <span>
                                        &quot; has the meaning set forth
                                        in&nbsp;
                                    </span>
                                    <a href='https://docs.google.com/document/d/1J39_FPdAfToyr0tjc1pzHJPpZmH4TUqx/edit#bookmark=id.35nkun2'>
                                        <span>Section 2(e)</span>
                                    </a>
                                    <span>.&nbsp;</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Authorized User</span>
                                    </strong>
                                    <span>
                                        &quot; means Customer and
                                        Customer&apos;s employees, consultants,
                                        contractors, and agents (i) who are
                                        authorized by Customer to access and use
                                        the Services under the rights granted to
                                        Customer pursuant to this Agreement and
                                        (ii) for whom access to the Services has
                                        been purchased hereunder.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Services</span>
                                    </strong>
                                    <span>
                                        &quot; means the services provided by
                                        Provider under this Agreement that are
                                        detailed on Provider&apos;s website
                                        available at www.levyl.app and reflected
                                        in the Customer&apos;s order.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Customer Data</span>
                                    </strong>
                                    <span>
                                        &quot; means, other than Aggregated
                                        Statistics, information, data, and other
                                        content, in any form or medium, that is
                                        submitted, posted, or otherwise
                                        transmitted by or on behalf of Customer
                                        or any other Authorized User through the
                                        Services.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Documentation</span>
                                    </strong>
                                    <span>
                                        &quot; means Provider&apos;s end user
                                        documentation relating to the Services
                                        available at www.levyl.app.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Provider IP</span>
                                    </strong>
                                    <span>
                                        &quot; means the Services, the
                                        Documentation, and all intellectual
                                        property provided to Customer or any
                                        other Authorized User in connection with
                                        the foregoing. For the avoidance of
                                        doubt, Provider IP includes Aggregated
                                        Statistics and any information, data, or
                                        other content derived from
                                        Provider&apos;s monitoring of
                                        Customer&apos;s access to or use of the
                                        Services, but does not include Customer
                                        Data.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>&quot;</span>
                                    <strong>
                                        <span>Third-Party Products</span>
                                    </strong>
                                    <span>
                                        &quot; means any products, content,
                                        services, information, websites, or
                                        other materials that are owned by third
                                        parties and are incorporated into or
                                        accessible through the Services.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Access and Use</span>
                            </u>
                            <span>.</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <u>
                                        <span>Provision of Access</span>
                                    </u>
                                    <span>
                                        . Subject to and conditioned on your
                                        payment of Fees and compliance with all
                                        the terms and conditions of this
                                        Agreement, Provider hereby grants you a
                                        revocable, non-exclusive,
                                        non-transferable, non-sublicensable,
                                        limited right to access and use the
                                        Services during the Term solely for your
                                        internal business operations by
                                        Authorized Users in accordance with the
                                        terms and conditions herein. Provider
                                        shall provide you the necessary
                                        passwords and access credentials to
                                        allow you to access the Services.&nbsp;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Documentation Licence</span>
                                    </u>
                                    <span>
                                        . Subject to the terms and conditions
                                        contained in this Agreement, Provider
                                        hereby grants you a non-exclusive,
                                        non-sublicensable, non-transferable
                                        license for Authorized Users to use the
                                        Documentation during the Term solely for
                                        your internal business purposes in
                                        connection with use of the Services.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Downloadable Software</span>
                                    </u>
                                    <span>
                                        . Use of the Services may require or
                                        include use of downloadable software.
                                        Provider grants you a non-transferable,
                                        non-exclusive, non-assignable, limited
                                        right for Authorized Users to use
                                        downloadable software we provide as part
                                        of the Services. Any Third-Party
                                        Products that consist of downloadable
                                        software are subject to the terms
                                        of&nbsp;
                                    </span>
                                    <a href='https://docs.google.com/document/d/1J39_FPdAfToyr0tjc1pzHJPpZmH4TUqx/edit#bookmark=id.2xcytpi'>
                                        <span>Section 3(e)</span>
                                    </a>
                                    <span>.</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Use Restrictions</span>
                                    </u>
                                    <span>
                                        . You shall not, and shall not permit
                                        any Authorized Users to, use the
                                        Services, any software component of the
                                        Services, or Documentation for any
                                        purposes beyond the scope of the access
                                        granted in this Agreement. You shall not
                                        at any time, directly or indirectly, and
                                        shall not permit any Authorized Users
                                        to: (i) copy, modify, or create
                                        derivative works of the Services, any
                                        software component of the Services, or
                                        Documentation, in whole or in part; (ii)
                                        rent, lease, lend, sell, license,
                                        sublicense, assign, distribute, publish,
                                        transfer, or otherwise make available
                                        the Services or Documentation except as
                                        expressly permitted under this
                                        Agreement; (iii) reverse engineer,
                                        disassemble, decompile, decode, adapt,
                                        or otherwise attempt to derive or gain
                                        access to any software component of the
                                        Services, in whole or in part; (iv)
                                        remove any proprietary notices from the
                                        Services or Documentation; or (v) use
                                        the Services or Documentation in any
                                        manner or for any purpose that
                                        infringes, misappropriates, or otherwise
                                        violates any intellectual property right
                                        or other right of any person, or that
                                        violates any applicable law, regulation,
                                        or rule.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Aggregated Statistics</span>
                                    </u>
                                    <span>
                                        . Notwithstanding anything to the
                                        contrary in this Agreement, Provider may
                                        monitor Customer&apos;s use of the
                                        Services and collect and compile data
                                        and information related to
                                        Customer&apos;s use of the Services to
                                        be used by Provider in an aggregated and
                                        anonymized manner, including to compile
                                        statistical and performance information
                                        related to the provision and operation
                                        of the Services (&quot;
                                    </span>
                                    <strong>
                                        <span>Aggregated Statistics</span>
                                    </strong>
                                    <span>
                                        &quot;). As between Provider and you,
                                        all right, title, and interest in
                                        Aggregated Statistics, and all
                                        intellectual property rights therein,
                                        belong to and are retained solely by
                                        Provider. You acknowledge that Provider
                                        may compile Aggregated Statistics based
                                        on Customer Data input into the
                                        Services. You agree that Provider may
                                        (i) make Aggregated Statistics publicly
                                        available in compliance with applicable
                                        law, and (ii) use Aggregated Statistics
                                        to the extent and in the manner
                                        permitted under applicable law; provided
                                        that such Aggregated Statistics do not
                                        identify Customer or Customer&apos;s
                                        Confidential Information.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Reservation of Rights</span>
                                    </u>
                                    <span>
                                        . Provider reserves all rights not
                                        expressly granted to Customer in this
                                        Agreement. Except for the limited rights
                                        and licences expressly granted under
                                        this Agreement, nothing in this
                                        Agreement grants, by implication,
                                        waiver, estoppel, or otherwise, to
                                        Customer or any third party any
                                        intellectual property rights or other
                                        right, title, or interest in or to the
                                        Provider IP.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Suspension</span>
                                    </u>
                                    <span>
                                        . Notwithstanding anything to the
                                        contrary in this Agreement, Provider may
                                        temporarily suspend Customer&apos;s and
                                        any other Authorized User&apos;s access
                                        to any portion or all of the Services
                                        if: (i) Provider reasonably determines
                                        that (A) there is a threat or attack on
                                        any of the Provider IP; (B)
                                        Customer&apos;s or any other Authorized
                                        User&apos;s use of the Provider IP
                                        disrupts or poses a security risk to the
                                        Provider IP or to any other customer or
                                        vendor of Provider; (C) Customer or any
                                        other Authorized User is using the
                                        Provider IP for fraudulent or illegal
                                        activities; (D) subject to applicable
                                        law, Customer has ceased to continue its
                                        business in the ordinary course, made an
                                        assignment for the benefit of creditors
                                        or similar disposition of its assets, or
                                        become the subject of any bankruptcy,
                                        reorganization, liquidation,
                                        dissolution, or similar proceeding; or
                                        (E) Provider&apos;s provision of the
                                        Services to Customer or any other
                                        Authorized User is prohibited by
                                        applicable law; (ii) any vendor of
                                        Provider has suspended or terminated
                                        Provider&apos;s access to or use of any
                                        third-party services or products
                                        required to enable Customer to access
                                        the Services; or (iii) in accordance
                                        with 5 (any such suspension described in
                                        subclause (i), (ii), or (iii), a &quot;
                                    </span>
                                    <strong>
                                        <span>Service Suspension</span>
                                    </strong>
                                    <span>
                                        &quot;). Provider shall use commercially
                                        reasonable efforts to provide written
                                        notice of any Service Suspension to
                                        Customer and to provide updates
                                        regarding resumption of access to the
                                        Services following any Service
                                        Suspension. Provider shall use
                                        commercially reasonable efforts to
                                        resume providing access to the Services
                                        as soon as reasonably possible after the
                                        event giving rise to the Services
                                        Suspension is cured. Provider will have
                                        no liability for any damage,
                                        liabilities, losses (including any loss
                                        of data or profits), or any other
                                        consequences that Customer or any other
                                        Authorized User may incur as a result of
                                        a Service Suspension.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Customer Responsibilities</span>
                            </u>
                            <span>.</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <u>
                                        <span>Acceptable Use</span>
                                    </u>
                                    <span>
                                        . The Services may not be used for
                                        unlawful, fraudulent, offensive, or
                                        obscene activity, as further described
                                        and set forth in Provider&apos;s terms
                                        and conditions of use located at
                                        levyl.app/transparency/terms-and-conditions,
                                        as may be amended from time to time,
                                        which is incorporated herein by
                                        reference. You will comply with all
                                        terms and conditions of this Agreement,
                                        all applicable laws, rules, and
                                        regulations, and all guidelines,
                                        standards, and requirements that may be
                                        posted on&nbsp;
                                    </span>
                                    <a href='http://www.levyl.app'>
                                        <span>www.levyl.app</span>
                                    </a>
                                    <span>
                                        &nbsp;from time to time, including the
                                        terms and conditions of use.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Account Use</span>
                                    </u>
                                    <span>
                                        . You are responsible and liable for all
                                        uses of the Services and Documentation
                                        resulting from access provided by you,
                                        directly or indirectly, whether such
                                        access or use is permitted by or in
                                        violation of this Agreement. Without
                                        limiting the generality of the
                                        foregoing, you are responsible for all
                                        acts and omissions of Authorized Users,
                                        and any act or omission by an Authorized
                                        User that would constitute a breach of
                                        this Agreement if taken by you will be
                                        deemed a breach of this Agreement by
                                        you. You shall use reasonable efforts to
                                        make all Authorized Users aware of this
                                        Agreement&apos;s provisions as
                                        applicable to such Authorized
                                        User&apos;s use of the Services, and
                                        shall cause Authorized Users to comply
                                        with such provisions.&nbsp;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Customer Data</span>
                                    </u>
                                    <span>
                                        . You hereby grant to Provider a
                                        non-exclusive, royalty-free, worldwide
                                        licence to reproduce, distribute, and
                                        otherwise use and display the Customer
                                        Data and perform all acts with respect
                                        to the Customer Data as may be necessary
                                        for Provider to provide the Services to
                                        you, and a non-exclusive, perpetual,
                                        irrevocable, royalty-free, worldwide
                                        licence to reproduce, distribute,
                                        modify, and otherwise use and display
                                        Customer Data incorporated within the
                                        Aggregated Statistics. You will ensure
                                        that Customer Data and any Authorized
                                        User&apos;s use of Customer Data will
                                        not violate any policy or terms
                                        referenced in or incorporated into this
                                        Agreement or any applicable law. You are
                                        solely responsible for the development,
                                        content, operation, maintenance, and use
                                        of Customer Data.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>
                                            Passwords and Access Credentials
                                        </span>
                                    </u>
                                    <span>
                                        . You are responsible for keeping your
                                        passwords and access credentials
                                        associated with the Services
                                        confidential. You will not sell or
                                        transfer them to any other person or
                                        entity. You will promptly notify us
                                        about any unauthorized access to your
                                        passwords or access credentials.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Third-Party Products</span>
                                    </u>
                                    <span>
                                        . The Services may permit access to
                                        Third-Party Products. For purposes of
                                        this Agreement, such Third-Party
                                        Products are subject to their own terms
                                        and conditions presented to you for
                                        acceptance within the Services by
                                        website link or otherwise. If you do not
                                        agree to abide by the applicable terms
                                        for any such Third-Party Products, then
                                        you should not install, access, or use
                                        such Third-Party Products.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Service Levels and Support</span>
                            </u>
                            <span>.</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <u>
                                        <span>Service Levels</span>
                                    </u>
                                    <span>
                                        . Subject to the terms and conditions of
                                        this Agreement, Provider shall use
                                        commercially reasonable efforts to make
                                        the Services available (&quot;
                                    </span>
                                    <strong>
                                        <span>Service Levels</span>
                                    </strong>
                                    <span>&quot;).</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Support</span>
                                    </u>
                                    <span>
                                        . This Agreement does not entitle
                                        Customer to any support for the
                                        Services.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Fees and Payment</span>
                            </u>
                            <span>
                                . Customer shall pay Provider the fees as
                                described on www.levyl.app &nbsp;(&quot;
                            </span>
                            <strong>
                                <span>Fees</span>
                            </strong>
                            <span>
                                &quot;) within thirty (30) days from the invoice
                                date without set-off or deduction. Customer
                                shall make all payments hereunder in Canadian
                                dollars on or before the due date. If Customer
                                fails to make any payment when due, without
                                limiting Provider&apos;s other rights and
                                remedies: (i) Provider may charge interest on
                                the past due amount at the rate of two percent
                                (2%) per annum or, if lower, the highest rate
                                permitted under applicable law; (ii) Customer
                                shall reimburse Provider for all reasonable
                                costs incurred by Provider in collecting any
                                late payments or interest, including legal fees,
                                court costs, and collection agency fees; and
                                (iii) if such failure continues for ten (10)
                                days or more, Provider may suspend, in
                                accordance with&nbsp;
                            </span>
                            <a href='https://docs.google.com/document/d/1J39_FPdAfToyr0tjc1pzHJPpZmH4TUqx/edit#bookmark=id.44sinio'>
                                <span>Section 2(g)</span>
                            </a>
                            <span>
                                , Customer&apos;s and all other Authorized
                                Users&apos; access to any portion or all of the
                                Services until such amounts are paid in
                                full.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Taxes</span>
                            </u>
                            <span>
                                . All Fees and other amounts payable by Customer
                                under this Agreement are exclusive of taxes and
                                similar assessments. Customer is responsible for
                                all harmonized sales tax (HST), provincial sales
                                tax (PST), goods and services tax (GST), value
                                added tax, use and excise taxes, and any other
                                similar taxes, duties, and charges of any kind
                                imposed by any federal, provincial, territorial,
                                or local governmental entity on any amounts
                                payable by Customer hereunder, other than any
                                taxes imposed on Provider&apos;s income.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Confidential Information</span>
                            </u>
                            <span>
                                . From time to time during the Term, Provider,
                                and Customer may disclose or make available to
                                the other party information about its business
                                affairs, products, confidential intellectual
                                property, trade secrets, third-party
                                confidential information, and other sensitive or
                                proprietary information, whether orally or in
                                written, electronic, or other form or media, and
                                whether or not marked, designated, or otherwise
                                identified as &quot;confidential&quot; at the
                                time of disclosure (collectively, &quot;
                            </span>
                            <strong>
                                <span>Confidential Information</span>
                            </strong>
                            <span>
                                &quot;). Confidential Information does not
                                include information that, at the time of
                                disclosure is: (a) in the public domain; (b)
                                known to the receiving party; (c) rightfully
                                obtained by the receiving party on a
                                non-confidential basis from a third party; or
                                (d) independently developed by the receiving
                                party. The receiving party shall not disclose
                                the disclosing party&apos;s Confidential
                                Information to any person or entity, except to
                                the receiving party&apos;s employees, agents, or
                                subcontractors who have a need to know the
                                Confidential Information for the receiving party
                                to exercise its rights or perform its
                                obligations hereunder and who are required to
                                protect the Confidential Information in a manner
                                no less stringent than required under this
                                Agreement. Notwithstanding the foregoing, each
                                party may disclose Confidential Information to
                                the limited extent required (i) to comply with
                                the order of a court or other governmental body,
                                or as otherwise necessary to comply with
                                applicable law, provided that the party making
                                the disclosure pursuant to the order shall first
                                have given written notice to the other party and
                                made a reasonable effort to obtain a protective
                                order; or (ii) to establish a party&apos;s
                                rights under this Agreement, including to make
                                required court filings. Each party&apos;s
                                obligations of non-disclosure with regard to
                                Confidential Information are effective as of the
                                date such Confidential Information is first
                                disclosed to the receiving party and will expire
                                two (2) years thereafter;&nbsp;
                            </span>
                            <em>
                                <span>provided, however</span>
                            </em>
                            <span>
                                , with respect to any Confidential Information
                                that constitutes a trade secret (as determined
                                under applicable law), such obligations of
                                non-disclosure will survive the termination or
                                expiration of this Agreement for as long as such
                                Confidential Information remains subject to
                                trade secret protection under applicable
                                law.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Privacy Policy</span>
                            </u>
                            <span>
                                . Provider complies with its privacy policy
                                available at
                                levyl.app/transparency/privacy-policy (&quot;
                            </span>
                            <strong>
                                <span>Privacy Policy</span>
                            </strong>
                            <span>
                                &quot;), in providing the Services. The Privacy
                                Policy is subject to change as described
                                therein. By accessing, using, and providing
                                information to or through the Services, you
                                acknowledge that you have reviewed and accepted
                                our Privacy Policy, and you consent to all
                                actions taken by us with respect to your
                                information in compliance with the then-current
                                version of our Privacy Policy.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>
                                    Intellectual Property Ownership; Feedback
                                </span>
                            </u>
                            <span>
                                . As between you and us, (a) we own all right,
                                title, and interest, including all intellectual
                                property rights, in and to the Services and (b)
                                you own all right, title, and interest,
                                including all intellectual property rights, in
                                and to Customer Data. If you or any of your
                                employees, contractors, or agents sends or
                                transmits any communications or materials to us
                                by mail, email, telephone, or otherwise,
                                suggesting or recommending changes to the
                                Services, including, without limitation, new
                                features or functionality relating thereto, or
                                any comments, questions, suggestions, or the
                                like (&quot;
                            </span>
                            <strong>
                                <span>Feedback</span>
                            </strong>
                            <span>
                                &quot;), we are free to use such Feedback
                                irrespective of any other obligation or
                                limitation between you and us governing such
                                Feedback. All such Feedback is and will be
                                treated as non-confidential. You hereby assign
                                to us on your behalf, and shall cause your
                                employees, contractors, and agents to assign,
                                all right, title, and interest in, and we are
                                free to use, without any attribution or
                                compensation to you or any third party, any
                                ideas, know-how, concepts, techniques, or other
                                intellectual property rights contained in the
                                Feedback, for any purpose whatsoever, although
                                we are not required to use any Feedback.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>
                                    Limited Warranty and Warranty Disclaimer
                                </span>
                            </u>
                            <span>.&nbsp;</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <span>
                                        Provider warrants that it provides
                                        Services using a commercially reasonable
                                        level of care and skill. THE FOREGOING
                                        WARRANTY DOES NOT APPLY, AND PROVIDER
                                        STRICTLY DISCLAIMS ALL WARRANTIES, WITH
                                        RESPECT TO ANY THIRD-PARTY PRODUCTS.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Customer Warranty</span>
                                    </u>
                                    <span>
                                        . You warrant that you own all right,
                                        title, and interest, including all
                                        intellectual property rights, in and to
                                        Customer Data and that both the Customer
                                        Data and your use of the Services are in
                                        compliance with the terms and conditions
                                        of use.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <span>
                                        EXCEPT FOR THE LIMITED WARRANTY SET
                                        FORTH IN&nbsp;
                                    </span>
                                    <a href='https://docs.google.com/document/d/1J39_FPdAfToyr0tjc1pzHJPpZmH4TUqx/edit#bookmark=id.206ipza'>
                                        <span>SECTION 10(a)</span>
                                    </a>
                                    <span>
                                        , THE SERVICES ARE PROVIDED &quot;AS
                                        IS&quot; AND PROVIDER SPECIFICALLY
                                        DISCLAIMS ALL WARRANTIES AND CONDITIONS,
                                        WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                                        OTHERWISE. PROVIDER SPECIFICALLY
                                        DISCLAIMS ALL IMPLIED WARRANTIES AND
                                        CONDITIONS OF MERCHANTABILITY, FITNESS
                                        FOR A PARTICULAR PURPOSE, TITLE, AND
                                        NON-INFRINGEMENT, AND ALL WARRANTIES
                                        ARISING FROM COURSE OF DEALING, USAGE,
                                        OR TRADE PRACTICE. PROVIDER MAKES NO
                                        WARRANTY OF ANY KIND THAT THE SERVICES,
                                        OR ANY PRODUCTS OR RESULTS OF THE USE
                                        THEREOF, WILL MEET YOUR OR ANY OTHER
                                        PERSON&apos;S OR ENTITY&apos;S
                                        REQUIREMENTS, OPERATE WITHOUT
                                        INTERRUPTION, ACHIEVE ANY INTENDED
                                        RESULT, BE COMPATIBLE OR WORK WITH ANY
                                        OF YOUR OR ANY THIRD PARTY&apos;S
                                        SOFTWARE, SYSTEM, OR OTHER SERVICES, OR
                                        BE SECURE, ACCURATE, COMPLETE, FREE OF
                                        HARMFUL CODE, OR ERROR-FREE, OR THAT ANY
                                        ERRORS OR DEFECTS CAN OR WILL BE
                                        CORRECTED.&nbsp;
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Indemnification</span>
                            </u>
                            <span>.&nbsp;</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <u>
                                        <span>Provider Indemnification</span>
                                    </u>
                                    <span>.&nbsp;</span>
                                </p>
                                <ol>
                                    <li>
                                        <p>
                                            <span>
                                                Provider shall indemnify,
                                                defend, and hold harmless
                                                Customer from and against any
                                                and all losses, damages,
                                                liabilities, deficiencies,
                                                claims, actions, judgments,
                                                settlements, interest, awards,
                                                penalties, fines, costs, or
                                                expenses of whatever kind,
                                                including reasonable legal fees
                                                (&quot;
                                            </span>
                                            <strong>
                                                <span>Losses</span>
                                            </strong>
                                            <span>
                                                &quot;), incurred by Customer
                                                resulting from any third-party
                                                claim, suit, action, or
                                                proceeding (&quot;
                                            </span>
                                            <strong>
                                                <span>Third-Party Claim</span>
                                            </strong>
                                            <span>
                                                &quot;) that the Services, or
                                                any use of the Services in
                                                accordance with this Agreement,
                                                infringes or misappropriates
                                                such third party&apos;s Canadian
                                                intellectual property
                                                rights/Canadian patents,
                                                copyrights, or trade
                                                secrets,&nbsp;
                                            </span>
                                            <em>
                                                <span>provided that</span>
                                            </em>
                                            <span>
                                                &nbsp;Customer promptly notifies
                                                Provider in writing of the
                                                Third-Party Claim, cooperates
                                                with Provider, and allows
                                                Provider sole authority to
                                                control the defence and
                                                settlement of such Third-Party
                                                Claim.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>
                                                If such a Third-Party Claim is
                                                made or either party reasonably
                                                anticipates such a Third-Party
                                                Claim will be made, Customer
                                                agrees to permit Provider, at
                                                Provider&apos;s sole discretion,
                                                to (A) modify or replace the
                                                Services, or component or part
                                                thereof, to make it
                                                non-infringing, or (B) obtain
                                                the right for Customer to
                                                continue use. If Provider
                                                determines that neither
                                                alternative is reasonably
                                                available, Provider may
                                                terminate this Agreement, in its
                                                entirety or with respect to the
                                                affected component or part,
                                                effective immediately on written
                                                notice to Customer. This Section
                                                11(a)(ii) sets forth your sole
                                                remedies and our sole liability
                                                and obligation for any actual,
                                                threatened, or alleged
                                                Third-Party Claims that the
                                                Services infringe,
                                                misappropriate, or otherwise
                                                violate any intellectual
                                                property rights of any third
                                                party.&nbsp;
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>This&nbsp;</span>
                                            <a href='https://docs.google.com/document/d/1J39_FPdAfToyr0tjc1pzHJPpZmH4TUqx/edit#bookmark=id.1hmsyys'>
                                                <span>Section 11(a)</span>
                                            </a>
                                            <span>
                                                &nbsp;will not apply to the
                                                extent that any such Third-Party
                                                Claim arises from Customer Data
                                                or Third-Party Products.
                                            </span>
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Customer Indemnification</span>
                                    </u>
                                    <span>
                                        . Customer shall indemnify, hold
                                        harmless, and, at Provider&apos;s
                                        option, defend Provider and its
                                        officers, directors, employees, agents,
                                        affiliates, successors, and assigns from
                                        and against any and all Losses arising
                                        from or relating to any Third-Party
                                        Claim (i) that the Customer Data, or any
                                        use of the Customer Data in accordance
                                        with this Agreement, infringes or
                                        misappropriates such third party&apos;s
                                        Canadian intellectual property rights;
                                        or (ii) based on Customer&apos;s or any
                                        Authorized User&apos;s negligence or
                                        wilful misconduct or use of the Services
                                        in a manner not authorized by this
                                        Agreement; provided that Customer may
                                        not settle any Third-Party Claim against
                                        Provider unless Provider consents to
                                        such settlement, and further&nbsp;
                                    </span>
                                    <em>
                                        <span>provided that</span>
                                    </em>
                                    <span>
                                        &nbsp;Provider will have the right, at
                                        its option, to defend itself against any
                                        such Third-Party Claim or to participate
                                        in the defence thereof by counsel of its
                                        own choice.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>LIMITATIONS OF LIABILITY</span>
                            </u>
                            <span>
                                . IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR
                                IN CONNECTION WITH THIS AGREEMENT UNDER ANY
                                LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
                                CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
                                LIABILITY, OR OTHERWISE, FOR ANY: (a)
                                CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
                                SPECIAL, AGGRAVATED, OR PUNITIVE DAMAGES; (b)
                                INCREASED COSTS, DIMINUTION IN VALUE OR LOST
                                BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c)
                                LOSS OF GOODWILL OR REPUTATION; (d) USE,
                                INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR
                                RECOVERY OF ANY DATA, OR BREACH OF DATA OR
                                SYSTEM SECURITY; OR (e) COST OF REPLACEMENT
                                GOODS OR SERVICES, IN EACH CASE REGARDLESS OF
                                WHETHER PROVIDER WAS ADVISED OF THE POSSIBILITY
                                OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR
                                DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT
                                WILL PROVIDER&apos;S AGGREGATE LIABILITY ARISING
                                OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY
                                LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
                                CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
                                LIABILITY, OR OTHERWISE EXCEED THE TOTAL AMOUNTS
                                PAID TO PROVIDER UNDER THIS AGREEMENT IN THE
                                TWELVE (12) MONTH PERIOD PRECEDING THE EVENT
                                GIVING RISE TO THE CLAIM.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Term and Termination</span>
                            </u>
                            <span>.&nbsp;</span>
                        </p>
                        <ol>
                            <li>
                                <p>
                                    <u>
                                        <span>Term</span>
                                    </u>
                                    <span>
                                        . The term of this Agreement begins on
                                        the date Provider notifies Customer it
                                        can access the Services and continues
                                        until terminated. Services that are
                                        specified to automatically renew will
                                        renew for up to one (3) additional
                                        successive one (1) year terms unless
                                        earlier terminated pursuant to this
                                        Agreement&apos;s express provisions or
                                        either party gives the other party
                                        written notice of non-renewal at least
                                        sixty (60) days prior to the expiration
                                        of the then-current services period.
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Termination</span>
                                    </u>
                                    <span>
                                        . In addition to any other express
                                        termination right set forth in this
                                        Agreement:
                                    </span>
                                </p>
                                <ol>
                                    <li>
                                        <p>
                                            <span>
                                                Provider may terminate this
                                                Agreement, for any reason upon
                                                thirty (30) days&apos; advance
                                                notice. You may terminate this
                                                Agreement for any reason upon
                                                sixty (60) days&apos; advance
                                                notice.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>
                                                either party may terminate this
                                                Agreement, effective on written
                                                notice to the other party, if
                                                the other party materially
                                                breaches this Agreement, and
                                                such breach: (A) is incapable of
                                                cure; or (B) being capable of
                                                cure, remains uncured thirty
                                                (30) days after the
                                                non-breaching party provides the
                                                breaching party with written
                                                notice of such breach; or
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>
                                                either party may terminate this
                                                Agreement, effective immediately
                                                upon written notice to the other
                                                party, if the other party: (A)
                                                becomes insolvent or is
                                                generally unable to pay, or
                                                fails to pay, its debts as they
                                                become due; (B) files or has
                                                filed against it, a petition for
                                                voluntary or involuntary
                                                bankruptcy or otherwise becomes
                                                subject, voluntarily or
                                                involuntarily, to any proceeding
                                                under any domestic or foreign
                                                bankruptcy or insolvency law;
                                                (C) makes or seeks to make a
                                                general assignment for the
                                                benefit of its creditors; or (D)
                                                applies for or has appointed a
                                                receiver, trustee, custodian, or
                                                similar agent appointed by order
                                                of any court of competent
                                                jurisdiction to take charge of
                                                or sell any material portion of
                                                its property or business.
                                            </span>
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>
                                            Effect of Expiration or Termination
                                        </span>
                                    </u>
                                    <span>
                                        . Upon expiration or earlier termination
                                        of this Agreement, Customer shall
                                        immediately discontinue use of the
                                        Provider IP. No termination of this
                                        Agreement will affect Customer&apos;s
                                        obligation to pay all Fees that may have
                                        become due before such expiration or
                                        termination, or entitle Customer to any
                                        refund.&nbsp;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <u>
                                        <span>Survival</span>
                                    </u>
                                    <span>
                                        . This Section 13(d), 5, 11, 12, and any
                                        right, obligation, or required
                                        performance of the parties in this
                                        Agreement which, by its express terms or
                                        nature and context is intended to
                                        survive termination or expiration of
                                        this Agreement, will survive any such
                                        termination or expiration.
                                    </span>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>
                            <ul>
                                <li>
                                    <p>
                                        <span>Modifications.</span>
                                        <span>
                                            &nbsp;You acknowledge and agree that
                                            we have the right, in our sole
                                            discretion, to modify this Agreement
                                            from time to time, and that modified
                                            terms become effective on posting.
                                            You will be notified of
                                            modifications through notifications
                                            or posts on&nbsp;
                                        </span>
                                        <a href='http://www.levyl.app'>
                                            <span>www.levyl.app</span>
                                        </a>
                                        <span>
                                            . You are responsible for reviewing
                                            and becoming familiar with any such
                                            modifications. Your continued use of
                                            the Services after the effective
                                            date of the modifications will be
                                            deemed acceptance of the modified
                                            terms. Provider will provide at
                                            least sixty (60) days&apos; advance
                                            notice of changes to any service
                                            level that Provider reasonably
                                            anticipates may result in a material
                                            reduction in quality or
                                            services.&nbsp;
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </u>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Governing Law</span>
                            </u>
                            <span>
                                . This Agreement and all related documents
                                including all exhibits attached hereto, and all
                                matters arising out of or relating to this
                                Agreement, whether sounding in contract, tort,
                                or statute, are governed by, and construed in
                                accordance with, the laws of the Province of
                                Ontario and the federal laws of Canada
                                applicable therein, without giving effect to any
                                choice or conflict of law provision or rule
                                (whether of the Province of Ontario or any other
                                jurisdiction) that would cause the application
                                of the laws of any jurisdiction other than those
                                of the Province of Ontario.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Choice of Forum</span>
                            </u>
                            <span>
                                . Any legal suit, action, litigation, or
                                proceeding of any kind whatsoever in any way
                                arising out of, from, or relating to this
                                Agreement, including all statements of work,
                                exhibits, schedules, attachments, and appendices
                                attached to this Agreement, the services
                                provided hereunder, and all contemplated
                                transactions, shall be instituted in the courts
                                of the Province of Ontario, and each party
                                irrevocably submits to the exclusive
                                jurisdiction of such courts in any such suit,
                                action, litigation, or proceeding. Service of
                                process, summons, notice, or other document by
                                mail to such party&apos;s address set forth
                                herein shall be effective service of process for
                                any suit, action, litigation, or other
                                proceeding brought in any such court. Each party
                                agrees that a final judgment in any such suit,
                                action, litigation, or proceeding is conclusive
                                and may be enforced in other jurisdictions by
                                suit on the judgment or in any other manner
                                provided by law. The parties irrevocably and
                                unconditionally waive any objection to the venue
                                of any action or proceeding in such courts and
                                irrevocably waive and agree not to plead or
                                claim in any such court that any such action or
                                proceeding brought in any such court has been
                                brought in an inconvenient forum.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <u>
                                <span>Miscellaneous</span>
                            </u>
                            <span>
                                . This Agreement constitutes the entire
                                agreement and understanding between the parties
                                hereto with respect to the subject matter hereof
                                and supersedes all prior and contemporaneous
                                understandings, agreements, representations, and
                                warranties, both written and oral, with respect
                                to such subject matter. Any notices to us must
                                be sent to our corporate headquarters address
                                available at&nbsp;
                            </span>
                            <a href='http://www.levyl.app'>
                                <span>www.levyl.app</span>
                            </a>
                            <span>
                                &nbsp;and must be delivered either in person, by
                                certified or registered mail, return receipt
                                requested and postage prepaid, or by recognized
                                overnight courier service, and are deemed given
                                upon receipt by us. Notwithstanding the
                                foregoing, you hereby consent to receiving
                                electronic communications from us. These
                                electronic communications may include notices
                                about applicable fees and charges, transactional
                                information, and other information concerning or
                                related to the Services. You agree that any
                                notices, agreements, disclosures, or other
                                communications that we send to you
                                electronically will satisfy any legal
                                communication requirements, including that such
                                communications be in writing. The invalidity,
                                illegality, or unenforceability of any provision
                                herein does not affect any other provision
                                herein or the validity, legality, or
                                enforceability of such provision in any other
                                jurisdiction. Any failure to act by us with
                                respect to a breach of this Agreement by you or
                                others does not constitute a waiver and will not
                                limit our rights with respect to such breach or
                                any subsequent breaches. This Agreement is
                                personal to you and may not be assigned or
                                transferred for any reason whatsoever without
                                our prior written consent and any action or
                                conduct in violation of the foregoing will be
                                void and without effect. We expressly reserve
                                the right to assign this Agreement and to
                                delegate any of its obligations hereunder.
                            </span>
                        </p>
                    </li>
                </ol>
            </Typography>
        </StyledContainer>
    </StyledBackground>
);

const ServicesAgreement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <NavBarSection
                logo={{ file: { url: logo } }}
                links={[
                    {
                        content: 'Career Seekers',
                        link: '/public/career-seekers'
                    },
                    { content: 'Employers', link: '/public/companies' }
                ]}
                logoLink=''
            />
            <ContainerSection heading={'Services Agreement'} />
            <FooterSection content={''} />
        </>
    );
};

export default ServicesAgreement;
