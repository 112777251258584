import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledChannelContainer = styled((props: ContainerProps) => (
    <Container {...props} />
))(({ theme }) => ({
    flexGrow: 1,
    width: 'unset',
    position: 'sticky',
    top: theme.spacing(8),
    padding: 0,
    margin: 0,
    background: 'white',
    borderRadius: '0.5rem',
    border: '1px solid #e9ecef',
    '& .sendbird-conversation': {
        borderRadius: '0.5rem'
    },
    '& .sendbird-message-content__middle__message-item-body.sendbird-text-message-item-body.outgoing':
        {
            backgroundColor: '#f3f0fa !important',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '150%',
            color: '#6c757d',
            borderRadius: '12px 0px 12px 12px',
            '*': {
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '150%',
                color: '#6c757d'
            }
        },
    '& .sendbird-message-content__middle__message-item-body.sendbird-text-message-item-body.incoming':
        {
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '150%',
            color: '#6c757d',
            backgroundColor: '#faf0f0',
            borderRadius: '0px 12px 12px 12px',
            '*': {
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '150%',
                color: '#6c757d'
            }
        },
    '& .sendbird-message-content__middle__sender-name': {
        display: 'none'
    },
    '& .sendbird-conversation__messages': {
        display: 'flex',
        flexDirection: 'column'
    },
    '& .sendbird-conversation__scroll-container': {
        flexGrow: 1,
        height: 0
    },
    '& .sendbird-conversation__messages-padding': {
        overflow: 'auto'
    },
    '& .sendbird-message-item-reaction-menu__trigger': {
        display: 'none'
    },
    '&#levyl .sendbird-channel-header__right-icon': {
        display: 'none'
    },
    '&#levyl .sendbird-channel-header': {
        height: 'auto',
        width: '100%',
        border: 'none !important'
    },
    '& .sendbird-message-input': {
        border: '1px solid #e9ecef'
    }
}));

export default StyledChannelContainer;
