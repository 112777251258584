import { Typography } from '@mui/material';
import React, { useState } from 'react';

import SmallCard from '../../global/layouts/SmallCard/SmallCard';

import FeedbackModal from './FeedbackModal';

import type { Interview } from '../../../types/interview';

export const InterviewCardContent = ({
    image,
    completionPercent,
    primaryAction,
    secondaryAction,
    name
}: {
    image: string;
    completionPercent: number;
    primaryAction: () => void;
    secondaryAction: () => void;
    name: string;
}) => (
    <SmallCard
        imageSrc={image}
        statusText={`${completionPercent}% complete`}
        primaryButton={{
            text: 'Complete Feedback',
            onClick: primaryAction
        }}
        secondaryButton={{ text: 'Skip', onClick: secondaryAction }}
    >
        <Typography
            variant={'body2'}
            style={{ fontWeight: 600 }}
            color={'primary.main'}
            sx={{ mt: 1 }}
        >
            {name}
        </Typography>
    </SmallCard>
);

const InterviewCard = ({
    interview,
    removeInterview
}: {
    interview: Interview;
    removeInterview: () => void;
}) => {
    const [modal, setModal] = useState(false);
    const [completionPercent, setCompletionPercent] = useState(0);

    return (
        <>
            <InterviewCardContent
                image={interview.company.logoURL}
                completionPercent={completionPercent}
                primaryAction={() => setModal(true)}
                secondaryAction={removeInterview}
                name={`${interview.participant?.name.firstName} ${interview.participant?.name.lastName}`}
            />
            <FeedbackModal
                open={modal}
                onClose={() => setModal(false)}
                interview={interview}
                setCompletionPercent={setCompletionPercent}
            />
        </>
    );
};
export default InterviewCard;
