/* eslint-disable max-lines */
import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch
} from 'react-router-dom';

import { parseResume } from '../../../../services/user';
import updateDataLayer from '../../../../utils/updateDataLayer';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { UserContext } from '../../../context/UserContext';
import MainContainer from '../../../global/layouts/MainContainer/MainContainer';
import {
    useCandidateProfileSidebarButtons,
    useDefaultSidebarButtons
} from '../../../global/layouts/MainContainer/SidebarButton/SidebarList';
import LocationSlide from '../common/LocationSlide';
import NameSlide from '../common/NameSlide';

import CertificationSlide from './CertificationSlide';
import DemographicSlide from './DemographicSlide';
import EducationSlide from './EducationSlide';
import ExperienceSlide from './ExperienceSlide';
import IdealRoleSlide from './IdealRoleSlide';
import PortfolioSlide from './PortfolioSlide';
import QuestionSlide from './QuestionSlide';
import ResumeSlide from './ResumeSlide';
import SkillsSlide from './SkillsSlide';

import type { User } from '../../../../types/user';

const routes = {
    name: { prev: 'demographics', next: 'idealrole' },
    location: { prev: 'name', next: 'idealrole' },
    idealRole: { prev: 'location', next: 'resume' },
    resume: { prev: 'idealRole', next: 'experience' },
    experience: { prev: 'resume', next: 'education' },
    education: { prev: 'experience', next: 'certification' },
    certification: { prev: 'education', next: 'skills' },
    skills: { prev: 'certification', next: 'portfolio' },
    portfolio: { prev: 'skills', next: 'questions' },
    interestedQuestions: { prev: 'portfolio', next: 'demographics' },
    demographics: { prev: 'questions', next: 'name' }
} as unknown as {
    [key in keyof User | 'resume']: { prev: string; next: string };
};

export default function CandidateProfile() {
    const location = useLocation();
    const [redirect, setRedirect] = useState<string>('');
    const [resume, setResume] = useState<string>('');
    const { path } = useRouteMatch();
    const { user, setUser, updateUser } = useContext(UserContext);
    const { auth } = useContext(FirebaseContext);

    const [resumeUploaded, setResumeUploaded] = useState(false);

    const defaultSideBarButtons = useDefaultSidebarButtons(user.userID);
    const scrollButtons = useCandidateProfileSidebarButtons(user.userID);

    const flowType = useMemo(
        () => (location.pathname.includes('/edit/') ? 'edit' : 'create'),
        [location.pathname]
    );

    const update = (key: keyof User, value: User[keyof User]) => {
        setUser({ ...user, [key]: value });
        setRedirect(
            `/${flowType}/${user.userID}/${user.levylRole}/${routes[key].next}`
        );
    };

    const submit = (updatedData: Partial<User>) => {
        updateUser(updatedData).then(() => {
            setRedirect(`/secure/${user.userID}/${user.levylRole}/dashboard`);
        });
    };

    const onClose = () =>
        updateUser().then(() => {
            setRedirect(`/secure/${user.userID}/${user.levylRole}/dashboard`);
        });

    useEffect(() => {
        if (redirect) {
            setRedirect('');
        }
    }, [redirect]);

    useEffect(() => {
        if (resume && user.id) {
            parseResume(user.id).then((response: Partial<User>) => {
                if (response) {
                    setResumeUploaded(true);
                    updateUser(response);
                }
            });
        }
    }, [resume, user.id, updateUser]);

    useEffect(() => {
        if (location.pathname.includes('candidate')) {
            updateDataLayer({
                role: 'candidate',
                user,
                resumeUploaded
            });
        }
    }, [location.pathname, user, resumeUploaded]);

    const CandidateProfileRoutes = () => (
        <Switch>
            <Route path={`${path}/name`}>
                <NameSlide
                    profile={user.name}
                    updateProfile={(value) => update('name', value)}
                    userRole={'candidate'}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/location`}>
                <LocationSlide
                    profile={user.location}
                    updateProfile={(value) => update('location', value)}
                    userRole={'candidate'}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/name`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/idealrole`}>
                <IdealRoleSlide
                    profile={user.idealRole}
                    updateProfile={(value) => update('idealRole', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/location`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/resume`}>
                <ResumeSlide
                    profile={resume}
                    updateProfile={(value: string) => {
                        setResume(value);
                        setRedirect(
                            `/${flowType}/${user.userID}/${user.levylRole}/experience`
                        );
                    }}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/idealrole`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/experience`}>
                <ExperienceSlide
                    profile={user.experience}
                    updateProfile={(value) => update('experience', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/resume`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/education`}>
                <EducationSlide
                    profile={user.education}
                    updateProfile={(value) => update('education', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/experience`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/certification`}>
                <CertificationSlide
                    profile={user.certification}
                    updateProfile={(value) => update('certification', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/education`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/skills`}>
                <SkillsSlide
                    profile={user.skills}
                    updateProfile={(value) => update('skills', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/certification`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/portfolio`}>
                <PortfolioSlide
                    profile={user.portfolio}
                    updateProfile={(value) => update('portfolio', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/skills`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/questions`}>
                <QuestionSlide
                    profile={user.interestedQuestions.map(
                        (question) => question.question
                    )}
                    updateProfile={(value) =>
                        update(
                            'interestedQuestions',
                            value.map((question, index) => ({
                                question,
                                userID: user.id,
                                id: user.interestedQuestions[index].id,
                                numberResponse: false,
                                systemGeneratedID: ''
                            }))
                        )
                    }
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/portfolio`}
                    onClose={onClose}
                />
            </Route>
            <Route path={`${path}/demographics`}>
                <DemographicSlide
                    profile={user.demographics}
                    updateProfile={(value) => update('demographics', value)}
                    prevURL={`/${flowType}/${user.userID}/${user.levylRole}/questions`}
                    submitProfile={submit}
                    onClose={onClose}
                />
            </Route>
        </Switch>
    );

    return (
        <Fragment>
            {flowType === 'edit' ? (
                <MainContainer
                    sideBarButtons={defaultSideBarButtons}
                    firstName={user.name.firstName}
                    photoURL={user.name.imageURL}
                    signOut={() => {
                        auth?.signOut();
                    }}
                    scrollButtons={scrollButtons}
                >
                    <CandidateProfileRoutes />
                </MainContainer>
            ) : (
                <MainContainer public fullWidth>
                    <CandidateProfileRoutes />
                </MainContainer>
            )}

            {redirect && <Redirect to={redirect} />}
        </Fragment>
    );
}
