import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { createCompanyPaymentCheckout } from '../../../../services/company';
import { StripePlan } from '../../../../types/enum';
import { TextButton } from '../../../global/components/Button/Button';
import RadioButton from '../../../global/components/RadioButton';
import StyledCard from '../../../global/Core';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { Company } from '../../../../types/company';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup
    .object()
    .shape({
        plan: yup
            .string()
            .oneOf(['basic', 'plus', 'enterprise'])
            .required(commonContent.common.errors.required)
    })
    .required();

export default function BusinessPlans(props: {
    company: Company;
    submitCompany: (company: Partial<Company>) => Promise<void>;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const { company, submitCompany } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPlan, setPlan] = useState<string | undefined>(
        content.company.plan.options[0].value
    );
    const [isSubmitting, setIsSubmitting] = useState<string | undefined>();
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            plan: props.company.plan
        }
    });

    const onSubmit = (data: { plan: string }) => {
        setIsSubmitting(data.plan);
    };

    const planDetails = useMemo(
        () =>
            selectedPlan === 'basic' ||
            selectedPlan === 'plus' ||
            selectedPlan === 'enterprise'
                ? content.company.plan[selectedPlan]
                : { price: '', details: ['', '', ''] },
        [selectedPlan]
    );

    useEffect(() => {
        if (isSubmitting && company.id) {
            submitCompany({
                ...company,
                plan: isSubmitting as StripePlan
            });
            setIsLoading(true);
            createCompanyPaymentCheckout(company.id).then((response: any) => {
                setIsLoading(false);
                window.location.href = response.url;
            });
        }
    }, [company, isSubmitting, submitCompany]);

    useEffect(() => {
        const subscription = watch((value) => {
            const { plan } = value;
            setPlan(plan);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <ColumnCard
            closeScreen
            step={3}
            screenType={'business'}
            onClose={props.onClose}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography variant='h2' fontWeight='400' sx={{ mb: 2 }}>
                    {content.company.plan.title}
                    <Typography variant='h2' color={'primary'} component='span'>
                        {`${
                            selectedPlan &&
                            selectedPlan.charAt(0).toUpperCase() +
                                selectedPlan.slice(1)
                        } Plan`}
                    </Typography>
                </Typography>
                <RadioButton
                    id='plan'
                    title={content.company.plan.label}
                    color='primary'
                    error={!!errors.plan}
                    errorText={errors.plan?.message}
                    control={control as unknown as Control<FieldValues>}
                    labels={content.company.plan.options}
                    row={true}
                />
                <StyledCard>
                    <Typography
                        variant={'h4'}
                        style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            fontWeight: 700,
                            fontSize: 40,
                            marginBottom: '16px'
                        }}
                    >
                        {planDetails.price}
                        <Typography variant={'body2'} component='span'>
                            {'/year'}
                        </Typography>
                    </Typography>
                    <ul style={{ paddingLeft: '1rem' }}>
                        <li style={{ marginBottom: '1rem' }}>
                            {planDetails.details[0]}
                        </li>
                        <li style={{ marginBottom: '1rem' }}>
                            {planDetails.details[1]}
                        </li>
                        <li style={{ marginBottom: '1rem' }}>
                            {planDetails.details[2]}
                        </li>
                    </ul>
                </StyledCard>
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {commonContent.common.cancel}
                    </TextButton>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id={'submit'}
                    >
                        {isLoading ? (
                            <CircularProgress color='primary' />
                        ) : (
                            commonContent.common.confirm
                        )}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
