import { styled } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';

const Container = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
`;

const StyledVideo = styled('video')`
    object-fit: cover;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

export default function VideoTile(props: {
    videoTrack: MediaStreamTrack;
    audioTrack: MediaStreamTrack;
    isLocal: boolean;
}) {
    const videoEl = useRef<HTMLVideoElement | null>(null);
    const audioEl = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (videoEl.current) {
            videoEl.current.srcObject = new MediaStream([props.videoTrack]);
        }
    }, [props.videoTrack]);

    useEffect(() => {
        if (audioEl.current) {
            audioEl.current.srcObject = new MediaStream([props.audioTrack]);
        }
    }, [props.audioTrack]);

    const getVideoComponent = () =>
        props.videoTrack && (
            <StyledVideo autoPlay muted playsInline ref={videoEl} />
        );

    const getAudioComponent = () =>
        !props.isLocal && props.audioTrack && <audio autoPlay ref={audioEl} />;

    return (
        <Container>
            {getVideoComponent()}
            {getAudioComponent()}
        </Container>
    );
}
