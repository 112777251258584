const removeFalsyObjects = (obj: { [key: string]: any }) => {
    Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = obj[key].filter((item: any) =>
                Object.values(item).some(Boolean)
            );
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            removeFalsyObjects(obj[key]);
        }
    });
    return obj;
};

export default removeFalsyObjects;
