import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import content from './content';

import type { UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    hard: yup.array().of(yup.object().shape({ skill: yup.string() })),
    soft: yup.array().of(yup.object().shape({ skill: yup.string() })),
    languages: yup.array().of(yup.object().shape({ skill: yup.string() }))
});

const StyledTextField = styled(TextInput)`
    flex-grow: 1;
`;

const StyledSection = styled('div')`
    width: 100%;
    display: flex;
    gap: 0.5rem;
`;

const StyledChipStack = styled(Stack)`
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
`;

export const SingleSkill = (props: {
    id: string;
    skillType: string;
    skills: string[];
    addValue: (value: { skill: string }) => void;
    removeValue: (index: number) => void;
    control: Control<FieldValues>;
}) => (
    <StyledSection
        id='skills'
        style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem'
        }}
    >
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledTextField
                id={props.id}
                color='primary'
                variant='outlined'
                label={`Enter ${props.skillType}`}
                error={''}
                control={props.control}
            />
            <IconButton
                id={`add-${props.skillType.replace(' ', '-').toLowerCase()}`}
                aria-label={content.skills.add}
                size='large'
                onClick={() =>
                    props.addValue({
                        skill: props.skills[props.skills.length - 1]
                    })
                }
            >
                <AddIcon fontSize='inherit' />
            </IconButton>
        </div>
        <StyledChipStack>
            {props.skills.map((skill, index) => (
                <Chip
                    id={`chip-${skill}`}
                    label={skill}
                    key={skill}
                    onDelete={() => {
                        props.removeValue(index);
                    }}
                    color='primary'
                />
            ))}
        </StyledChipStack>
    </StyledSection>
);

export default function SkillsSlide(props: {
    profile: UserProfile['skills'];
    updateProfile: (value: UserProfile['skills']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            hard: props.profile.hard.map((skill) => ({ skill })),
            soft: props.profile.soft.map((skill) => ({ skill })),
            languages: props.profile.languages.map((skill) => ({ skill }))
        }
    });
    const {
        fields: hardFields,
        append: hardAppend,
        remove: hardRemove
    } = useFieldArray({
        control,
        name: 'hard'
    });
    const {
        fields: softFields,
        append: softAppend,
        remove: softRemove
    } = useFieldArray({
        control,
        name: 'soft'
    });
    const {
        fields: languageFields,
        append: languageAppend,
        remove: languageRemove
    } = useFieldArray({
        control,
        name: 'languages'
    });

    const onSubmit = (data: {
        hard: { skill: string }[];
        soft: { skill: string }[];
        languages: { skill: string }[];
    }) => {
        props.updateProfile({
            hard: data.hard.map((skill) => skill.skill),
            soft: data.soft.map((skill) => skill.skill),
            languages: data.languages.map((skill) => skill.skill)
        });
    };

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'skillsSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {commonContent.common.stepThreeSubtitle}
                </Typography>
                <SingleSkill
                    id={`hard.${hardFields.length - 1}.skill`}
                    skillType={content.skills.hard}
                    skills={hardFields
                        .slice(0, hardFields.length - 1)
                        .map((skill) => skill.skill)
                        .filter((skill) => skill !== '')}
                    addValue={hardAppend}
                    removeValue={hardRemove}
                    control={control as unknown as Control<FieldValues>}
                />
                <SingleSkill
                    id={`soft.${softFields.length - 1}.skill`}
                    skillType={content.skills.soft}
                    skills={softFields
                        .slice(0, hardFields.length - 1)
                        .map((skill) => skill.skill)
                        .filter((skill) => skill !== '')}
                    addValue={softAppend}
                    removeValue={softRemove}
                    control={control as unknown as Control<FieldValues>}
                />
                <SingleSkill
                    id={`languages.${languageFields.length - 1}.skill`}
                    skillType={content.skills.languages}
                    skills={languageFields
                        .slice(0, hardFields.length - 1)
                        .map((skill) => skill.skill)
                        .filter((skill) => skill !== '')}
                    addValue={languageAppend}
                    removeValue={languageRemove}
                    control={control as unknown as Control<FieldValues>}
                />
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {commonContent.common.back}
                    </TextButton>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id={'submit'}
                    >
                        {commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
