// @ts-expect-error - no types for library
import TagManager from 'react-gtm-module';

import { LevylUser } from '../components/pages/Profile/userProfileTypes';
import { User } from '../types/user';

const getResumeUploadedStatus = (status: boolean) =>
    status ? 'uploaded' : 'not uploaded';

const getAttributeStatus = (status: boolean) =>
    status ? 'added' : 'not added';

const updateDataLayer = (data: {
    role: LevylUser;
    user: User;
    resumeUploaded?: boolean;
}) => {
    const tagManagerArgs = {
        dataLayer: {
            role: data.role,
            user: data?.user && {
                user_id: data?.user?.userID,
                role_types: data?.user?.idealRole?.desiredRoles?.[0],
                preferred_locations: data?.user?.idealRole?.desiredLocations,
                number_of_preferred_locations:
                    data?.user?.idealRole?.desiredLocations?.length,
                work_environment: data?.user?.idealRole?.workingEnvironment,
                resume_uploaded: getResumeUploadedStatus(
                    !!data?.resumeUploaded
                ),
                portfolio: getAttributeStatus(
                    data?.user?.portfolio?.length > 0 &&
                        data?.user?.portfolio?.[0].title !== ''
                ),
                languages: getAttributeStatus(
                    data?.user?.skills?.languages?.length > 0 &&
                        data?.user?.skills?.languages?.[0] !== ''
                ),
                hard_skills: getAttributeStatus(
                    data?.user?.skills?.hard?.length > 0 &&
                        data?.user?.skills?.hard?.[0] !== ''
                ),
                soft_skills: getAttributeStatus(
                    data?.user?.skills?.soft?.length > 0 &&
                        data?.user?.skills?.soft?.[0] !== ''
                ),
                questions: data?.user?.interestedQuestions?.map(
                    (question: {
                        id: number;
                        userID: number;
                        question: string;
                        systemGeneratedID: string;
                        numberResponse: boolean;
                    }) => getAttributeStatus(!!question?.question)
                ),
                gender: getAttributeStatus(
                    data?.user?.demographics?.gender !== 'no_disclose'
                ),
                ethnicity: getAttributeStatus(
                    data?.user?.demographics?.ethnicity !== 'no_disclose'
                ),
                lgbtq: getAttributeStatus(
                    data?.user?.demographics?.lgbtq !== 'no_disclose'
                )
            }
        }
    };
    TagManager.dataLayer(tagManagerArgs);
};

export default updateDataLayer;
