import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useContext, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import logo from '../../../../../static/logos/name.svg';
import { UserContext } from '../../../../context/UserContext';
import SidebarButton from '../SidebarButton/SidebarButton';
import UserButton from '../UserButton/UserButton';

import type { SideBarButtons } from '../SidebarButton/SidebarButton';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    maxHeight: '100%',
    position: 'sticky',
    zIndex: 1201,
    boxShadow: 'unset'
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
        display: 'none'
    }
}));

const StyledContentSection = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLogo = styled('img')(({ theme }) => ({
    height: 24,
    [`${theme.breakpoints.up('xs')}`]: {
        height: 22
    },
    [theme.breakpoints.up('sm')]: {
        height: 24
    }
}));

const content = {
    logo: {
        image: logo,
        link: '/secure/dashboard',
        a11y: 'Levyl Logo',
        linka11y: 'Redirect to Dashboard'
    }
};

type HeaderProps =
    | {
          public?: false;
          firstName: string;
          photoURL: string;
          signOut: () => void;
          sideBarButtons: SideBarButtons;
      }
    | { public: true };

export default function Header(props: HeaderProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const { pathname } = useLocation();
    const { uid } = useParams<{ uid: string }>();
    const { user } = useContext(UserContext);

    return (
        <StyledAppBar>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    {!props.public && (
                        <StyledIconButton
                            edge='start'
                            color='primary'
                            aria-label='menu'
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            <MenuIcon />
                        </StyledIconButton>
                    )}
                    <StyledContentSection>
                        {pathname.startsWith('/secure') && (
                            <Link
                                to={`/secure/${uid}/${user.levylRole}/dashboard`}
                                style={{ display: 'flex' }}
                                aria-label={content.logo.linka11y}
                            >
                                <StyledLogo
                                    src={content.logo.image}
                                    alt={content.logo.a11y}
                                />
                            </Link>
                        )}
                        {!pathname.startsWith('/secure') && (
                            <StyledLogo
                                src={content.logo.image}
                                alt={content.logo.a11y}
                            />
                        )}
                        {!props.public && (
                            <Box sx={{ display: { xs: 'flex' } }}>
                                <UserButton
                                    firstName={props.firstName}
                                    photoURL={props.photoURL}
                                    signOut={props.signOut}
                                />
                            </Box>
                        )}
                    </StyledContentSection>
                </Toolbar>
            </Container>
            {!props.public && (
                <Drawer
                    anchor={'left'}
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    style={{ zIndex: 1202 }}
                >
                    <List sx={{ p: 3 }}>
                        <StyledLogo src={logo} sx={{ mb: 2 }} />
                        {props.sideBarButtons.map((sidebar, index) => (
                            <SidebarButton
                                text={sidebar.text}
                                to={sidebar.to}
                                active={sidebar.active}
                                key={index}
                            />
                        ))}
                    </List>
                </Drawer>
            )}
        </StyledAppBar>
    );
}
