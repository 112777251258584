import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { Fragment } from 'react';

import image from '../../../static/illustrations/balloon.svg';
import Button from '../../global/components/Button/Button';
import ColumnCard from '../../global/layouts/ColumnCard/ColumnCard';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';

import content from './content.json';
import { StyledTextButton } from './styles';

const LeftSection = styled('div')(({ theme }) => ({
    width: '20rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: '1rem',
    marginBottom: '0rem',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
}));

export default function CreateIntroSlide() {
    return (
        <Fragment>
            <MainContainer public fullWidth>
                <ColumnCard image={{ src: image, alt: '' }}>
                    <LeftSection>
                        <Typography
                            variant='h2'
                            component='h1'
                            fontWeight='400'
                            sx={{ mb: 3 }}
                            color='text.secondary'
                        >
                            {'How will you use Levyl?'}
                        </Typography>
                        <StyledButton
                            id='selectCandidate'
                            variant='outlined'
                            to='/signup/user'
                        >
                            {'Job Seeker'}
                        </StyledButton>
                        <StyledButton
                            id='selectBusiness'
                            variant='outlined'
                            to='/signup/business'
                        >
                            {'Business'}
                        </StyledButton>
                        <Typography
                            fontWeight='400'
                            color='text.secondary'
                            style={{
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                paddingTop: '2.5rem'
                            }}
                        >
                            {`${content.changetoLoginText} `}
                            <StyledTextButton
                                id='goToLogin'
                                to='/login'
                                sx={{ ml: 0.5 }}
                            >
                                {content.changetoLoginCTA}
                            </StyledTextButton>
                        </Typography>
                    </LeftSection>
                </ColumnCard>
            </MainContainer>
        </Fragment>
    );
}
