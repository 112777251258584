import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextButton } from '../../../global/components/Button/Button';
import TextInput from '../../../global/components/TextField/TextField';
import ColumnCard from '../../../global/layouts/ColumnCard/ColumnCard';
import commonContent from '../common/content';
import { FormSection, NextSteps, StyledButton } from '../styles';

import type { UserProfile } from '../userProfileTypes';
import type { Control, FieldValues } from 'react-hook-form';

const formSchema = yup.object().shape({
    questions: yup.array().of(yup.object().shape({ question: yup.string() }))
});

export default function QuestionSlide(props: {
    profile: UserProfile['questions'];
    updateProfile: (value: UserProfile['questions']) => void;
    prevURL: string;
    onClose: () => Promise<void>;
}) {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            questions: props.profile.map((question) => ({ question }))
        }
    });
    const { fields } = useFieldArray({
        control,
        name: 'questions'
    });

    const onSubmit = (data: { questions: { question: string }[] }) => {
        props.updateProfile(
            data.questions.map((question) => question.question)
        );
    };

    return (
        <ColumnCard
            closeScreen
            step={1}
            screenType={'candidate'}
            onClose={props.onClose}
            id={'questionSlide'}
        >
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h2'
                    component='h1'
                    fontWeight='400'
                    sx={{ mb: 2 }}
                >
                    {commonContent.common.title}
                </Typography>
                <Typography>
                    {commonContent.common.stepThreeSubtitle}
                </Typography>
                {fields.map((field, index) => (
                    <TextInput
                        key={field.id}
                        id={`questions.${index}.question`}
                        label={`Question ${index + 1}`}
                        color='primary'
                        error={!!errors.questions?.[index]?.question}
                        errorText={errors.questions?.[index]?.question?.message}
                        placeholder={''}
                        control={control as unknown as Control<FieldValues>}
                    />
                ))}
                <NextSteps>
                    <TextButton to={props.prevURL}>
                        {commonContent.common.back}
                    </TextButton>
                    <StyledButton
                        variant='outlined'
                        sx={{ m: 2 }}
                        submit
                        id={'submit'}
                    >
                        {commonContent.common.submit}
                    </StyledButton>
                </NextSteps>
            </FormSection>
        </ColumnCard>
    );
}
