import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledButton = styled(LoadingButton)`
    text-transform: none;
    border-radius: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
`;

type ButtonProps = {
    submit?: boolean;
    to?: string;
    link?: string;
    onClick?: () => void;
    variant: 'contained' | 'outlined' | 'text';
    children: React.ReactNode;
    ariaLabel?: string;
    disableRipple?: boolean;
    disabled?: boolean;
    loading?: boolean;
    id?: string;
};

export default function Button(props: ButtonProps) {
    const {
        variant,
        to,
        link,
        onClick,
        children,
        submit,
        disableRipple,
        disabled,
        loading,
        ...other
    } = props;
    if (to) {
        return (
            <StyledButton
                variant={variant}
                component={Link}
                to={to}
                disableRipple={disableRipple}
                disabled={disabled}
                {...other}
            >
                {children}
            </StyledButton>
        );
    }
    if (link) {
        return (
            <StyledButton
                variant={variant}
                component={'a'}
                href={link}
                disableRipple={disableRipple}
                disabled={disabled}
                {...other}
            >
                {children}
            </StyledButton>
        );
    }
    if (submit) {
        return (
            <StyledButton
                variant={variant}
                type='submit'
                disableRipple={disableRipple}
                disabled={disabled}
                loading={loading}
                {...other}
                value={children}
            >
                {children}
            </StyledButton>
        );
    }
    return (
        <StyledButton
            variant={variant}
            onClick={onClick}
            disableRipple={disableRipple}
            disabled={disabled}
            loading={loading}
            {...other}
        >
            {children}
        </StyledButton>
    );
}

export const TextButton = styled((props: ButtonProps) => (
    <Button disableRipple {...props} />
))`
    text-transform: none;
    border-radius: 2rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding: 0rem;
    min-width: 0rem;
    &:hover {
        text-decoration: underline;
        background-color: transparent;
    }
    &:focus {
        text-decoration: underline;
        background-color: transparent;
    }
`;

export const BackButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(0),
    minWidth: theme.spacing(4),
    borderRadius: theme.spacing(8),
    backgroundColor: theme.palette.primary.light,
    span: {
        margin: theme.spacing(0)
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light
    },
    '&:focus': {
        backgroundColor: theme.palette.primary.light
    }
}));
