import axios from 'axios';

/**
 * Given the response from the server, we return it,
 * @param {Promise} response The response promise
 * @returns Promise
 */
function handleRequest(axiosCall) {
    return axiosCall.then((response) =>
        response.status ? response.data : response
    );
}

function getBackendUrl() {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:8000';
    }
    return 'https://levyl.app';
}

/**
 * Sends the request to the given url with the given payload and returns a promise
 * @param {string} url Url endpoint
 * @param {string} method Request type
 * @param {object} data Data/Params object
 * @returns Promise containing the request
 */
export default function request(url, data = {}, method = 'GET', options = {}) {
    let backendUrl = getBackendUrl();
    backendUrl = `${backendUrl}${url}`;
    const requestOptions = {
        method,
        url: backendUrl,
        ...options
    };
    if (method.toLowerCase() === 'get') {
        requestOptions.params = data;
    } else {
        requestOptions.data = data;
    }
    return handleRequest(axios(requestOptions));
}

/**
 * Makes a GET request and returns a promise
 * @param {string} url Endpoint url
 * @param {object} params Query params
 * @returns Result Promise
 */
export function get(url, params = {}) {
    return request(url, params, 'GET');
}

/**
 * Makes a POST request and returns a promise
 * @param {string} url Endpoint url
 * @param {object} data Payload data
 * @param {object} options Additional options
 * @returns Response promise
 */
export function post(url, data, options = {}) {
    return request(url, data, 'POST', options);
}

/**
 * Given the endpoint url and the data to update, we send a PUT request to update
 * @param {string} url Endpoint URL
 * @param {object} data Payload data to update
 * @returns Response promise
 */
export function put(url, data) {
    return request(url, data, 'PUT');
}

/**
 * Delete the given resource
 * @param {string} url Endpoint URL
 * @returns Response promise
 */
export function del(url) {
    return request(url, {}, 'DELETE');
}

export function patch(url, data) {
    return request(url, data, 'PATCH');
}
